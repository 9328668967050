<script>
/**
 * @author lixidong
 * @description 页面元素和功能
 */
// 表格模块
import TableTool from '@/components/TableTool/index.vue'
import * as userApi from '@/api/easy_darwin_server/user_api'
import { CNameRules, ENameRules } from '@/utils/rules'
export default {
  components: { TableTool },
  props: {
    status: Boolean,
    value: { type: Object, value: () => ({}) },
  },
  data() {
    return {
      drawer: false,
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        footer_disabled: true,
        size: 'mini',
        row_key: 'ID',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'CName',
            label: '名称',
            width: 120,
          },
          {
            prop: 'EName',
            label: 'Key',
            width: 150,
          },
          // {
          //   prop: 'Status',
          //   label: '状态',
          //   slot: true,
          // },
          {
            prop: 'Description',
            label: '备注',
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        CName: '',
        EName: '',
        Description: '',
        Status: true,
        Url: '',
        ParentID: 0,
      },
      dialogBtnLock: false,
      dialogRules: {
        CName: CNameRules,
        EName: ENameRules,
        ParentID: [{ required: true, message: '类型不能为空！' }],
      },
    }
  },
  methods: {
    // 通道数据请求拦截
    async reqIntercept(query) {
      let res = await userApi[
        this.value.type == 'dom' ? 'getDom' : 'getOperation'
      ]({ ID: this.value.ID })
      if (res.code === 200) {
        // 区分目录和菜单
        return { data: res.data, total: res.data.length }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            const dialogForm = Object.assign(
              { MenuID: this.value.ID },
              this.dialogForm
            )
            if (this.dialogTitle == 'add') {
              let res = await await userApi[
                this.value.type == 'dom' ? 'addDom' : 'addOperation'
              ](dialogForm)
              if (res.code !== 200) return
            } else {
              let res = await userApi[
                this.value.type == 'dom' ? 'updateDom' : 'updateOperation'
              ](dialogForm)
              if (res.code !== 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    // add
    addList() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.$route.query.deviceid &&
        this.$set(this.dialogForm, 'deviceid', this.$route.query.deviceid)
      this.dialogFormVisible = true
    },
    //删除通道
    deleteList(row) {
      this.$confirm('此操作将永久删除这个权限，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await userApi[
            this.value.type == 'dom' ? 'delDom' : 'delOperation'
          ](row)
          if (res.code !== 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改通道
    updateList(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      ;(this.dialogTitle = 'update'), (this.dialogForm = Object.assign({}, row))
      this.dialogFormVisible = true
    },
  },
  watch: {
    status: {
      handler(val) {
        if (val) this.drawer = val
      },
      immediate: true,
    },
    drawer: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.tableTool && this.$refs.tableTool.requestTableData()
          })
        } else {
          this.$emit('update:status', val)
        }
      },
      immediate: true,
    },
  },
}
</script>
<template>
  <div class="dom-fn">
    <el-drawer :title="value.CName" size="80%" :visible.sync="drawer">
      <div
        style="
          height: calc(100vh - 200px);
          box-sizing: border-box;
          padding: 0 20px;
        "
      >
        <table-tool ref="tableTool" :tableData="tableData">
          <template slot="header">
            <div style="margin-bottom: 15px">
              <div>
                <el-button
                  @click="addList"
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  >添加{{
                    value.type == 'dom' ? '页面元素' : '页面功能'
                  }}</el-button
                >
              </div>
            </div>
          </template>
          <!-- 字段 -->
          <template slot="slot-Status" slot-scope="scope">
            <el-link
              :underline="false"
              :icon="scope.row.Status ? 'el-icon-check' : 'el-icon-close'"
              :type="scope.row.Status ? 'success' : 'danger'"
            ></el-link>
          </template>
          <!-- 操作按钮 -->
          <template slot="slot-edit" slot-scope="scope">
            <el-link
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click="updateList(scope.row)"
            ></el-link>
          </template>
          <template slot="slot-domfn" slot-scope="scope">
            <el-link
              :underline="false"
              icon="el-icon-s-operation"
              type="primary"
              size="mini"
              @click="smallAuth(scope.row)"
            ></el-link>
          </template>
          <template slot="slot-delete" slot-scope="scope">
            <el-link
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click="deleteList(scope.row)"
            ></el-link>
          </template>
        </table-tool>
        <!-- 添加修改名单 -->
        <el-dialog
          top="100px"
          :close-on-click-modal="false"
          :modal="false"
          :title="dialogTitle | dialogTitleKeyValue"
          :visible.sync="dialogFormVisible"
        >
          <el-form
            size="mini"
            ref="dialogForm"
            :model="dialogForm"
            :rules="dialogRules"
            label-width="110px"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="名称" prop="CName">
                  <el-input
                    v-model="dialogForm.CName"
                    placeholder="请输入名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="key(唯一)" prop="EName">
                  <el-input
                    v-model="dialogForm.EName"
                    placeholder="请输入key(唯一)"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="描述" prop="Description">
                  <el-input
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="dialogForm.Description"
                    placeholder="请输入描述"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
            <el-button
              size="mini"
              type="primary"
              :loading="dialogBtnLock"
              @click="dialogFormSubmit('dialogForm')"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </el-drawer>
  </div>
</template>
