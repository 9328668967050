/**
 * @author lixidong
 * @description 公用逻辑
 */

import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
// 面包屑
import BreadCrumb from '@/components/BreadCrumb.vue'
// 头部功能导航
import NaviBar from '@/components/NaviBar.vue'

import ChangePwd from '@/components/ChangePwd.vue'
export default {
  props: {
    mode: { type: Boolean, default: true },
  },
  components: {
    NaviBar,
    BreadCrumb,
    ChangePwd,
  },
  mounted() {
    $('body').layout('fix')
    this.fixHover()
    // $("body").addClass(localStorage["sidebar-collapse"]);
    // $(window).resize(this.resizeScroll)
    // this.resizeScroll()
  },
  computed: {
    ...mapGetters('menu', ['listNaviate']),
    ...mapState(['logoText', 'logoMiniText', 'serverInfo']),
    versionText() {
      let text = ''
      if (this.serverInfo) {
        text = this.serverInfo.Version || ''
      }
      return text.substring(text.indexOf(' ') + 1)
    },
  },
  beforeDestroy() {
    $(window).off('resize', this.resizeScroll)
  },

  methods: {
    ...mapActions('menu', ['updateRoute']),

    // 想要实现内部scroll
    resizeScroll() {
      const height = $(window).height() || 0
      const headerHeight =
        $('.main-header')[0].getBoundingClientRect().height || 0
      const footerHeight =
        $('.main-footer')[0].getBoundingClientRect().height || 0
      // 左侧导航栏
      $('.n-sider') && $('.n-sider').height(height - headerHeight)
      // 内容工作区
      $('#adlte-main') &&
        $('#adlte-main').height(height - headerHeight - footerHeight)
    },
    fixHover() {
      if (videojs.browser.IS_IOS || videojs.browser.IS_ANDROID) {
        for (
          var sheetI = document.styleSheets.length - 1;
          sheetI >= 0;
          sheetI--
        ) {
          var sheet = document.styleSheets[sheetI]
          if (sheet.cssRules) {
            for (var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
              var rule = sheet.cssRules[ruleI]
              if (rule.selectorText) {
                rule.selectorText = rule.selectorText.replace(
                  ':hover',
                  ':active'
                )
                rule.selectorText = rule.selectorText.replace(
                  ':focus',
                  ':active'
                )
              }
            }
          }
        }
      }
    },
    Year() {
      return moment().format('YYYY')
    },
  },
  watch: {
    // 更新路由信息
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.updateRoute(val)
      },
    },
  },
}
