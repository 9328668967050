import routes from '@/router/Index/routes'

export default {
  data() {
    return {
      routeMap: new Map(),
    }
  },
  created() {
    this.getRouteMap(routes)
  },
  methods: {
    // 递归遍历路由
    getRouteMap(routes) {
      routes
        .filter((route) => route.path != '*')
        .forEach((route) => {
          if (route.path) {
            let { component, ...routeArgs } = route
            this.routeMap.set(route.path, routeArgs)
          }
          if (route.children) {
            this.getRouteMap(route.children)
          }
        })
    },
    // 根据path获取路由信息
    getRouteByPath(path) {
      return this.routeMap.get(path)
    },
    // 获取全部菜单目录名称
    getMenuName() {
      return Array.from(this.routeMap.values())
        .filter((route) => route.meta && route.meta.title)
        .map((route) => ({
          value: route.meta.title,
        }))
    },
    // 获取全部菜单的url
    getMenuUrl() {
      return Array.from(this.routeMap.values())
        .filter((route) => route.meta && route.meta.title)
        .map((route) => ({
          value: route.path,
        }))
    },
    // 获取全部菜单的name
    getMenuKey() {
      return Array.from(this.routeMap.values())
        .filter((route) => route.meta && route.meta.title)
        .map((route) => ({ value: route.name }))
    },
    // 筛选目录菜单名称
    filterMenuName(querystr, cb) {
      var restaurants = this.getMenuName()
      var results = querystr
        ? restaurants.filter(this.createFilter(querystr))
        : restaurants
      cb(results)
    },
    // 筛选目录菜单url
    filterMenuUrl(querystr, cb) {
      var restaurants = this.getMenuUrl()
      var results = querystr
        ? restaurants.filter(this.createFilter(querystr))
        : restaurants
      cb(results)
    },
    // 筛选目录菜单name
    filterMenuKey(querystr, cb) {
      var restaurants = this.getMenuKey()
      var results = querystr
        ? restaurants.filter(this.createFilter(querystr))
        : restaurants
      cb(results)
    },
    // 创建筛选函数
    createFilter(querystr) {
      return function filterFn(item) {
        return item.value.toLowerCase().indexOf(querystr.toLowerCase()) === 0
      }
    },
  },
}
