export default {
  Location: '2号楼北-西',
  Time: '2024.08.26 17:10:31',
  Responsibles: [
    { name: '李四', phone: '13676541111' },
    { name: '鹿鸣湖', phone: '13876548741' },
  ],
  AlertStatus: '李四',
  WarnType: '通道拥塞',
}
