<script>
/**
 * @author lixidong
 * @description 告警列表
 */
import * as cameraApi from '@/api/camera_api/camera_api'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import ListenScroll from '@/mixins/listen_scroll'
import ImgBolb from '@/components/ImgBolb'
export default {
  mixins: [ListenScroll],
  components: {
    ImgBolb,
  },
  data() {
    return {
      warningTable: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
        value: [],
      },
      visible: false,
      visibleData: {},
    }
  },
  computed: {
    loading() {
      const status =
        this.warningTable.currentPage == 1 && this.warningTable.total == 0
      if (!status) {
        this.$nextTick(() => {
          this.handleScroll()
        })
      }
      return status
    },
  },
  mounted() {
    this.$bus.$on('refresh-warning', () => {
      this.warningTable = this.$options.data().warningTable
      this.getWarningList()
    })
  },
  methods: {
    // 展示告警详情
    handler(value) {
      this.$bus.$emit('warm-show', value)
    },
    async getWarningList() {
      if (
        this.warningTable.currentPage >
          Math.ceil(this.warningTable.total / this.warningTable.pageSize) &&
        this.warningTable.currentPage != 1
      )
        return
      let res = await cameraApi.selectEvent({
        PageInfo: {
          PageIdx: this.warningTable.currentPage,
          PageSize: this.warningTable.pageSize,
        },
      })
      if (res.code === 200) {
        let { Total, WarningInfos } = res.data
        if (!WarningInfos) WarningInfos = []
        const { pageSize, value, currentPage } = this.warningTable
        WarningInfos = WarningInfos.map((item) => ({
          ...item,
          uuid: uuidv4(),
          Timestamp: moment(item.CreateTime).format('YYYY-MM-DD HH:mm:ss'),
        }))
        if (!value) value = []
        this.warningTable = {
          currentPage: currentPage + 1,
          total: Total,
          pageSize: pageSize,
          value: value.concat(WarningInfos),
        }
      }
    },
  },
}
</script>

<template>
  <div v-resize="handleScroll" class="home-list home-item">
    <div class="home-detail-header">
      <div>告警列表</div>
      <div>详情</div>
    </div>
    <div
      v-loading="loading"
      element-loading-background="rgba(0,0,0,0.1)"
      class="home-detail-main scrollbar-no-y"
      ref="scrollbar"
    >
      <div ref="inner" class="innner">
        <div
          style="cursor: pointer"
          v-for="item in warningTable.value"
          :key="item.uuid"
          @click="handler(item)"
        >
          <ImgBolb
            :popover="false"
            lazy
            class="home-detail-main_image"
            :src="item.Snapshot"
          />
          <div class="home-list-info">
            <div style="color: var(--color-red)" class="ellipsis">抽烟告警</div>
            <el-row :gutter="10">
              <el-col style="display: flex" :span="24">
                <div style="flex-shrink: 0">时间：</div>
                <div
                  style="color: var(--color-white); flex: auto; font-size: 12px"
                >
                  {{ item.CreateTime | formatDate }}
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col class="ellipsis" :span="24"
                >布点：<span style="color: var(--color-white)">{{
                  item.Location
                }}</span></el-col
              >
            </el-row>
          </div>
        </div>
        <div
          style="text-align: center"
          v-if="!loading && warningTable.value.length == 0"
        >
          暂无数据
        </div>
      </div>
      <div
        style="text-align: center; height: 2px"
        v-load-more="{
          root: () => $refs.scrollbar,
          loadfn: getWarningList,
        }"
      ></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home-list {
  overflow: hidden;
  .home-detail-main {
    .innner > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      margin-top: 20px;
      .home-detail-main_image {
        width: 120px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 80px;
        margin-right: 5px;
      }
      .home-list-info {
        flex: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px;
      }
    }
    > div:first-child {
      margin-top: 0px;
    }
  }
}
</style>
