<script>
/**
 * @author lixidong
 * @description ai告警列表
 */
import tableMixins from './utils/table.js'
import ImgBolb from '@/components/ImgBolb'
// import { randomImage } from '@/utils/mock'
export default {
  mixins: [tableMixins],
  components: {
    ImgBolb,
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
    this.$bus.$on('warning-message', () => {
      this.$refs.tableTool && this.$refs.tableTool.requestTableData()
    })
  },
  methods: {
    // randomImage,
  },
}
</script>

<template>
  <div class="warning-list">
    <table-tool ref="tableTool" :tableData="tableData">
      <!-- 字段 -->
      <template slot="slot-AIBoxName" slot-scope="scope">
        <div>
          <div class="ellipsis">
            {{ scope.row.AIBoxName }}
          </div>
          <div class="ellipsis">{{ scope.row.CameraName }}</div>
        </div>
      </template>
      <template slot="slot-WarnType" slot-scope="scope">
        <div>
          <div class="ellipsis">
            {{ scope.row.WarnType }}
          </div>
          <div class="ellipsis">{{ scope.row.WarnMethod }}</div>
        </div>
      </template>
      <template slot="slot-Snapshot" slot-scope="scope">
        <ImgBolb
          :src="scope.row.Snapshot"
          lazy
          style="width: 120px; height: 60px"
        >
          <template slot-scope="{ src }">
            <div>
              <p>{{ scope.row.AIBoxName }}</p>
              <el-image style="width: 300px" :src="src"> </el-image>
            </div>
          </template>
        </ImgBolb>
      </template>
      <template slot="slot-Users" slot-scope="scope">
        <div>
          <div
            v-for="(item, index) in scope.row.Users"
            :key="item.Tel"
            class="ellipsis"
          >
            {{ item.NickName || item.UserName }}-{{ item.Tel }}
            <el-tag size="mini" type="warning">未读</el-tag>
          </div>
        </div>
      </template>
      <template slot="slot-b" slot-scope="scope">
        <div>
          <!-- <el-tag size="mini" type="success">已处理</el-tag> -->
          <el-tag size="mini" type="warning">未处理</el-tag>
        </div>
      </template>
      <!-- 按钮 -->
      <template slot="slot-info" slot-scope="scope">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-tooltip content="截图下载" placement="left">
              <img
                style="cursor: pointer; width: 14px; height: 14px"
                @click="download(scope.row.Snapshot)"
                src="@/assets/images/warm/imgd.png"
                alt="截图下载"
              />
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip content="录像下载" placement="left">
              <img
                style="cursor: pointer; width: 14px; height: 14px"
                @click="download(scope.row.Video)"
                src="@/assets/images/warm/vd.png"
                alt="录像下载"
              />
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip content="源视频流地址" placement="left">
              <img
                style="cursor: pointer; width: 14px; height: 14px"
                @click="playVideo(scope, 'source')"
                src="@/assets/images/warm/vp.png"
                alt="源视频流地址"
              />
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip content="分析后视频流地址" placement="left">
              <img
                style="cursor: pointer; width: 14px; height: 14px"
                @click="playVideo(scope, 'analyse')"
                src="@/assets/images/warm/vp2.png"
                alt="分析后视频流地址"
              />
            </el-tooltip>
          </el-col>
          <el-col :span="4">
            <el-tooltip content="详情" placement="left">
              <img
                style="cursor: pointer; width: 14px; height: 14px"
                @click="infoChannel(scope)"
                src="@/assets/images/warm/info.png"
                alt="详情"
              />
            </el-tooltip>
          </el-col>
        </el-row>
      </template>
    </table-tool>
    <Warm ref="warm" />
    <DialogPlayer ref="dialog-player" />
  </div>
</template>

<style lang="less" scoped></style>
