/**
 * @author lixidong
 * @description 路由守卫
 */
import store from '@/store'
import { Message } from 'element-ui'
export const beforeEach = async (to, from, next) => {
  try {
    // 无需登录，无需权限
    if (to.name && to.meta.noAuth) {
      next()
      return
    }
    // 验证是否登录
    // 刷新退出的原因之一，获取用户信息失败
    console.log('1. 获取用户信息')
    let resAstate = await store.dispatch('getUserInfo')
    if (!resAstate) {
      console.log('2. 获取用户信息失败')
      // window.localStorage.setItem('key', 'get userinfo fail')
      Message.error('获取用户信息失败，请重新登录')
      next('/logout')
      return
    }
    // 验证是否拥有页面权限
    const rootKeys = store.state.root.rootKeys || []
    if (to.name && to.meta.auth) {
      if (rootKeys.includes(to.name)) {
        // 拥有
        next()
        return
      } else {
        // 无权限
        next({
          name: 'nopermission',
        })
        return
      }
    }
    // 无需权限
    next()
  } catch (err) {
    next('/logout')
  } finally {
    // 关闭loading
    const loadingOverlay = document.getElementById('loading')
    if (loadingOverlay) {
      loadingOverlay.remove()
    }
  }
}
