/**
 * @author lixidong
 * @description 表格
 */
import TableTool from '@/components/TableTool/index.vue'
import * as userApi from '@/api/easy_darwin_server/user_api'
import { rermissionNameRules } from '@/utils/rules.js'
export default {
  components: { TableTool },
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,

        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'PermissionName',
            label: '名称',
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '绑定',
            slot: 'bind',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        PermissionName: '',
      },
      dialogBtnLock: false,
      dialogRules: {
        PermissionName: rermissionNameRules,
        PermissionName: rermissionNameRules,
      },
      drawer: false,
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 通道数据请求拦截
    async reqIntercept(query) {
      let res = await userApi.loadauthlist({
        CurrentPage: query.currentPage,
        PageSize: query.pageSize,
      })
      if (res.code === 200) {
        return { data: res.data.Permissions || [], total: res.data.Total || 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          const { auth_three, ...dialogForm } = this.dialogForm
          try {
            if (this.dialogTitle == 'add') {
              let res = await userApi.addAuth(dialogForm)
              if (res.code != 200) return
            } else {
              let res = await userApi.editAuth(dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 级联数据修改
    cascader(value) {
      var _value = { menus: [], doms: [], operations: [] }
      value.forEach((item) => {
        item.forEach((str) => {
          let strs = str.split('_')
          switch (strs[0]) {
            case 'operation':
              _value.operations.push(strs[1])
              break
            case 'dom':
              _value.doms.push(strs[1])
              break
            default:
              _value.menus.push(strs[1])
          }
        })
      })
      var val = {}
      Object.keys(_value).forEach((key) => {
        val[key] = [...new Set(_value[key])]
      })
      return val
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    // add
    addList() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.dialogFormVisible = true
    },
    //删除
    deleteList(row) {
      this.$confirm('此操作将永久删除这个权限，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await userApi.delAuth(row)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改通道
    updateList(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'update'
      this.dialogForm = Object.assign({}, row)
      this.dialogFormVisible = true
    },
  },
}
