/**
 * @author lixidong
 * @description 通道弹窗列表
 */
import * as deviceApi from '@/api/gosip_server/device_api.js'
import TableTool from '@/components/TableTool/index.vue'
export default {
  components: { TableTool },
  data() {
    return {
      channelDialogVisible: false,
      channelTableData: {
        pageSize: 10,
        stripe: true,
        border: false,
        height: 398,
        size: 'mini',
        cellClick: this.channelCellClick,
        reqIntercept: this.channelReqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'name',
            label: '通道名称',
          },
        ],
      },
    }
  },
  methods: {
    // 打开弹窗
    channelDialogShow() {
      this.channelDialogVisible = true
      this.$nextTick(
        () =>
          this.$refs.channelTableTool &&
          this.$refs.channelTableTool.requestTableData()
      )
    },
    // 通道数据请求拦截
    async channelReqIntercept(query) {
      let res = await deviceApi.channels_config({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: query.pageSize,
      })
      if (res.code === 200) {
        let { Total, List } = res.data
        return { data: List || [], total: Total }
      }
    },
    //  点击单元格
    async channelCellClick(row, column, cell, event) {
      this.channelName = row.name
      this.channelId = row.channelid
      this.channelDialogVisible = false
    },
  },
}
