/**
 * @author lixidong
 * @description 表格
 */
import * as userApi from '@/api/easy_darwin_server/user_api.js'
import { mapState } from 'vuex'
import { groupNameRules } from '@/utils/rules'
export default {
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        row_key: 'ID',
        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'GroupName',
            label: '组名称',
            width: 200,
          },
          {
            prop: 'RoleName',
            label: '角色名称',
          },
        ],
        btns: [
          {
            label: '子组',
            slot: 'add',
            width: 50,
          },
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '成员',
            slot: 'user',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        GroupName: '',
        RoleName: '',
        Path: null,
        ParentId: null,
      },
      dialogBtnLock: false,
      dialogRules: {
        GroupName: groupNameRules,
        RoleName: [{ required: true, message: '角色名称不能为空！' }],
      },
      initialized: false,
    }
  },
  activated() {
    // 在组件被激活时执行的逻辑
    if (this.initialized) {
      this.$refs.tableTool && this.$refs.tableTool.handleReset()
    }
  },
  mounted() {
    if (!this.initialized) {
      this.initialized = true
      this.$refs.tableTool && this.$refs.tableTool.handleReset()
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 表格数据请求拦截
    async reqIntercept(query) {
      let res = await userApi.loadgrouplist({
        CurrentPage: query.currentPage,
        PageSize: query.pageSize,
      })
      // 递归将Children转换为children
      const convertChildren = (data) => {
        if (data.Children) {
          data.children = data.Children.map((item) => convertChildren(item))
          delete data.Children
        }
        return data
      }
      if (res.code === 200) {
        let d = res.data.GroupInfos || []
        d = d.map((item) => convertChildren(item))
        return { data: d, total: res.data.Total || 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            // pwd md5
            const dialogForm = Object.assign({}, this.dialogForm)
            if (this.dialogTitle == 'add') {
              let res = await userApi.addgroup(dialogForm)
              if (res.code != 200) return
            } else {
              let res = await userApi.editgroup(dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //显示表单按钮逻辑
    dialogFormShow(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'add'
      let f = row
        ? { Path: row.Path + row.ID, ParentId: row.ID }
        : { Path: '0', ParentId: 0 }
      this.dialogForm = Object.assign({}, this.$options.data().dialogForm, f)
      this.dialogFormVisible = true
    },
    //删除逻辑
    deleteFn(row) {
      this.$confirm('此操作将永久删除该组，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { ID } = row
          let res = await userApi.delgroup({ ID })
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改用户
    async editFn(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'update'
      this.dialogForm = Object.assign({}, row)
      this.dialogFormVisible = true
    },

    // 查看用户
    navUserFn(row) {
      this.$router.push({
        name: 'group_user',
        query: { groupName: row.GroupName, id: row.ID, RoleName: row.RoleName },
      })
    },
  },
}
