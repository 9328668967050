<script>
/**
 * @auhtor lixidong
 * @description 时间轴
 */
import { debounce } from '@/utils/tool.js'
import moment from 'moment'
export default {
  props: {
    // 时间片段
    timeSlice: {
      type: Array,
      default: () => [{ start: '00:00', end: '24:00' }],
    },
  },
  data() {
    return {
      cursorText: '00:00',
      cursorPosition: 0,
      timeRuleWidth: 0,
      isDraggingc: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      timeChangeOld: 0,
      playStatus: false,
      seconds: 0,
      safes: [], // 可播放区域
    }
  },

  created() {
    this.resizeDo = debounce(this.resize, 200)
  },
  mounted() {
    this.addMouseEvents()
    this.addScrollEvents()
  },
  methods: {
    // timeSlice 数据格式化,
    timeSliceFormat() {
      // 先判断是否是想要的格式，不是就返回空数组
      if (!this.timeSlice.length) return []
      const timeSlice = this.timeSlice.map((item) => {
        const start = item.start.split(':')
        const end = item.end.split(':')

        return {
          start: parseInt(start[0]) * 60 + parseInt(start[1]),
          end: parseInt(end[0]) * 60 + parseInt(end[1]),
        }
      })
      return timeSlice
    },
    // 每次修改，重置data数据，并且播放时间片段中最早的时间
    init() {
      //  每次修改，重置data数据
      const initialData = this.$options.data.call(this)
      Object.keys(initialData).forEach((key) => {
        this.$data[key] = initialData[key]
      })
      if (this.timeSlice.length) {
        // 生成可以播放的区间
        const timeSliceFormat = this.timeSliceFormat()
        this.safes = timeSliceFormat.map((item) => {
          return { left: item.start, width: item.end - item.start }
        })
        console.log(111, this.safes)

        //立即播放时间片段中最早的时间
        const minStartTime = Math.min(
          ...this.timeSlice.map((item) => item.startTime)
        )
        const hm = moment(minStartTime).format('HH:mm')
        const minutes =
          parseInt(hm.split(':')[0]) * 60 + parseInt(hm.split(':')[1])
        this.cursorText = hm
        // 修改指针的位置
        this.$nextTick(() => {
          this.updateTimeCursor(minutes)
          this.emitChangeTime()
        })
      }
    },
    // 修改当前时间
    setCurrentTime(num) {
      const time = this.seconds + num
      this.$emit('setCurrentTime', time < 0 ? 0 : time)
    },
    // 修改状态
    changeStatus(status) {
      this.playStatus = status
    },
    // 功能逻辑
    pause() {
      this.$emit('stop')
    },
    play() {
      this.$emit('start')
    },
    // 视频进度逻辑
    timeChange(seconds) {
      this.seconds = seconds
      if (this.isDraggingc) return
      const dx = Math.floor(seconds / 60)
      const cursorPosition = this.cursorPosition
      if (dx <= this.timeChangeOld) return
      let newLeft = cursorPosition + 1
      // 限制cursor在timeRule的范围内
      if (newLeft < 0) newLeft = 0
      if (newLeft > 1440) newLeft = 1440
      this.updateTimeCursor(newLeft)
      this.timeChangeOld = dx
    },
    // 修改时间和指针位置
    updateTimeCursor(position) {
      const cursor = this.$refs['timeAxis-cursor']
      const minutes = this.updateCursorText(position)
      cursor.style.left = `${minutes + 29}px`
      this.cursorPosition = minutes
    },
    // 触发更改时间的事件
    emitChangeTime() {
      // 将this.cursorText转化为分钟，判断它是否在时间片段中
      const minutes =
        parseInt(this.cursorText.split(':')[0]) * 60 +
        parseInt(this.cursorText.split(':')[1])
      const isTimeSlice = this.actionTimeSlice(minutes)
      if (isTimeSlice) {
        this.$emit('change', this.cursorText)
      } else {
        this.$message.error('当前区域没有可以播放的视频哦！')
      }
    },
    // 处理时间片段
    actionTimeSlice(minute) {
      const timeSliceFormat = this.timeSliceFormat()
      if (!timeSliceFormat.length) return false
      return timeSliceFormat.some((item) => {
        return minute >= item.start && minute < item.end
      })
    },
    // 页面逻辑
    // 添加鼠标事件
    addMouseEvents() {
      const cursor = this.$refs['timeAxis-cursor']
      cursor.addEventListener('mousedown', this.onMouseDown)
    },
    // cursor鼠标按下
    onMouseDown(event) {
      this.isDraggingc = true
      const cursor = this.$refs['timeAxis-cursor']
      const clientX = event.clientX
      const cursorPosition = this.cursorPosition
      var newCursorPosition = cursorPosition
      const onMouseMove = (e) => {
        const dx = e.clientX - clientX
        let newLeft = cursorPosition + dx
        // 限制cursor在timeRule的范围内
        if (newLeft < 0) newLeft = 0
        if (newLeft > 1440) newLeft = 1440
        const minutes = this.updateCursorText(newLeft)
        cursor.style.left = `${minutes + 29}px`
        newCursorPosition = minutes
      }

      const onMouseUp = () => {
        this.isDraggingc = false
        this.cursorPosition = newCursorPosition
        this.emitChangeTime()
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
    // 修改时间的变化
    updateCursorText(position) {
      const minutes = Math.round(position / 1) * 1
      const hours = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0')
      const mins = (minutes % 60).toString().padStart(2, '0')
      this.cursorText = `${hours}:${mins}`
      return minutes
    },
    // 根据参数补全时间
    addZero(num) {
      num = num - 1
      return num < 10 ? `0${num}:00` : `${num}:00`
    },
    // 根容器窗口大小变化
    resize(data) {
      this.timeRuleWidth = data[0].contentRect.width
    },
    // 鼠标按下
    addScrollEvents() {
      const timeRule = this.$refs['timeAxis-container']
      timeRule.addEventListener('mousedown', this.onScrollMouseDown) //cursor 的mousedown正在执行的时候，它不能执行
    },
    onScrollMouseDown(event) {
      if (this.isDraggingc) return
      const container = this.$refs['timeAxis-container']
      this.isDragging = true
      this.startDragX = event.clientX
      this.startScrollLeft = container.scrollLeft

      const onMouseMove = (e) => {
        if (!this.isDragging) return
        const dx = e.clientX - this.startDragX
        var startScrollLeft = this.startScrollLeft - dx
        if (startScrollLeft < 0) {
          startScrollLeft = 0
        }
        if (startScrollLeft > 1470 - this.timeRuleWidth) {
          startScrollLeft = 1470
        }
        container.scrollLeft = startScrollLeft
      }

      const onMouseUp = () => {
        this.isDragging = false
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
  },
  watch: {
    timeSlice: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<template>
  <div ref="timeAxis" v-resize="resizeDo" class="timeAxis">
    <!-- 时间容器 -->
    <div ref="timeAxis-container" class="timeAxis-container">
      <div ref="timeAxis-position" class="timeAxis-position">
        <div v-for="hour in 24" :key="hour" class="timeAxis-hour">
          <div :data-hour="addZero(hour)" class="timeAxis-minute"></div>
          <div v-for="minute in 5" :key="minute" class="timeAxis-minute"></div>
        </div>
      </div>
      <div class="timeAxis-able">
        <div
          v-for="(safe, index) in safes"
          :key="index"
          :style="{ left: safe.left + 'px', width: safe.width + 'px' }"
          class="timeAxis-safe"
        ></div>
      </div>
      <div ref="timeAxis-cursor" class="timeAxis-cursor"></div>
    </div>
    <div class="timeAxis-controls">
      <button
        :disabled="!playStatus"
        @click="setCurrentTime(-5)"
        class="timeAxis-btns"
      ></button>
      <button
        @click="play"
        :disabled="playStatus"
        class="timeAxis-btns"
      ></button>
      <button
        @click="pause"
        :disabled="!playStatus"
        class="timeAxis-btns"
      ></button
      ><button
        :disabled="!playStatus"
        @click="setCurrentTime(5)"
        class="timeAxis-btns"
      ></button>
    </div>
  </div>
</template>

<style lang="less" scoped>
@timeMinuteWidth: 1px;
@timeHourWidth: 6 * 10 * @timeMinuteWidth;
@timeTotalHourWidth: 24 * @timeHourWidth;
@timeMinuteColor: #9b9ca6;
@timeHourColor: #3d3d4e;
@hourHeight: 10px;
.timeAxis {
  width: 100%;
  height: 80px;
  .timeAxis-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    max-width: @timeTotalHourWidth + @timeHourWidth;
    box-sizing: border-box;
    padding: 0px calc(@timeHourWidth / 2);
    background-color: transparent;
    font-size: 12px;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
    }
    .timeAxis-position {
      width: @timeTotalHourWidth;
      height: 25px;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: calc(@timeHourWidth / 2);
      z-index: 2;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      &::after {
        content: '';
        display: block;
        clear: both;
      }
      .timeAxis-hour {
        width: @timeHourWidth;
        height: 100%;
        float: left;
        line-height: @hourHeight;
        overflow: visible;
        > .timeAxis-minute {
          height: @hourHeight;
          width: calc(@timeMinuteWidth * 10);
          background-color: inherit;
          float: left;
          box-sizing: border-box;
          position: relative;
          overflow: visible;
          &::before {
            content: '';
            height: 50%;
            width: @timeMinuteWidth;
            background-color: @timeMinuteColor;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        > .timeAxis-minute:first-child {
          &::before {
            height: 100%;
            background-color: @timeHourColor;
          }
          &::after {
            content: attr(data-hour);
            display: block;
            position: absolute;
            bottom: -1.5 * @hourHeight;
            left: -0.5 * @timeHourWidth;
            width: @timeHourWidth;
            text-align: center;
          }
        }
      }
    }
    .timeAxis-able {
      position: absolute;
      height: @hourHeight;
      width: @timeTotalHourWidth;
      top: 10px;
      left: calc(@timeHourWidth / 2);

      > .timeAxis-safe {
        position: absolute;
        background-color: rgba(77, 110, 231, 0.2);
        height: 100%;
        top: 0;
        width: 0px;
      }
    }
    .timeAxis-cursor {
      position: absolute;
      left: calc(@timeHourWidth / 2 - @timeMinuteWidth);
      top: 0;
      height: 25px;
      width: calc(@timeMinuteWidth * 2);
      background-color: var(--color-primary);
      z-index: 3;
      cursor: move;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid var(--color-primary);
      }
    }
  }
  .timeAxis-controls {
    line-height: 30px;
    text-align: center;
    .timeAxis-btns {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      outline: none;
      border: 0;
      background-color: transparent;
      margin: 0px 10px;
      padding: 0;
      background-size: 100% 100%;
    }
    > .timeAxis-btns:first-child {
      background-image: url('~@/assets/images/time_asix/xz.png');
    }
    > .timeAxis-btns:nth-child(2) {
      background-image: url('~@/assets/images/time_asix/dy.png');
    }
    > .timeAxis-btns:nth-child(3) {
      background-image: url('~@/assets/images/time_asix/gg.png');
    }
    > .timeAxis-btns:last-child {
      background-image: url('~@/assets/images/time_asix/xy.png');
    }
    .timeAxis-btns:disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
