<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import TableTool from '@/components/TableTool/index.vue'
import tableMixin from './utils/group_table.js'
import roleMixin from './utils/role.js'
import InfoConfirm from '@/components/InfoConfirm/index.vue'
export default {
  components: { TableTool, InfoConfirm },
  mixins: [tableMixin, roleMixin],
}
</script>

<template>
  <div class="groupm">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <auth-wrapper auth="add-group">
          <div class="groupm_header">
            <div>
              <el-button
                class="groupm_add-group"
                @click="() => dialogFormShow()"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加组</el-button
              >
            </div>
            <div>
              <info-confirm>
                <el-row
                  >1. 点击“添加组”按钮，可以实现<span class="info-highlight"
                    >新增组结构</span
                  >并且<span class="info-highlight">绑定拥有服务权限的角色</span
                  >。</el-row
                >
                <el-row
                  >2.
                  如果您拥有“编辑”和“删除”的权限，那么您就可以修改组的相应参数。</el-row
                >
                <el-row>3. 组结构下的成员将继承组所拥有的角色权限。</el-row>
              </info-confirm>
            </div>
          </div>
        </auth-wrapper>
      </template>
      <!-- 按钮操作 -->
      <template slot="slot-add" slot-scope="scope">
        <auth-wrapper slot auth="add-group">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon=" el-icon-circle-plus-outline"
              type="success"
              size="mini"
              @click.stop="() => dialogFormShow(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-group">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click.stop="editFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-user" slot-scope="scope">
        <auth-wrapper slot auth="update-group">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-user"
              type="primary"
              size="mini"
              @click.stop="navUserFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-group">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click.stop="deleteFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改组 -->
    <el-dialog
      top="100px"
      :close-on-click-modal="false"
      :title="dialogTitle | dialogTitleKeyValue"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="组名称" prop="GroupName">
              <el-input
                v-model="dialogForm.GroupName"
                placeholder="请输入组名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="RoleName">
              <div @click="roleDialogShow">
                <el-input
                  placeholder="请选择角色名称"
                  style="pointer-events: none"
                  v-model="dialogForm.RoleName"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
      <!-- 通道弹窗 -->
      <el-dialog
        top="100px"
        append-to-body
        title="角色列表"
        width="800px"
        :close-on-click-modal="false"
        :visible.sync="roleDialogVisible"
      >
        <table-tool
          noresize
          ref="roleTableTool"
          :tableData="roleTableData"
        ></table-tool>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.groupm {
  height: 100%;
  .groupm_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .groupm_header-form {
      display: flex;
      align-items: center;

      /deep/ .el-input {
        margin-right: 5px;
      }
    }
  }
}
</style>
