<script>
/**
 * @auhtor lixidong
 * @description 弹窗播放
 */
import LivePlayer from '@liveqing/liveplayer'
export default {
  components: { LivePlayer },
  props: {
    live: { type: Boolean, default: true }, //是否直播
    videoTitle: { type: String, default: '' }, //视频流右上角显示的标题
    poster: { type: String, default: '' }, //视频封面图片
    aspect: { type: String, default: 'fullscreen' }, //视频显示区域的宽高比
  },
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
      loading: true,
      title: '',
    }
  },
  methods: {
    // 打开弹窗
    showDialog(videoUrl, title) {
      this.videoUrl = videoUrl
      this.title = title
      this.dialogVisible = true
    },
    //  播放, 参数: 当前时间进度
    playHandler() {
      console.log('play')
      this.loading = false
    },
    // 播放器错误回调
    errorHandler(err) {
      console.log('liveplayer:' + err)
    },
    // 播放结束
    endedHandler() {
      console.log('liveplayer:播放结束')
    },
    // m3u8加载失败时触发通知消息
    messageHandler(query) {
      console.log('liveplayer:', query)
    },
    getCurrentTime(seconds) {
      this.$emit('timeupdate', seconds)
    },
    //
    onMediaInfo(query) {
      console.log('liveplayer:', query)
    },
    // 内部快照回调
    snapInside(query) {
      console.log('liveplayer:内部快照回调', query)
    },
    // 全屏转换
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
  },
  watch: {
    videoUrl: {
      handler(newVal) {
        if (newVal) this.loading = true
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<template>
  <el-dialog
    class="dialog-player"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    width="100vh"
  >
    <div class="liveplayer liveplayer-template">
      <LivePlayer
        style="height: 100%; width: 100%"
        v-loading="loading"
        v-if="dialogVisible"
        element-loading-background="rgba(0,0,0,1)"
        element-loading-text="视频拼命加载中"
        ref="liveplayer"
        loading
        :hide-big-play-button="live"
        :hide-waiting="false"
        @fullscreen="fullscreenChange"
        @play="playHandler"
        stretch
        :aspect="aspect"
        :videoUrl="videoUrl"
        :live="live"
        muted
        autoplay
        @timeupdate="getCurrentTime"
        @error="errorHandler"
        @ended="endedHandler"
        @snapInside="snapInside"
        @message="messageHandler"
      >
      </LivePlayer>
    </div>
  </el-dialog>
</template>
<style lang="less" scoped>
.dialog-player {
  /deep/.el-dialog__body {
    padding: 0;
  }
  .liveplayer {
    position: relative;
    width: 100%;
    height: 60vh;

    .liveplayer_btn {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #fff;
      background-color: hsla(0, 0%, 50%, 0.5);
      padding: 2px 5px;
      border-radius: 2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
