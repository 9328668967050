<script>
/**
 * @auhtor lixidong
 * @description 今日报警数量
 */
import tnoa01 from '@/assets/images/home/tnoa01.png'
import tnoa02 from '@/assets/images/home/tnoa02.png'
import tnoa03 from '@/assets/images/home/tnoa03.png'
import tnoa04 from '@/assets/images/home/tnoa04.png'
export default {
  data() {
    return {
      value: [
        { type: '消防', value: 20, icon: tnoa01 },
        { type: '财产', value: 20, icon: tnoa02 },
        { type: '设备', value: 25, icon: tnoa03 },
        { type: '车辆', value: 25, icon: tnoa04 },
      ],
    }
  },
  computed: {
    // 获取今日报警数量
    getTodayAlarm() {
      return this.value.reduce(
        (total, item) => parseInt(total) + parseInt(item.value),
        0
      )
    },
  },
}
</script>

<template>
  <div class="home-todayAlarm home-item">
    <div class="home-detail-header">
      <img src="@/assets/images/warm/header-common.png" alt="" />
      <div>
        <div class="home-detail-header-title">今日报警数量</div>
        <div n class="home-detail-header-subtitle">
          Today's number of alarms
        </div>
      </div>
      <div>{{ getTodayAlarm }}</div>
    </div>
    <div class="home-detail-main scrollbar-no-y">
      <el-row
        type="flex"
        style="flex-wrap: wrap; justify-content: space-between; height: 100%"
      >
        <el-col
          v-for="(item, index) in value"
          :key="index"
          :span="24 / value.length"
          style="
            min-width: 70px;
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <img
            style="width: 35px; height: 35px; margin-bottom: 5px"
            :src="item.icon"
            :alt="item.value"
          />
          <el-statistic
            group-separator=","
            :value="item.value"
            class="home-statistic"
            :title="item.type"
          ></el-statistic>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
