/**
 * @author lixidong
 * @description 微信接口
 */
import { get } from '../index'

// 小程序登录二维码
export const wx_login = (data, env_version = 'develop') => {
  return get('/easy_darwin_server/api/v1/wx_login', {
    ...data,
    env_version,
  })
}

// 小程序注册二维码
export const wx_register = (data, env_version = 'develop') => {
  return get('/easy_darwin_server/api/v1/wx_scene', {
    ...data,
    env_version,
  })
}
