<script>
/**
 * @auhtor lixidong
 * @description 重点区域
 */
import ka01 from '@/assets/images/home/ka01.png'
import ka02 from '@/assets/images/home/ka02.png'
import ka03 from '@/assets/images/home/ka03.png'
import ka04 from '@/assets/images/home/ka04.png'
export default {
  data() {
    return {
      value: [
        { type: '一号厂房', value: 20, icon: ka01 },
        { type: '二号厂房', value: 20, icon: ka02 },
        { type: '三号厂房', value: 25, icon: ka03 },
        { type: '四号厂房', value: 25, icon: ka04 },
      ],
    }
  },
}
</script>

<template>
  <div class="home-keyArea home-item">
    <div class="home-detail-header">
      <img src="@/assets/images/warm/header-common.png" alt="" />
      <div>
        <div class="home-detail-header-title">重点区域</div>
        <div class="home-detail-header-subtitle">Key areas</div>
      </div>
      <div></div>
    </div>
    <div class="home-detail-main scrollbar-no-y">
      <el-row
        type="flex"
        style="flex-wrap: wrap; justify-content: space-between; height: 100%"
      >
        <el-col
          v-for="(item, index) in value"
          :key="index"
          :span="24 / value.length"
          style="
            min-width: 70px;
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <img
            style="width: 35px; height: 35px; margin-bottom: 5px"
            :src="item.icon"
            :alt="item.value"
          />
          <el-statistic
            group-separator=","
            :value="item.value"
            class="home-statistic"
            :title="item.type"
          ></el-statistic>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
