<script>
/**
 * @auhtor lixidong
 * @description 告警
 */
import * as cameraApi from '@/api/camera_api/camera_api'
import LivePlayer from '@liveqing/liveplayer'
export default {
  components: { LivePlayer },
  props: {
    mockValue: {
      type: Object,
      default: null,
    },
    imgSrc: {
      type: String,
      default: '',
    },
    live: { type: Boolean, default: true }, //是否直播
    poster: { type: String, default: '' }, //视频封面图片
    aspect: { type: String, default: 'fullscreen' }, //视频显示区域的宽高比
  },
  data() {
    return {
      videoUrl: '',
      loading: true,
    }
  },
  methods: {
    //  告警播放类似回放，播放当前时间前后一分钟的时间段视频。
    async getVideoUrl() {
      let r = await cameraApi.getCameraLiveStream({
        DeviceName: this.mockValue.CameraName,
      })
      if (r.code === 200) {
        this.videoUrl = r.data['HLS-mpegts']
      }
    },

    //  播放, 参数: 当前时间进度
    playHandler() {
      console.log('play')
      this.loading = false
    },
    // 播放器错误回调
    errorHandler(err) {
      console.log('liveplayer:' + err)
    },
    // 播放结束
    endedHandler() {
      console.log('liveplayer:播放结束')
    },
    // m3u8加载失败时触发通知消息
    messageHandler(query) {
      console.log('liveplayer:', query)
    },
    getCurrentTime(seconds) {
      this.$emit('timeupdate', seconds)
    },
    //
    onMediaInfo(query) {
      console.log('liveplayer:', query)
    },
    // 内部快照回调
    snapInside(query) {
      console.log('liveplayer:内部快照回调', query)
    },
    // 全屏转换
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
  },
  watch: {
    mockValue: {
      handler(val) {
        // val.CameraName && this.getVideoUrl()
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div class="warm">
    <!-- <div class="warm-side">
      <div style="height: 100%; width: 100%; position: relative">
        <LivePlayer
          v-loading="loading"
          style="height: 100%; width: 100%"
          element-loading-background="rgba(0,0,0,1)"
          element-loading-text="视频拼命加载中"
          ref="liveplayer"
          loading
          :hide-big-play-button="live"
          :hide-waiting="false"
          @fullscreen="fullscreenChange"
          @play="playHandler"
          :aspect="aspect"
          :videoUrl="mockValue.Video"
          :live="live"
          muted
          autoplay
          @timeupdate="getCurrentTime"
          @error="errorHandler"
          @ended="endedHandler"
          @snapInside="snapInside"
          @message="messageHandler"
        >
        </LivePlayer>
      </div>
    </div> -->
    <div class="warm-main">
      <div class="li-skeleton">
        <img v-if="imgSrc" :src="imgSrc" alt="" />
        <!-- banner -->
        <!-- <img :src="banner" alt="" /> -->
      </div>
      <div class="warm-info" :class="{ 'li-skeleton': !mockValue }">
        <template v-if="mockValue">
          <div>
            <div>
              <span>报</span><span>警</span><span>类</span><span>型：</span>
            </div>
            <div class="ellipsis">{{ mockValue.WarnType }}</div>
          </div>
          <div>
            <div><span>地</span><span>点：</span></div>
            <div class="ellipsis">{{ mockValue.Location || '/' }}</div>
          </div>
          <div>
            <div><span>时</span><span>间：</span></div>
            <div class="ellipsis">{{ mockValue.CreateTime }}</div>
          </div>
          <div>
            <div><span>负</span><span>责</span><span>人：</span></div>
            <div class="warm-head">
              <div v-for="(item, index) in mockValue.Responsibles" :key="index">
                <span
                  v-if="item.name == mockValue.AlertStatus"
                  style="color: var(--color-green)"
                  class="el-icon-circle-check"
                ></span>
                <span v-else class="warm-circle"></span>
                &nbsp;{{ item.name + ' ' + item.phone }}
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>报</span><span>警</span><span>状</span><span>态：</span>
            </div>
            <div>
              {{ mockValue.AlertStatus }}
              <el-tag
                style="border-radius: 20px"
                effect="dark"
                size="small"
                type="primary"
                >已处理</el-tag
              >
              <!-- <el-tag
                style="border-radius: 20px"
                effect="dark"
                size="small"
                type="warning"
                >未处理</el-tag
              > -->
            </div>
          </div>
          <!-- <el-button v-else type="primary" size="small">提交处理</el-button> -->
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.warm {
  .vjs-live-display {
    display: none;
  }
}
</style>
<style scoped lang="less">
@height: 500px; // 500px

.warm {
  display: flex;

  .warm-side {
    flex-shrink: 0;
    flex-grow: 0;
    height: @height;
    width: 60%;
    padding-right: 20px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .warm-main {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    // width: 100%;
    height: @height;
    overflow: hidden;

    > div:first-child {
      width: 100%;
      height: calc(@height / 2);
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    > div:last-child {
      width: 100%;
      height: calc(@height / 2 - 20px);
    }

    .warm-info {
      .warm-circle {
        width: 14px;
        height: 14px;
        transform: scale(0.9);
        border-radius: 50%;
        display: inline-block;
        border: 1px solid var(--color-grey);
        box-sizing: border-box;
      }

      > div {
        display: flex;
        width: 100%;
        > div:first-child {
          width: 72px;
          display: flex;
          justify-content: space-between;
          color: var(--color-grey);
          flex-shrink: 0;
          flex-grow: 0;
        }

        > div:last-child {
          flex: auto;
          > div {
            display: flex;
            align-items: center;
          }
        }

        div {
          line-height: 30px;
        }
      }

      > div:first-child {
        color: var(--color-red);
        font-size: 24px;
        font-weight: bold;

        > div:first-child {
          color: var(--color-red);
          width: 120px;
        }
      }
      .warm-head {
        height: 100px;
        overflow-y: auto;
        // border: 1px solid #e6e6e6;
        padding: 0 5px;
        box-sizing: border-box;
        margin-bottom: 8px;
      }
    }
  }
}

.li-skeleton {
  background: linear-gradient(90deg, #f1f2f4 25%, #e6e6e6 37%, #f1f2f4 50%);
  background-size: 400% 100%;
  border-radius: 3px;
  animation: gradientAnimation 1.8s ease infinite;
  /* 添加动画效果 */
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
    /* 开始位置 */
  }

  100% {
    background-position: 0% 50%;
    /* 结束位置 */
  }
}
</style>
