<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import { mapState } from 'vuex'

import ChangePwd from '@/components/ChangePwd.vue'
import Home from './home.vue'
import House from './house.vue'
import Warm from '@/components/Warm/dialog.vue'
import WarmTimeMixin from '@/mixins/warm_time.js'
export default {
  mixins: [WarmTimeMixin],
  components: { ChangePwd, Home, House, Warm },
  data() {
    return {
      activeRouter: 'Home',
      routers: [
        {
          label: '首页',
          value: 'Home',
        },
        // {
        //   label: '实时',
        //   value: 'House',
        // },
      ],
      timer: null,
    }
  },
  mounted() {
    this.$bus.$on(
      'warm-show',
      (value) =>
        this.$refs.warm &&
        this.$refs.warm.showDialog &&
        this.$refs.warm.showDialog(value)
    )
  },
  computed: {
    ...mapState(['userInfo']),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 监听滚动条
    handleScroll() {
      // 如果y轴滚动超过50，就将ref=header的背景颜色设置黑色
      const header = this.$refs.header
      console.log(123456, window.screenY)
      if (window.scrollY > 0) {
        header.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
        header.style.backdropFilter = 'blur(10px)'
        header.style.webkitBackdropFilter = 'blur(10px)' // 兼容 Safari
      } else {
        header.style.backgroundColor = ''
        header.style.backdropFilter = ''
        header.style.webkitBackdropFilter = ''
      }
    },
    // 退出功能···
    logoutHandler() {
      this.$router.push({ path: '/logout' })
    },
  },
}
</script>

<template>
  <div
    v-img-lazy:backgroundImage="require('@/assets/images/warm/group3.png')"
    class="home-page"
  >
    <!-- 头部 -->
    <ChangePwd ref="change-pwd" />
    <div ref="header" class="home-page_header">
      <nav class="home-page_nav">
        <ul>
          <li
            v-for="item in routers"
            :key="item.value"
            @click="activeRouter = item.value"
            :class="{ active: activeRouter === item.value }"
          >
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </nav>
      <div class="navbar-custom-menu">
        <ul style="float: right" class="nav navbar-nav">
          <li class="dropdown" v-if="userInfo">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <img
                style="width: 20px; height: 20px; margin-right: 8px"
                src="@/assets/images/users.svg"
                alt=""
              />
              {{ userInfo.NickName || userInfo.UserName }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <a
                  @click.prevent="$refs['change-pwd'].dialogFormShow()"
                  role="button"
                  ><i class="fa fa-key"></i> 修改密码</a
                >
              </li>
              <li>
                <a @click.prevent="logoutHandler" role="button"
                  ><i class="fa fa-sign-out"></i> 退出登陆</a
                >
              </li>
            </ul>
          </li>
          <li v-else>
            <a href="/login.html"><i class="fa fa-user"></i> 登录</a>
          </li>
        </ul>
        <div @click="$router.go(-1)" class="home-page-back">
          <i class="el-icon-position"></i>
        </div>
      </div>
    </div>
    <div class="home-page_main">
      <component :is="activeRouter" />
    </div>
    <Warm ref="warm" />
  </div>
</template>

<style lang="less" scoped>
.home-page {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  // overflow: hidden;
  // overflow-y: auto;
  background-color: #0e0f16;
  position: relative;
  background: url('@/assets/images/warm/group3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .home-page-back {
    color: #b9b8b8;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    .el-icon-position {
      color: #b9b8b8;
    }
    &:hover {
      background-color: #eee;
      .el-icon-position {
        color: var(--color-black);
      }
    }
  }
  .home-page_header {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    z-index: 1000;
    top: 0;
    left: 0;

    .home-page_nav {
      text-align: center;
      height: 100%;
      ul {
        list-style-type: none; /* 去掉默认的列表样式 */
        padding: 0; /* 去掉内边距 */
        margin: 0; /* 去掉外边距 */
        display: inline-block; /* 使ul变成内联块元素 */
        height: 100%;
        > li {
          line-height: 50px;
          color: #9b9ca6;
          display: inline-block;
          padding: 0 30px;
        }
        > li.active {
          color: #fff;
          > span {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              display: block;
              bottom: -8px;
              left: -10px;
              width: calc(100% + 20px);
              border-bottom: 2px solid #0daa65;
            }
          }
        }
      }
    }
    .navbar-custom-menu {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;

      .dropdown-toggle {
        color: #fff;
        display: flex;
        align-items: center;
        img {
          display: block;
        }
      }
      .dropdown-toggle:hover,
      .dropdown-toggle :focus {
        color: #0e0f16;
      }
      .nav .open > li:hover,
      .nav .open > a:focus {
        color: #0e0f16;
      }
      .dropdown.open {
        a {
          color: #0e0f16;
        }
      }
      .navbar-home {
        float: left;
        position: relative;
        display: block;
        padding: 0 15px;

        i {
          color: #333;
          line-height: 50px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .home-page_main {
    position: relative;
    z-index: 2;
    height: calc(100% - 50px);
    /deep/ .home {
      @gap: 10px;
      box-sizing: border-box;
      padding: @gap;
      grid-gap: @gap;

      .home-item {
        // margin: 10px;
        // background-color: rgba(28, 31, 50, 80%);
        // border-radius: 10px;
        // padding: 10px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        box-sizing: border-box;
        padding: 10px;
        @home-detail-header: 40px;
        .home-detail-header {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          height: @home-detail-header;
          > img {
            width: 20px;
            height: 22px;
            flex-grow: 0;
            flex-shrink: 0;
          }
          > div:nth-child(2) {
            flex: auto;
            margin-left: 10px;
            overflow: hidden;
            > div {
              color: #fff;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            > div:first-child {
              font-size: 16px;
              margin-bottom: 1px;
              line-height: 1;
            }
            > div:last-child {
              font-size: 10px;
              line-height: 1;
            }
          }
          > div:last-child {
            font-size: 20px;
            color: #fff;
            flex-grow: 0;
            flex-shrink: 0;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            z-index: 1;
            background-color: #74c4ba;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            right: 0;
            z-index: 2;
            height: 3px;
            width: 14px;
            background-color: #ffb648;
          }
        }
        .home-detail-main {
          font-size: 12px;
          height: calc(100% - @home-detail-header);
          color: #0daa65;
          box-sizing: border-box;
          .home-statistic {
            .head {
              color: #fff;
              margin-bottom: 0px;
              line-height: 1;
              .title {
                font-size: 12px;
                line-height: 1;
              }
            }
            .con {
              color: var(--color-white);
              .number {
                font-size: 24px;
                color: #0daa65;
              }
              .minute {
                font-size: 12px;
                line-height: 1;
                color: #b4fcde;
              }
            }
          }
        }
        .home-detail-main:has(.home-statistic) {
          display: flex;
          align-items: center;
          > .el-row {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-height: 900px) {
  .home-page {
    .home-page_main {
      /deep/.home {
        .home-item {
          @home-detail-header: 30px;
          // header
          .home-detail-header {
            height: @home-detail-header;
            > img {
              width: calc(20px * 2 / 3);
              height: calc(22px * 2 / 3);
            }
            > div:nth-child(2) {
              > div:first-child {
                font-size: 12px;
              }
              > div:last-child {
                font-size: 8px;
              }
            }
            > div:last-child {
              font-size: 16px;
            }
          }
          // body
          .home-detail-main {
            img {
              width: 25px !important;
              height: 25px !important;
            }
            .home-statistic {
              .head {
                .title {
                  font-size: 10px;
                }
              }
              .con {
                .number {
                  font-size: 16px;
                }
                .minute {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
