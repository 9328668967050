<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import * as echarts from 'echarts'
import optionfn from './utils/optionfn'
export default {
  data() {
    return {
      chart: null,
    }
  },
  methods: {
    getLast30Days() {
      let result = []
      let date = new Date()
      for (let i = 0; i < 30; i++) {
        let day = new Date(date)
        day.setDate(date.getDate() - i)
        let month = (day.getMonth() + 1).toString().padStart(2, '0')
        let dayOfMonth = day.getDate().toString().padStart(2, '0')
        result.unshift(`${month}/${dayOfMonth}`)
      }
      return {
        xAxisData: result,
        yAxisData: Array.from(
          { length: 30 },
          (_, i) => 100 - i * (98 / 29)
        ).map((t, i) => {
          if (i == 100) return 100
          return Math.max(2, Math.ceil(t - Math.random() * 10))
        }),
      }
    },

    main(width, height) {
      const { xAxisData, yAxisData: seriesData } = this.getLast30Days()

      var option = optionfn(xAxisData, seriesData)
      var chart = echarts.init(this.$refs.box, null, { width, height })
      chart.setOption(option)
      this.chart = chart
    },
    resize(domData) {
      const { width, height } = domData[0].contentRect
      if (this.chart) {
        this.chart.resize({ width, height })
      } else {
        this.main(width, height)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<template>
  <div class="home-day home-item">
    <div class="home-detail-header">
      <div>近30天报警</div>
      <div>详情</div>
    </div>
    <div v-resize="resize" ref="box" class="home-detail-main"></div>
  </div>
</template>
