<script>
/**
 * @author lixidong
 */
import { mapActions } from 'vuex'
import PageVisibilityObserver from '@/utils/page-visibility-observer'
export default {
  data() {
    return {
      visibilityObserver: null,
    }
  },
  created() {
    // 获取菜单配置
    this.getMenuConfig()
  },
  watch: {
    // 更新路由信息
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.updateRoute(val)
      },
    },
  },
  mounted() {
    this.pageVisibilityBug()
  },
  methods: {
    ...mapActions('menu', ['updateRoute', 'getMenuConfig']),
    // 解决页面失活，视频播放链接失效问题
    pageVisibilityBug() {
      const observer = new PageVisibilityObserver((state) => {
        if (state === 'visible') {
          console.log('页面处于活动状态')
          this.$bus.$emit('PAGEVISIBILITY', true)
        } else if (state === 'hidden') {
          console.log('页面已隐藏')
          this.$bus.$emit('PAGEVISIBILITY', false)
        }
      })
      this.visibilityObserver = observer
    },
  },
  beforeDestroy() {
    // 断开观察
    visibilityObserver && visibilityObserver.disconnect()
  },
}
</script>

<template>
  <div id="app">
    <keep-alive :max="10">
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
</style>
