<script>
/**
 * @auhtor lixidong
 * @description 组用户
 */
import OnlyHeaderLTE from '@/components/AdminLTE/OnlyHeaderLTE.vue'
import TableTool from '@/components/TableTool/index.vue'
import tableMixin from './utils/table.js'
export default {
  components: { TableTool, OnlyHeaderLTE },
  mixins: [tableMixin],
}
</script>

<template>
  <OnlyHeaderLTE>
    <div class="group-user">
      <table-tool ref="tableTool" :tableData="tableData">
        <template slot="header">
          <div class="groupm_header">
            <div>
              <auth-wrapper auth="add-group-user">
                <el-button
                  class="groupm_add-user"
                  @click="dialogFormShow"
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  >添加成员</el-button
                >
              </auth-wrapper>
            </div>
            <div>
              <el-button size="mini" @click="$router.go(-1)">返回</el-button>
            </div>
          </div>
        </template>
        <!-- 按钮操作 -->
        <template slot="slot-delete" slot-scope="scope">
          <auth-wrapper slot auth="delete-group-user">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                :underline="false"
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click.stop="deleteFn(scope.row)"
              ></el-link>
            </template>
          </auth-wrapper>
        </template>
      </table-tool>
      <!-- 添加修改成员 -->
      <el-dialog
        top="100px"
        :close-on-click-modal="false"
        :title="dialogTitle | dialogTitleKeyValue"
        :visible.sync="dialogFormVisible"
      >
        <el-form
          size="mini"
          ref="dialogForm"
          :model="dialogForm"
          :rules="dialogRules"
          label-width="100px"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="成员" prop="UserIDs">
                <el-select
                  multiple
                  v-model="dialogForm.UserIDs"
                  placeholder="请选择组成员"
                >
                  <el-option
                    v-for="item in allUser"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                    style="padding-right: 20px"
                  >
                    <span>{{ item.label }}-{{ item.nickName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
          <el-button
            size="mini"
            type="primary"
            :loading="dialogBtnLock"
            @click="dialogFormSubmit('dialogForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </OnlyHeaderLTE>
</template>

<style lang="less" scoped>
.group-user {
  .groupm_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .groupm_header-form {
      display: flex;
      align-items: center;

      /deep/ .el-input {
        margin-right: 5px;
      }
    }
  }
}
</style>
