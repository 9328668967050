<script>
/**
 * @author lixidong
 * @description 运维管理
 */
export default {
    
}
</script>

<template>
    <div class="operation-management">
        
    </div>
</template>