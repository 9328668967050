<script>
/**
 * @author lixidong
 * @description 边缘配置
 */
import AdminNoLTE from '@/components/AdminLTE/AdminNoLTE.vue'
import NSider from '@/components/NSider.vue'
import WarmingBox from './warmingBox.vue'
import * as nodeExporter from '@/api/prometheus/node_exporter'
import tableMixin from './utils/table.js'
import getChannlInfo from './utils/getChannlInfo'
import ImgBolb from '@/components/ImgBolb'
export default {
  mixins: [tableMixin, getChannlInfo],
  components: { AdminNoLTE, NSider, WarmingBox, ImgBolb },
  data() {
    return {
      nodeExporterInstance: null,
      apibox: null,
      value: {
        cpu: null,
        memory: null,
        disk: null,
      },
      selectedRowId: null,
    }
  },
  computed: {
    rowClassName() {
      const { AIBoxName, CreateTime } = this.$route.params
      return (scope) => {
        return scope.row.AIBoxName === AIBoxName &&
          scope.row.CreateTime == CreateTime
          ? 'blinking-row'
          : ''
      }
    },
  },
  computed: {
    rowClassName() {
      const { AIBoxName, CreateTime } = this.$route.params
      return (scope) => {
        return scope.row.AIBoxName === AIBoxName &&
          scope.row.CreateTime == CreateTime
          ? 'blinking-row'
          : ''
      }
    },
  },
  methods: {
    // 刷新获取告警列表
    refresh() {
      this.$refs.tableTool && this.$refs.tableTool.requestTableData()
    },
    // 获取盒子信息
    getBoxInfo(data) {
      this.apibox = data
      // 请求告警列表数据
      this.refresh()
      // this.getNowTable();
      var { Addr: nodeExporterInstance } = data
      this.channelInfo(data)
      this.nodeExporterInstance = nodeExporterInstance
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => this.updateValue(), 1000 * 60)
      this.updateValue()
    },
    // 更新盒子状态
    async updateValue() {
      var nodeExporterInstance = this.nodeExporterInstance
      if (!nodeExporterInstance) return
      nodeExporterInstance += ':9100'
      // CPU 使用率查询
      const cpuQuery = `100 - (avg by (cpu) (rate(node_cpu_seconds_total{mode="idle",instance="${nodeExporterInstance}"}[1m])) * 100)`
      // 内存使用率查询
      const memoryQuery = `(1 - ((node_memory_MemFree_bytes{instance="${nodeExporterInstance}"} + node_memory_Inactive_bytes{instance="${nodeExporterInstance}"}) / node_memory_MemTotal_bytes{instance="${nodeExporterInstance}"})) * 100`
      // 硬盘使用率查询
      const diskQuery = `(1 - node_filesystem_free_bytes{instance="${nodeExporterInstance}"} / node_filesystem_size_bytes{instance="${nodeExporterInstance}"}) * 100`
      const PromiseArr = [cpuQuery, memoryQuery, diskQuery].map((query) => {
        return nodeExporter.getNodeExporter(query)
      })
      let res = await Promise.all(PromiseArr)
      const result = res.map((item, index) => {
        if (item.code == 200) {
          try {
            if (index == 0) {
              // cpu
              let time = item.data.result[0].value[0]
              // 所有cpu的平均值
              let value =
                item.data.result.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.value[1] * 1,
                  0
                ) / item.data.result.length
              return [time, value]
            }
            return item.data.result[0].value
          } catch (err) {
            return []
          }
        } else {
          return []
        }
      })
      this.value = {
        cpu:
          result[0].length > 0
            ? parseFloat(Math.max(0, result[0][1] * 1).toFixed(2))
            : 0,
        memory:
          result[1].length > 0
            ? parseFloat(Math.max(0, result[1][1] * 1).toFixed(2))
            : 0,
        disk:
          result[2].length > 0
            ? parseFloat(Math.max(0, result[2][1] * 1).toFixed(2))
            : 0,
      }
    },
  },

  beforeDestroy() {
    this.timer && clearInterval(this.timer)
    // this.clearTabletimer();
  },
}
</script>

<template>
  <AdminNoLTE mode>
    <NSider slot="sider">
      <WarmingBox @getBoxInfo="getBoxInfo" @getChannlInfo="channelInfo" />
    </NSider>
    <div class="edge-configuration">
      <el-card class="edge-configuration_card">
        <div class="edge-configuration_flex">
          <el-row class="edge-configuration_row" type="flex" :gutter="20">
            <el-col :span="12">
              <el-row class="edge-configuration_row" type="flex" :gutter="20">
                <el-col style="width: auto">盒子状态</el-col>
                <el-col style="width: auto"
                  >CPU: <span>{{ value.cpu || '0.00' }}%</span>
                </el-col>
                <el-col style="width: auto"
                  >内存: <span>{{ value.memory || '0.00' }}%</span>
                </el-col>
                <el-col style="width: auto"
                  >硬盘: <span>{{ value.disk || '0.00' }}%</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row class="edge-configuration_row" type="flex" :gutter="20">
                <el-col style="width: auto"
                  >通道: 共<span>{{ nowPipelines.total }}</span
                  >路</el-col
                >
                <el-col style="width: auto"
                  ><el-tag type="primary" size="mini" plain>运行</el-tag>
                  {{ nowPipelines.run }}路</el-col
                >
                <el-col style="width: auto"
                  ><el-tag type="danger" size="mini" plain>停止</el-tag>
                  {{ nowPipelines.stop }}路</el-col
                >
              </el-row>
            </el-col>
          </el-row>
          <div @click="refresh" class="edge-configuration_refresh">
            <span class="el-icon-refresh-right"></span>
          </div>
        </div>
      </el-card>
      <div class="edge-configuration_table">
        <table-tool
          :row-class-name="rowClassName"
          ref="tableTool"
          :tableData="tableData"
        >
          <!-- 字段 -->
          <template slot="slot-AIBoxName" slot-scope="scope">
            <div>
              <div class="ellipsis">
                {{ scope.row.AIBoxName }}
              </div>
              <div class="ellipsis">{{ scope.row.CameraName }}</div>
            </div>
          </template>
          <template slot="slot-WarnType" slot-scope="scope">
            <div>
              <div class="ellipsis">
                {{ scope.row.WarnType }}
              </div>
              <div class="ellipsis">{{ scope.row.WarnMethod }}</div>
            </div>
          </template>
          <template slot="slot-Snapshot" slot-scope="scope">
            <ImgBolb
              :src="scope.row.Snapshot"
              lazy
              style="width: 120px; height: 60px"
            >
              <template slot-scope="{ src }">
                <div>
                  <p>{{ scope.row.AIBoxName }}</p>
                  <el-image style="width: 300px" :src="src"> </el-image>
                </div>
              </template>
            </ImgBolb>
          </template>
          <template slot="slot-Users" slot-scope="scope">
            <div>
              <div
                v-for="(item, index) in scope.row.Users"
                :key="index"
                class="ellipsis"
              >
                {{ item.NickName || item.UserName }}-{{ item.Tel }}
                <el-tag v-if="item.status" size="mini" type="success"
                  >已读</el-tag
                >
                <el-tag v-else size="mini" type="warning">未读</el-tag>
              </div>
            </div>
          </template>
          <template slot="slot-b">
            <div>
              <el-tag size="mini" type="success">已处理</el-tag>
              <!-- <el-tag size="mini" type="warning">未处理</el-tag> -->
            </div>
          </template>
          <!-- 按钮 -->
          <template slot="slot-info" slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="4">
                <el-tooltip content="截图下载" placement="left">
                  <img
                    style="cursor: pointer; width: 14px; height: 14px"
                    @click="download(scope.row.Snapshot)"
                    src="@/assets/images/warm/imgd.png"
                    alt="截图下载"
                  />
                </el-tooltip>
              </el-col>
              <el-col :span="4">
                <el-tooltip content="录像下载" placement="left">
                  <img
                    style="cursor: pointer; width: 14px; height: 14px"
                    @click="download(scope.row.Video)"
                    src="@/assets/images/warm/vd.png"
                    alt="录像下载"
                  />
                </el-tooltip>
              </el-col>
              <el-col :span="4">
                <el-tooltip content="源视频流地址" placement="left">
                  <img
                    style="cursor: pointer; width: 14px; height: 14px"
                    @click="playVideo(scope, 'source')"
                    src="@/assets/images/warm/vp.png"
                    alt="源视频流地址"
                  />
                </el-tooltip>
              </el-col>
              <el-col :span="4">
                <el-tooltip content="分析后视频流地址" placement="left">
                  <img
                    style="cursor: pointer; width: 14px; height: 14px"
                    @click="playVideo(scope, 'analyse')"
                    src="@/assets/images/warm/vp2.png"
                    alt="分析后视频流地址"
                  />
                </el-tooltip>
              </el-col>
              <el-col :span="4">
                <el-tooltip content="详情" placement="left">
                  <img
                    style="cursor: pointer; width: 14px; height: 14px"
                    @click="infoChannel(scope)"
                    src="@/assets/images/warm/info.png"
                    alt="详情"
                  />
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </table-tool>
      </div>
    </div>
    <Warm ref="warm" />
    <DialogPlayer ref="dialog-player" />
  </AdminNoLTE>
</template>

<style scoped lang="less">
.edge-configuration {
  position: relative;

  .edge-configuration_card {
    margin-bottom: 15px;
    height: 70px;
    box-sizing: border-box;
    overflow: hidden;
    .edge-configuration_flex {
      display: flex;
      align-items: center;
      .edge-configuration_row {
        flex: auto;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: nowrap;
        // flex-shrink: 0;
        // flex-grow: 0;
      }
      .edge-configuration_refresh {
        cursor: pointer;
        height: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        margin-left: 20px;
      }
    }
  }

  .edge-configuration_table {
    height: calc(100% - 85px) !important;
  }

  @keyframes blink {
    0% {
      background-color: var(--color-red);
    }
    50% {
      background-color: var(--color-white);
    }
    100% {
      background-color: var(--color-red);
    }
  }

  /deep/ .blinking-row {
    animation: blink 1s infinite;
  }
}
</style>
