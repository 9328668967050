/**
 * @author lixidong
 * @description 表格
 */
import * as userApi from '@/api/easy_darwin_server/user_api.js'
import { md5 } from '@/utils/tool.js'
import { mapState } from 'vuex'
import {
  usernameRules,
  nickNameRules,
  passwordRules,
  telRules,
} from '@/utils/rules.js'
export default {
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        expand: true,
        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'UserName',
            label: '用户名称',
          },
          {
            prop: 'NickName',
            label: '用户昵称',
          },
          {
            prop: 'RoleName',
            label: '角色名称',
          },
          {
            prop: 'Tel',
            label: '电话号码',
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        Password: '888888',
        UserName: '',
        NickName: '',
      },
      dialogBtnLock: false,
      pwdButtonLock: false,
      dialogRules: {
        UserName: usernameRules,
        RoleName: [{ required: true, message: '角色名称不能为空！' }],
        Password: passwordRules,
        NickName: nickNameRules,
        Tel: telRules,
      },
      initialized: false,
    }
  },
  activated() {
    // 在组件被激活时执行的逻辑
    if (this.initialized) {
      this.$refs.tableTool && this.$refs.tableTool.handleReset()
    }
  },
  mounted() {
    if (!this.initialized) {
      this.initialized = true
      this.$refs.tableTool && this.$refs.tableTool.handleReset()
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 跳转用户组
    cellClick() {
      this.$router.push({ path: '/user_center/user_mangement' })
    },
    // 表格数据请求拦截
    async reqIntercept(query) {
      let res = await userApi.loaduserlist({
        CurrentPage: query.currentPage,
        PageSize: query.pageSize,
      })
      if (res.code === 200) {
        return { data: res.data.UserInfos || [], total: res.data.Total || 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            // pwd md5
            const dialogForm = Object.assign({}, this.dialogForm)
            if (this.dialogTitle == 'add') {
              if (dialogForm['Password'])
                dialogForm['Password'] = md5(dialogForm['Password'])
              let res = await userApi.adduser(dialogForm)
              if (res.code != 200) return
            } else {
              let res = await userApi.edituser(dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //显示表单按钮逻辑
    dialogFormShow() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'add'
      this.dialogForm = this.$options.data().dialogForm
      this.pwdButtonLock = true
      this.dialogFormVisible = true
    },
    //删除逻辑
    deleteFn(row) {
      this.$confirm('此操作将永久删除该用户，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { ID } = row
          let res = await userApi.removeuser(ID)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改用户
    async editFn(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'update'
      this.dialogForm = Object.assign({}, row)
      this.pwdButtonLock = true
      this.dialogFormVisible = true
    },
  },
}
