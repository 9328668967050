/**
 * @author lixidong
 * @description 自定义项目常用的快捷方法
 */

import crypto from 'crypto-js'
import moment from 'moment'
import { Message } from 'element-ui'
import { download } from '@/api/index'

// 复制功能
export const copyText = (text) => {
  if (!text) return
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        Message.success('复制成功')
      })
      .catch(() => {
        Message.error('复制失败')
      })
  } else {
    const textarea = document.createElement('textarea')
    textarea.value = text

    // 添加到文档中
    textarea.style.position = 'fixed' // 防止页面滚动
    textarea.style.opacity = '0'
    document.body.appendChild(textarea)

    // 选择文本
    textarea.select()

    try {
      const result = document.execCommand('copy')
      if (result) {
        Message.success('复制成功')
      } else {
        Message.error('复制失败')
      }
    } catch (error) {
      console.error('复制错误:', error)
    }

    // 移除元素
    textarea.remove()
  }
}
// 判断图片类型的函数
function getImageType(byteArray) {
  // JPEG 文件头
  if (byteArray[0] === 0xff && byteArray[1] === 0xd8 && byteArray[2] === 0xff) {
    return 'image/jpeg'
  }
  // PNG 文件头
  if (
    byteArray[0] === 0x89 &&
    byteArray[1] === 0x50 &&
    byteArray[2] === 0x4e &&
    byteArray[3] === 0x47
  ) {
    return 'image/png'
  }
  // GIF 文件头
  if (byteArray[0] === 0x47 && byteArray[1] === 0x49 && byteArray[2] === 0x46) {
    return 'image/gif'
  }
  // BMP 文件头
  if (byteArray[0] === 0x42 && byteArray[1] === 0x4d) {
    return 'image/bmp'
  }
  // 未知类型
  return 'unknown'
}
// 下载图片
export async function downloadImage(url) {
  try {
    // , { cache: true } 解决图片路径不存在的问题
    // 获取图片
    let res = await download(url, { cache: true })
    console.log(res)
    if (!(res instanceof Blob)) {
      throw new Error('下载的资源不是一个有效的 Blob 对象')
    }

    function extractFilename(url) {
      // 定义正则表达式
      const regex = /\/([^\/?]+)(?=\?|$)/
      const match = regex.exec(url)

      // 如果匹配成功，返回匹配的内容，否则返回默认文件名
      return match ? match[1] : 'downloaded_image'
    }

    // 使用 FileReader 读取 Blob 数据
    const reader = new FileReader()
    reader.onload = function (event) {
      const arrayBuffer = event.target.result
      const byteArray = new Uint8Array(arrayBuffer)

      // 判断图片类型
      const type = getImageType(byteArray)
      // 从 URL 中提取文件名
      let filename = extractFilename(url) || 'downloaded_image'

      // 检查文件名是否已有后缀
      if (!filename.includes('.')) {
        switch (type) {
          case 'image/jpeg':
            filename += '.jpg'
            break
          case 'image/png':
            filename += '.png'
            break
          case 'image/gif':
            filename += '.gif'
            break
          case 'image/bmp':
            filename += '.bmp'
            break
          default:
            filename += '.bin' // 未知类型使用 .bin 后缀
        }
      }

      // 创建一个 URL 对象
      const downloadUrl = window.URL.createObjectURL(res)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = downloadUrl
      a.download = filename // 以视频文件名作为下载文件名
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(downloadUrl)
      document.body.removeChild(a)
    }

    // 读取 Blob 数据
    reader.readAsArrayBuffer(res)
  } catch (err) {
    console.log(err)
    Message.error('下载失败')
  }
}

// 转换日期
export function formatDate(value, format) {
  if (!value) return ''

  // 确保 value 是字符串类型
  const valueStr = typeof value === 'string' ? value : String(value)

  // 检查时间字符串是否包含 'Z' 或时区偏移
  const isUTC =
    valueStr.includes('Z') || valueStr.includes('+') || valueStr.includes('-')

  // 使用适当的 moment 方法解析时间
  const momentValue = isUTC ? moment.utc(valueStr) : moment(valueStr)

  const currentYear = moment.utc().year()
  const valueYear = momentValue.year()
  const isToday = moment.utc().isSame(momentValue, 'day')

  if (isToday) {
    format = 'HH:mm:ss' // 当天的日期不显示年月日
  } else if (currentYear === valueYear) {
    format = 'MM-DD HH:mm:ss' // 今年的日期不显示年
  } else {
    format = format || 'YYYY-MM-DD HH:mm:ss' // 其他年份显示完整格式
  }

  return momentValue.format(format)
}

/**
 * @description 解析json字符串，转换对象的健（大转小且_）
 */

export const transformJson = (json, filter) => {
  if (!json) return {}
  try {
    const obj = typeof json === 'string' ? JSON.parse(json) : json
    const transformedObject = {}
    Object.keys(obj).forEach((key) => {
      let newKey = ''
      if (filter && typeof filter[key] === 'function') {
        newKey = filter[key](key)
      } else {
        const restChars = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        )
        newKey = restChars.slice(1)
      }
      transformedObject[newKey] = obj[key]
    })
    return transformedObject
  } catch (err) {
    return {}
  }
}

/**
 * @description 微服务路径选择器
 * @param {*} url
 * @param {*} urls
 * @returns  urls=>item
 */
export const serverUrlHelper = (url, urls) => {
  let urlItem = urls.find((item) => item.transitionReg.test(url))
  return urlItem
}

/**
 * 字段判断规范化处理
 * @param {Number} value
 * @param {String} value
 */
export const ifspecification = (value, ifValue) => {
  const stringValue = String(value)
  const stringIfValue = String(ifValue)

  if (/^-?\d*\.?\d+$/.test(stringValue)) {
    return stringValue === stringIfValue
  }

  return stringValue.toUpperCase() === stringIfValue.toUpperCase()
}

/**
 * 防抖
 * @param {Function} func 执行函数
 * @param {*} delay  间隔时间
 * @returns
 */
export const debounce = (func, delay) => {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}
/***
 * 节流
 */

export const throttle = (func, wait) => {
  let timeout = null
  let lastTime = 0

  return function () {
    const context = this
    const args = arguments
    const now = new Date().getTime()

    if (now - lastTime >= wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      func.apply(context, args)
      lastTime = now
    } else if (!timeout) {
      timeout = setTimeout(function () {
        func.apply(context, args)
        lastTime = new Date().getTime()
      }, wait - (now - lastTime))
    }
  }
}
// 判断是否为同一天
export const isSameDay = (date1, date2) => {
  const d1 = date1.getDate()
  const m1 = date1.getMonth()
  const y1 = date1.getFullYear()

  const d2 = date2.getDate()
  const m2 = date2.getMonth()
  const y2 = date2.getFullYear()

  return d1 === d2 && m1 === m2 && y1 === y2
}

/**
 *  生成唯一id
 * @param {String/Number} key
 * @returns String
 * @example
 * // uuid(1)
 */
export const uuid = (key = '') => {
  return (
    Date.now().toString(36) + Math.random().toString(36).substr(2) + (key + '')
  )
}

/**
 * md5加密
 * @param {String/Number} text
 * @returns String
 */

export const md5 = (text) => {
  return crypto.MD5(text).toString()
}

/***
 * videojs 检测
 */

// 是否是移动设备
export const isMobile = () => {
  return videojs.browser.IS_IOS || videojs.browser.IS_ANDROID
}

// 是否是ie
export const isIE = () => {
  return !!videojs.browser.IE_VERSION
}

// 表单前后空格处理
export const trimmedForm = (form) =>
  Object.fromEntries(
    Object.entries(form).map(([key, value]) => [
      key,
      typeof value === 'string' ? value.trim() : value,
    ])
  )
