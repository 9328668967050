/**
 * @author lixidong
 * @description 角色管理 ()
 */
import * as userApi from '@/api/easy_darwin_server/user_api.js'
const root = {
  namespaced: true,
  state: {
    // 权限字段
    rootKeys: [],
  },
  mutations: {
    setRootKeys(state, rootKeys) {
      state.rootKeys = rootKeys
    },
  },
  actions: {
    // 获取权限字段
    async getRootKeys({ commit, state, dispatch }, userID) {
      let res = await userApi.getAuthUserKey(userID)
      if (res.code != 200) return
      let { Permissions } = res.data
      commit('setRootKeys', Permissions)
    },
  },
}

export default root
