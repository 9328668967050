/**
 * @author lixidong
 * @description 表格
 */
import * as cameraApi from '@/api/camera_api/camera_api'
import warningTable from '@/mixins/warning-table'
export default {
  mixins: [warningTable],
  methods: {
    // 通道数据请求拦截
    async reqIntercept(query) {
      let res = await cameraApi.selectEvent({
        PageInfo: {
          PageIdx: query.currentPage,
          PageSize: query.pageSize,
        },
        AiboxName: this.apibox.Name,
      })
      if (res.code === 200) {
        let { Total, WarningInfos } = res.data
        return { data: WarningInfos || [], total: Total }
      }
      return { data: [], total: 0 }
    },
  },
}
