export default {
  methods: {
    // 监听内容区和滚动区变化
    handleScroll() {
      if (
        this.$refs.scrollbar.scrollHeight > this.$refs.scrollbar.clientHeight
      ) {
        this.$refs.scrollbar.classList.add('scrollbar-y')
      } else {
        this.$refs.scrollbar.classList.remove('scrollbar-y')
      }
    },
  },
}
