<script>
/**
 * @author lixidong
 * @description  录像配置
 */
// 表格模块
import TableTool from "@/components/TableTool/index.vue";
export default {
    components: { TableTool },
    data () {
        return {
            // 搜索
            search: "",
            // 表格数据
            tableData: {
                stripe: true,
                border: false,

                size: "mini",
                columns: [
                    {
                        prop: "a",
                        label: "设备ID",
                        slot: true,
                        template: () => {
                            return `<span>789</span>`;
                        },
                    },
                    {
                        prop: "b",
                        label: "设备名称",
                    },
                    {
                        prop: "c",
                        label: "通道ID",
                    },
                    {
                        prop: "d",
                        label: "通道名称",
                    },
                    {
                        prop: "e",
                        label: "通道计划",
                    }
                ],
                btns: [

                    {
                        label: "编辑",
                        icon: "el-icon-edit",
                    },
                ],
            },
        }
    }
}
</script>
<template>
    <div class="video-recording">
        <el-tabs type="border-card">
            <el-tab-pane label="录像计划">
                <table-tool :tableData="tableData">
                    <template slot="header">

                    </template>
                </table-tool>
            </el-tab-pane>
            <el-tab-pane label="录像储存">
                <div class="video-recording_tags">录像存储</div>
                <el-form size="mini" label-width="80px">
                    <el-form-item label="存储路径">
                        <el-input></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary">保存设置</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<style lang="less" scoped>
.video-recording {
    .video-recording_tags {
        width: 100%;
        height: 48px;
        background-color: #f5f5f5;
        font-size: 16px;
        font-weight: 400;
        line-height: 48px;
        padding-left: 20px;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 18px;
    }

    .video-recording_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .video-recording_header-form {
            display: flex;
            align-items: center;

            /deep/ .el-input {
                margin-right: 5px;
            }
        }
    }

}
</style>