<script>
/**
 * @auhtor lixidong
 * @description 报警信息
 */
export default {
  data() {
    return {}
  },
}
</script>

<template>
  <div class="home-warm home-item">
    <div class="home-detail-header">
      <div>报警信息</div>
      <div>详情</div>
    </div>
    <div class="home-detail-main scrollbar-no-y">
      <div class="inner">
        <div class="warning-info">
          <div class="col">
            <div>今日报警数</div>
            <div>2</div>
          </div>
          <div class="col">
            <div>近30日平均报警</div>
            <div>24</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inner {
  height: 100%;
  .warning-info {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .col {
      width: 50%;
      min-width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > div:first-child {
        text-align: center;
      }
      > div:last-child {
        font-size: 18px;
        color: var(--color-white);
        font-weight: bold;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}
</style>
