<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
function getRandomTimeToday() {
  const startOfDay = new Date()
  startOfDay.setHours(0, 0, 0, 0) // 设置为今天凌晨

  const now = new Date()
  const randomTimestamp =
    startOfDay.getTime() +
    Math.random() * (now.getTime() - startOfDay.getTime())

  return new Date(randomTimestamp)
}

export default {
  data() {
    return {
      value: [
        { name: '鄂A6S5H8', datetime: getRandomTimeToday(), action: '进入' },
        { name: '鄂A355BJ', datetime: getRandomTimeToday(), action: '外出' },
        { name: '鄂AAK6216', datetime: getRandomTimeToday(), action: '外出' },
        { name: '鄂AC178W', datetime: getRandomTimeToday(), action: '外出' },
        { name: '鄂W3R978', datetime: getRandomTimeToday(), action: '进入' },
        { name: '鄂A5862U', datetime: getRandomTimeToday(), action: '外出' },
        { name: '鄂ALQ312', datetime: getRandomTimeToday(), action: '外出' },
        { name: '鄂A15C7U', datetime: getRandomTimeToday(), action: '进入' },
        { name: '鄂AR1587', datetime: getRandomTimeToday(), action: '进入' },
        { name: '鄂A93L67', datetime: getRandomTimeToday(), action: '外出' },
      ].sort((a, b) => b.datetime - a.datetime),
    }
  },
  methods: {
    // 补位
    strCover(val, step = 3) {
      val = String(val)
      if (val.length < step) {
        const stepVal = step - val.length
        for (let i = 0; i < stepVal; i++) {
          val = '0' + val // 在每次循环中前面加 '0'
        }
      }
      return val
    },
  },
}
</script>

<template>
  <div class="home-car home-item">
    <div class="home-detail-header">
      <div>车辆信息</div>
      <div>详情</div>
    </div>
    <div class="home-detail-main scrollbar-no-y">
      <table style="width: 100%; border-collapse: collapse">
        <tbody>
          <tr v-for="(item, index) in value" :key="index">
            <td class="ellipsis" style="white-space: nowrap">
              {{ item.name }}
            </td>
            <td
              style="
                color: var(--color-white);
                white-space: nowrap;
                padding: 0 2px;
              "
            >
              {{ item.datetime | formatDate }}
            </td>
            <td style="white-space: nowrap">
              <span style="color: var(--color-red)" :role="item.action">{{
                item.action
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style></style>
