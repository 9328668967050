<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import Warm from './index.vue'
import * as cameraApi from '@/api/camera_api/camera_api'
export default {
  components: { Warm },
  data() {
    return {
      dialogVisible: false,
      mockValue: null,
      imgSrc: '',
    }
  },
  methods: {
    async showDialog(videoData) {
      if (this.dialogVisible) return
      if (videoData) {
        const { Id } = videoData
        // let r = await cameraApi.selectEvent({ Id })
        // console.log(r)
        // if (r.code != 200) return
        // const { WarningInfos = [] } = r.data
        // if (WarningInfos.length == 0) return
        const { Snapshot, Users, ...args } = videoData
        this.imgSrc = Snapshot
        this.mockValue = Object.assign(
          {
            AlertStatus: '杨权',
            Responsibles: (Users || []).map((item) => {
              return { name: item.NickName || item.UserName, phone: item.Tel }
            }),
          },
          args
        )
      }
      this.dialogVisible = true
    },
  },
}
</script>

<template>
  <el-dialog
    :close-on-click-modal="false"
    width="400px"
    :visible.sync="dialogVisible"
  >
    <!--     width="80%" -->
    <warm
      v-if="dialogVisible"
      ref="warm"
      :mock-value="mockValue"
      :img-src="imgSrc"
    />
  </el-dialog>
</template>
