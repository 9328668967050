/**
 * @author lixidong
 * @description 拥有注册全局自定义方法
 */
import bus from '@/utils/bus.js'
import { ifspecification } from '@/utils/tool.js'
import loadMore from '@/utils/load-more.js'
import resize from '@/utils/resize.js'
import $ from 'jquery'

import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'admin-lte/dist/css/AdminLTE.css'
import 'admin-lte/dist/css/skins/_all-skins.css'
import '@/assets/styles/custom.less'
import 'animate.css/animate.min.css'

import 'bootstrap/dist/js/bootstrap.js'
import 'admin-lte/dist/js/adminlte.js'

import ElementUI from 'element-ui'
import '@/assets/styles/element-custom.scss'

// 引入icon-park
import '@icon-park/vue/styles/index.css'
import { LeftTwo } from '@icon-park/vue'

// 全屏
import fullscreen from 'vue-fullscreen'

//回到顶部
import BackToTop from 'vue-backtotop'

import uploadInput from '@/utils/uploadInput.js'

// copy
// import VueClipboard from 'vue-clipboard2'
import { copyText } from '@/utils/tool.js'
import AuthWrapper from '@/utils/authwrapper'

import ImgLazy from '@/utils/img-lazy.js'
import { formatDate } from '@/utils/tool.js'
if (process.env.VUE_APP_MOCK) {
  require('@/mock/index') // 仅在开发环境中引入 mock.js
}
const Plugin = {
  install(Vue) {
    Vue.use(ElementUI)
    Vue.component('LeftTwo', LeftTwo)
    Vue.component('AuthWrapper', AuthWrapper)
    Vue.use(fullscreen)
    Vue.use(BackToTop)
    // 注册自定义加载更多方法
    Vue.directive('load-more', loadMore)
    // 注册自定义上传文件方法
    Vue.directive('input-upload', uploadInput)
    Vue.directive('resize', resize)
    Vue.directive('img-lazy', ImgLazy)
    // 添加时间转换过滤
    Vue.filter('formatDate', formatDate)
    // copy
    // Vue.use(VueClipboard)
    // 自定义命令
    Vue.prototype.$bus = bus
    Vue.prototype.$JQuery = $
    Vue.prototype.$copyText = copyText
    // 字段判断规范话命令
    Vue.prototype.$ifspecification = ifspecification
    // 过滤
    Vue.filter('dialogTitleKeyValue', function (value, keyValue) {
      keyValue = keyValue || { add: '添加', update: '修改' }
      return keyValue[value] || '表单'
    })
  },
}

export default Plugin
