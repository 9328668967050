/**
 * @author lixidong
 * @description  正则
 * 1. ChangePwd 修改密码
 */
/**
 * @author lixidong
 * @description  正则
 * 1. ChangePwd 修改密码
 */
export const usernameRules = [
  { required: true, message: '用户账号（用户名称）不能为空！' },
  {
    pattern: /^[a-zA-Z][a-zA-Z0-9_]{2,15}$/,
    message: '字母开头，3-16 位',
  },
]

export const passwordRules = [
  { required: true, message: '用户密码不能为空！' },
  {
    pattern: /^[?=.*A-Za-z\d]{6,}$/,
    message: '至少 6 位，至少 6 位，使用字母、数字或?=.*',
  },
]

export const nickNameRules = [
  {
    pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,20}$/,
    message: '昵称长度为 2 到 20 个字符，且只能包含汉字、字母、数字和下划线',
  },
]
export const telRules = [
  { required: true, message: '电话号码不能为空！' },
  { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号' },
]
export const groupNameRules = [
  { required: true, message: '组名称不能为空！' },
  {
    pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,20}$/,
    message: '组名称长度为 2 到 20 个字符，且只能包含汉字、字母、数字和下划线',
  },
]

export const roleNameRules = [
  { required: true, message: '角色名称不能为空！' },
  {
    pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,20}$/,
    message: '角色名称长度为 2到 20 个字符，且只能包含汉字、字母、数字和下划线',
  },
]

export const rermissionNameRules = [
  { required: true, message: '权限名称不能为空！' },
  {
    pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]{2,20}$/,
    message:
      '权限名称长度为 2 到 20 个字符，且只能包含汉字、字母、数字和下划线',
  },
]

export const CNameRules = [
  { required: true, message: '名称不能为空！' },
  {
    pattern: /^[\u4E00-\u9FA5]{2,20}$/,
    message: '名称长度为 2 到 20 个汉字',
  },
]

export const ENameRules = [
  { required: true, message: 'key不能为空！' },
  {
    pattern: /^[\-a-zA-Z_-]{2,20}$/,
    message: 'key长度为 2 到 20 个字符，且只能包含字母和下划线和横杠',
  },
]
