import Vue from 'vue'
const observesMap = new WeakMap()
const loadMore = {
  inserted(el, binding) {
    const main = () => {
      const { root, loadfn, rootMargin, thresholdpx } = binding.value
      // 计算阈值
      const threshold = (function (root, thresholdpx) {
        const rootRect = (
          root || document.documentElement
        ).getBoundingClientRect()
        let useHeigth = rootRect.height
        if (!thresholdpx) return 0
        let thresholdNum = thresholdpx.replace('px', '') * 1 //去掉px
        if (thresholdNum > useHeigth) return 0
        return thresholdNum / useHeigth
      })(root(), thresholdpx)
      // 配置
      const options = {
        root: (root && root()) || null,
        rootMargin: rootMargin || '0px',
        threshold: threshold || 0.1, // 定义了元素进入视口的比例
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            loadfn() // 执行绑定的回调函数
          }
        })
      }, options)

      observer.observe(el)
      observesMap.set(el, observer)
    }
    Vue.nextTick(() => main())
  },
  unbind(el, binding, vnode) {
    // 消除监听效果
    const observer = observesMap.get(el)
    if (observer) {
      observer.unobserve(el)
      observesMap.delete(el)
    }
  },
}

export default loadMore
