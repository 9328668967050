export default function (xAxisData, seriesData, style = {}) {
  const maxTotal = Math.max(...seriesData)
  var option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: [
        {
          name: '2024/08',
          icon: 'rect',
          textStyle: {
            color: '#fff',
          },
          itemStyle: {
            color: style.color || '#6e7ace', // 设置矩形图标的颜色
          },
        },
      ],
      right: '5%', // 图例位置
      top: '0px', // 图例位置
      itemWidth: 15, // 设置矩形图标的宽度
      itemHeight: 8, // 设置矩形图标的高度
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLabel: {
        show: false, // 隐藏 x 轴的标签
      },
      axisLine: {
        lineStyle: {
          type: 'dotted', // 设置轴线为虚线点
          color: '#c4c4c4', // 设置轴线颜色
          width: 0.8,
          opacity: 0.2,
        },
      },
      axisTick: {
        show: false, // 隐藏刻度线
      },
    },
    grid: {
      left: '14%', // Y 轴的空间
      right: '5%', // 右边距
      top: '26px', // 上边距
      bottom: '15%', // 下边距
    },
    yAxis: {
      type: 'value',
      min: 0, // 根据你的数据设置适当的最小值
      max: maxTotal, // 根据你的数据设置适当的最大值
      axisLabel: {
        color: '#ffffff', // 设置 y 轴标签的颜色为白色
      },
      splitLine: {
        lineStyle: {
          type: 'dotted', // 将虚线类型设置为点状
          color: '#c4c4c4', // 设置虚线的颜色
          width: 0.8,
          opacity: 0.2,
        },
      },
    },
    // title: {
    //   text: '单位:%',
    //   left: '12px',
    //   top: '0', // 根据需要调整位置
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: 'normal',
    //     color: '#9b9ca6',
    //   },
    // },
    series: [
      {
        name: '2024/08',
        type: 'line',
        smooth: true, // 使线条平滑
        areaStyle: {}, // 开启面积图
        data: seriesData, // 使用随机生成的数据
        lineStyle: {
          width: 2, // 线条宽度
          color: style.color || '#6e7ace', // 线条颜色
        },
        itemStyle: {
          color: 'transparent', // 隐藏数据点
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: style.bgColor || 'rgba(58, 82, 255, 1)', // 渐变起始颜色
              },
              {
                offset: 1,
                color: 'rgba(97,106,175, 0)', // 渐变结束颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        showSymbol: false, // 隐藏数据点
      },
    ],
  }
  return option
}
