<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import FireAlarm from '@/components/Home/fireAlarm.vue'
import PropertySafety from '@/components/Home/propertySafety.vue'
import Devices from '@/components/Home/devices.vue'
import Cars from '@/components/Home/cars.vue'
import Personnel from '@/components/Home/personnel.vue'
import TodayAlarm from '@/components/Home/todayAlarm.vue'
import KeyArea from '@/components/Home/keyArea.vue'
import AverageResponseTime from '@/components/Home/averageResponseTime.vue'
import KeyTime from '@/components/Home/keyTime.vue'
import HandlingSituation from '@/components/Home/handlingSituation.vue'
export default {
  components: {
    FireAlarm,
    PropertySafety,
    Devices,
    Cars,
    Personnel,
    TodayAlarm,
    KeyArea,
    AverageResponseTime,
    KeyTime,
    HandlingSituation,
  },
}
</script>

<template>
  <div class="home">
    <TodayAlarm />
    <KeyArea />
    <AverageResponseTime />
    <KeyTime />
    <div class="home-main"></div>
    <HandlingSituation />
    <div class="home-l">
      <FireAlarm />
      <PropertySafety />
      <Devices />
      <Cars />
      <Personnel />
    </div>
  </div>
</template>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  display: grid; /* 确保这是网格容器 */
  grid-template-areas:
    'b a a a g'
    'c a a a g'
    'd a a a g'
    'e a a a g'
    'l l l l l';
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;
  grid-auto-rows: 1fr; /* 确保每行高度相同 */
  grid-auto-columns: 1fr; /* 确保每列宽度相同 */
  .home-main {
    grid-area: a;
    visibility: hidden;
  }
  .home-todyAlarm {
    grid-area: b;
  }
  .home-keyArea {
    grid-area: c;
  }
  .home-averageResponseTime {
    grid-area: d;
  }
  .home-keyTime {
    grid-area: e;
  }
  .home-handlingSituation {
    grid-area: g;
    background-color: rgba(13, 170, 101, 0.1);
    /deep/.el-tag {
      border-color: #0daa65;
    }
  }
  .home-l {
    grid-area: l;
    display: grid; /* 确保这是网格容器 */
    grid-template-areas: 'f i j k h';
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-auto-rows: 1fr; /* 确保每行高度相同 */
    grid-auto-columns: 1fr; /* 确保每列宽度相同 */
    grid-gap: 10px;
  }
  .home-fireAlarm,
  .home-propertySafety,
  .home-devices,
  .home-cars,
  .home-personnel {
    /deep/.radius {
      position: absolute;
      width: 10px;
      height: 10px;
      border-style: solid;
    }
    /deep/.r1 {
      border-width: 0;
      border-top-width: 1px;
      border-left-width: 1px;
      top: 0;
      left: 0;
    }
    /deep/.r2 {
      border-width: 0;
      border-top-width: 1px;
      border-right-width: 1px;
      top: 0;
      right: 0;
    }
    /deep/.r3 {
      border-width: 0;
      border-bottom-width: 1px;
      border-left-width: 1px;
      bottom: 0;
      left: 0;
    }
    /deep/.r4 {
      border-width: 0;
      border-bottom-width: 1px;
      border-right-width: 1px;
      bottom: 0;
      right: 0;
    }
  }

  .home-fireAlarm {
    grid-area: f;
    background-color: rgba(13, 170, 101, 0.1);
    /deep/.radius {
      border-color: #0daa65;
    }
  }
  .home-propertySafety {
    grid-area: i;
    background-color: rgba(255, 182, 72, 0.1);
    /deep/.radius {
      border-color: #ffb648;
    }
  }
  .home-devices {
    grid-area: j;
    background-color: rgba(18, 247, 255, 0.1);
    /deep/.radius {
      border-color: #12f7ff;
    }
  }
  .home-cars {
    grid-area: k;
    background-color: rgba(255, 182, 72, 0.1);
    /deep/.radius {
      border-color: #ffb648;
    }
  }
  .home-personnel {
    grid-area: h;
    background-color: rgba(13, 170, 101, 0.1);
    /deep/.radius {
      border-color: #0daa65;
    }
  }
}
</style>
