<script>
/**
 * @author lixidong
 * @description 通道列表
 */
// 表格模块
import OnlyHeaderLTE from '@/components/AdminLTE/OnlyHeaderLTE.vue'
import TableTool from '@/components/TableTool/index.vue'
import * as deviceApi from '@/api/gosip_server/device_api.js'
export default {
  components: { TableTool, OnlyHeaderLTE },
  data() {
    const streamtypelist = [
      {
        label: '媒体服务器拉流',
        value: 'pull',
        disabled: true,
      },
      {
        label: '摄像头推流',
        value: 'push',
      },
    ]
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        pageSize: 20,
        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          console.log(data)
          return data
        },
        columns: [
          {
            prop: 'channelid',
            label: 'ID',
            width: 200,
          },
          {
            prop: 'name',
            label: '名称',
            width: 200,
          },
          {
            prop: 'streamtype',
            label: '播放类型',
            type: 'select',
            value: streamtypelist,
          },
          {
            prop: 'url',
            width: 120,
            label: '静态拉流地址',
          },
          {
            prop: 'status',
            label: '在线',
            slot: true,
          },
          {
            prop: 'memo',
            width: 300,
            label: '备注',
          },
        ],
        btns: [
          {
            label: '播放',
            slot: 'play',
            width: 50,
          },
          {
            label: '录像',
            slot: 'video',
            width: 50,
          },
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '按需直播',
            slot: 'importVideo',
            width: 100,
          },
          {
            label: '流量',
            slot: 'kmms',
            width: 50,
          },
          {
            label: '启用',
            slot: 'start',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
          {
            label: '分析',
            slot: 'scanf',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        memo: '',
        streamtype: 'push',
        url: '',
      },
      dialogBtnLock: false,
      dialogRules: {},
      streamtypelist,
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 通道数据请求拦截
    async reqIntercept(query) {
      let _query = this.$route.query
      let res = await deviceApi.channels_config({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: query.pageSize,
        filters: JSON.stringify([
          { field_name: 'deviceid', opertator: '=', value: _query.deviceid },
        ]),
      })
      if (res.code === 200) {
        let { Total, List } = res.data
        return { data: List || [], total: Total }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            if (this.dialogTitle == 'add') {
              let res = await deviceApi.add_channel(this.dialogForm)
              if (res.code != 200) return
            } else {
              let res = await deviceApi.edit_channel(this.dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    // add
    addChannel() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.$route.query.deviceid &&
        this.$set(this.dialogForm, 'deviceid', this.$route.query.deviceid)
      this.dialogFormVisible = true
    },
    //删除通道
    deleteChannel(row) {
      this.$confirm('此操作将永久删除该通道，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await deviceApi.del_channel(row)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改通道
    updateChannel(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      ;(this.dialogTitle = 'update'), (this.dialogForm = Object.assign({}, row))
      this.dialogFormVisible = true
    },
  },
}
</script>
<template>
  <OnlyHeaderLTE>
    <div class="channel-mangement">
      <table-tool ref="tableTool" :tableData="tableData">
        <template slot="header">
          <div class="channel-mangement_header">
            <div>
              <auth-wrapper auth="add-channel">
                <el-button
                  @click="addChannel"
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  >添加通道</el-button
                >
              </auth-wrapper>
            </div>
          </div>
        </template>
        <!-- 字段 -->
        <template slot="slot-status" slot-scope="scope">
          <el-link
            :underline="false"
            :icon="
              $ifspecification(scope.row.status, 'on')
                ? 'el-icon-check'
                : 'el-icon-close'
            "
            :type="
              $ifspecification(scope.row.status, 'on') ? 'success' : 'danger'
            "
          ></el-link>
        </template>
        <!-- 操作按钮 -->
        <template slot="slot-edit" slot-scope="scope">
          <auth-wrapper slot auth="update-channel">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                :underline="false"
                icon="el-icon-edit"
                type="success"
                size="mini"
                @click="updateChannel(scope.row)"
              ></el-link>
            </template>
          </auth-wrapper>
        </template>
        <template slot="slot-delete" slot-scope="scope">
          <auth-wrapper slot auth="delete-channel">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                :underline="false"
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click="deleteChannel(scope.row)"
              ></el-link>
            </template>
          </auth-wrapper>
        </template>
      </table-tool>
      <!-- 添加修改通道 -->
      <el-dialog
        top="100px"
        :title="dialogTitle | dialogTitleKeyValue"
        :close-on-click-modal="false"
        :visible.sync="dialogFormVisible"
      >
        <el-form
          size="mini"
          ref="dialogForm"
          :model="dialogForm"
          :rules="dialogRules"
          label-width="80px"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="播放类型" prop="streamtype">
                <el-select
                  v-model="dialogForm.streamtype"
                  placeholder="请选择播放类型"
                >
                  <el-option
                    v-for="item in streamtypelist"
                    :disabled="item.disabled"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dialogForm.streamtype == 'pull'" :span="24">
              <el-form-item label="静态拉流地址" prop="url">
                <el-input
                  v-model="dialogForm.url"
                  placeholder="静态拉流地址"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="memo">
                <el-input
                  type="textarea"
                  autosize
                  v-model="dialogForm.memo"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
          <el-button
            size="mini"
            type="primary"
            :loading="dialogBtnLock"
            @click="dialogFormSubmit('dialogForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </OnlyHeaderLTE>
</template>

<style lang="less" scoped>
.channel-mangement {
  .channel-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>
