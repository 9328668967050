import * as cameraApi from '@/api/camera_api/camera_api.js'
export default {
  data() {
    return {
      warm_timer: null,
      warm_oldValue: null,
    }
  },
  mounted() {
    // this.getNowWarm()
  },
  methods: {
    // 获取最新一条告警信息
    getNowWarm() {
      this.clearWarmTimer()
      this.warm_timer = setTimeout(async () => {
        try {
          let r = await cameraApi.selectEvent({
            PageInfo: {
              PageIndex: 1,
              PageSize: 1,
            },
          })
          if (r.code == 200) {
            let firstData = r.data.WarningInfos ? r.data.WarningInfos[0] : {}
            if (!this.warm_oldValue) {
              this.warm_oldValue = firstData
            } else {
              const oldTime = new Date(this.warm_oldValue.CreateTime).getTime()
              const newTime = new Date(firstData.CreateTime).getTime()
              console.log(
                this.warm_oldValue.CreateTime,
                oldTime,
                firstData.CreateTime,
                newTime,
                newTime - oldTime
              )
              // 如果新的告警信息的时间大于旧的告警信息的时间，说明有新的告警信息
              if (newTime - oldTime > 0) {
                this.warm_oldValue = firstData
                this.$refs.warm &&
                  this.$refs.warm.showDialog &&
                  this.$refs.warm.showDialog(firstData)
                this.$bus.$emit('refresh-warning')
              }
            }
          }
        } finally {
          this.getNowWarm()
        }
      }, 1000 * 5)
    },
    // 销毁计时器
    clearWarmTimer() {
      this.warm_timer && clearTimeout(this.warm_timer)
    },
  },
  beforeDestroy() {
    this.clearWarmTimer()
  },
}
