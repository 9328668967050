var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector-item",class:{ active: _vm.auth },attrs:{"data-index":'通道' + (_vm.index + 1)}},[_c('el-form',{attrs:{"size":"small","label-width":"80px"}},[_c('el-row',{staticClass:"selector-item_row",attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"相机"}},[_c('el-select',{staticStyle:{"width":"100%"},on:{"change":_vm.cameraSelectionChanged},model:{value:(_vm.selectedCamera),callback:function ($$v) {_vm.selectedCamera=$$v},expression:"selectedCamera"}},_vm._l((_vm.cameras),function(camera){return _c('el-option',{key:camera.Id,attrs:{"value":camera.Id,"label":camera.Name}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"视频流"}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.selectedProfile),callback:function ($$v) {_vm.selectedProfile=$$v},expression:"selectedProfile"}},_vm._l((_vm.profiles),function(profile,index){return _c('el-option',{key:index,attrs:{"value":profile.Token,"label":profile.Token +
                ' （' +
                profile.VideoEncoderConfiguration.Resolution.Width +
                'x' +
                profile.VideoEncoderConfiguration.Resolution.Height +
                ' ' +
                profile.VideoEncoderConfiguration.Encoding +
                ' @ ' +
                profile.VideoEncoderConfiguration.RateControl.FrameRateLimit +
                'fps）'}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"算法"}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.selectedPipeline),callback:function ($$v) {_vm.selectedPipeline=$$v},expression:"selectedPipeline"}},_vm._l((_vm.pipelines),function(pipeline,index){return _c('el-option',{key:index,attrs:{"value":pipeline.Id,"label":pipeline.NameCn + ' - ' + pipeline.VersionCn}})}),1)],1)],1),_c('el-col',{staticStyle:{"line-height":"32px","text-align":"center"},attrs:{"span":2}},[_c('el-checkbox',{model:{value:(_vm.IsRtsp),callback:function ($$v) {_vm.IsRtsp=$$v},expression:"IsRtsp"}},[_vm._v("Rtsp")])],1),_c('el-col',{staticStyle:{"display":"flex","line-height":"32px","align-items":"center","height":"32px"},attrs:{"span":4}},[(_vm.other.state)?_c('div',{class:_vm._f("stateFilter")(_vm.other.state,'class')},[_vm._v(" "+_vm._s(_vm._f("stateFilter")(_vm.other.state))+" ")]):_vm._e(),(_vm.type == 'edit' && _vm.auth && !_vm.disabled)?_c('button',{staticClass:"pipeline-update",on:{"click":function($event){$event.preventDefault();return _vm.updatePipeline.apply(null, arguments)}}}):_vm._e(),(_vm.type == 'edit' && !_vm.auth && !_vm.disabled)?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":_vm.startPipeline}},[_vm._v("启动")]):_vm._e(),_c('button',{staticClass:"pipeline-del",on:{"click":function($event){$event.preventDefault();return _vm.deletePipeline.apply(null, arguments)}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }