<script>
/**
 * @author lixidong
 * @description 用户管理
 */
import * as userApi from '@/api/easy_darwin_server/user_api.js'
// 表格模块
import TableTool from '@/components/TableTool/index.vue'
import InfoConfirm from '@/components/InfoConfirm/index.vue'
import { roleNameRules } from '@/utils/rules.js'
export default {
  components: { TableTool, InfoConfirm },
  data() {
    return {
      // 搜索
      search: '',
      // 表格数据
      tableData: {
        stripe: true,
        border: false,

        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'RoleName',
            label: '角色名称',
          },
          {
            prop: 'PermissionName',
            label: '权限名称',
            // template: this.showAuth
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        RoleName: '',
        PermissionID: '',
      },
      dialogBtnLock: false,
      pwdButtonLock: false,
      dialogRules: {
        RoleName: roleNameRules,
        PermissionID: [{ required: true, message: '权限不能为空！' }],
      },
      authList: [],
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 获取权限列表
    async getAuth() {
      let res = await userApi.getAuth()
      if (res.code == 200) {
        this.authList = res.data.Permissions.map((item) => ({
          value: item.ID,
          label: item.PermissionName,
        }))
      }
    },
    // 表格数据请求拦截
    async reqIntercept(query) {
      let res = await userApi.loadrolelist({
        CurrentPage: query.currentPage,
        PageSize: query.pageSize,
      })
      if (res.code === 200) {
        return { data: res.data.Roles || [], total: res.data.Total || 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            const dialogForm = Object.assign({}, this.dialogForm)
            if (this.dialogTitle == 'add') {
              let res = await userApi.addrole(dialogForm)
              if (res.code != 200) return
            } else {
              let res = await userApi.editrole(dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //显示表单按钮逻辑
    async dialogFormShow() {
      await this.getAuth()
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'add'
      ;(this.dialogForm = this.$options.data().dialogForm),
        (this.pwdButtonLock = true)
      this.dialogFormVisible = true
    },
    //删除逻辑
    deleteFn(row) {
      this.$confirm('此操作将永久删除该角色，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { ID } = row
          let res = await userApi.delrole(ID)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改用户
    async editFn(row) {
      await this.getAuth()
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'update'
      this.dialogForm = Object.assign({}, row)
      this.pwdButtonLock = true
      this.dialogFormVisible = true
    },
  },
}
</script>

<template>
  <div class="role-mangement">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <auth-wrapper auth="add-role">
          <div class="role-mangement_header">
            <div>
              <el-button
                @click="dialogFormShow"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加角色</el-button
              >
            </div>
            <div>
              <info-confirm>
                <el-row
                  >1. 点击“添加角色”按钮，可以实现<span class="info-highlight"
                    >新增角色</span
                  >并且<span class="info-highlight"
                    >使角色拥有权限服务的能力</span
                  >。</el-row
                >
                <el-row
                  >2.
                  如果您拥有“编辑”和“删除”的权限，那么您就可以修改角色的相应参数。</el-row
                >
              </info-confirm>
            </div>
          </div>
        </auth-wrapper>
      </template>
      <!-- 按钮操作 -->
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-role">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click.stop="editFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-role">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click.stop="deleteFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改用户 -->
    <el-dialog
      top="100px"
      :close-on-click-modal="false"
      :title="dialogTitle | dialogTitleKeyValue"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色名称" prop="RoleName">
              <el-input
                v-model="dialogForm.RoleName"
                placeholder="请输入角色名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="权限" prop="PermissionID">
              <el-select
                v-model="dialogForm.PermissionID"
                placeholder="请选择权限"
              >
                <el-option
                  v-for="item in authList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.role-mangement {
  .role-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .role-mangement_header-form {
      display: flex;
      align-items: center;

      /deep/ .el-input {
        margin-right: 5px;
      }
    }
  }
}
</style>
