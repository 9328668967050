<script>
/**
 * @author lixidong
 * @description H5直播/点播播放器，使用简单，功能强大.支持WebRTC/MP4播放;
 *  支持m3u8/HLS播放;
 *  支持HTTP-FLV/WS-FLV/RTMP播放;
 *  支持直播和点播播放;
 *  支持播放器快照截图;
 *  支持点播多清晰度播放;
 *  支持全屏或比例显示;
 *   自动检测IE浏览器兼容播放;
 *  支持自定义叠加层;
 * @link https://www.liveqing.com/docs/manuals/LivePlayer.html#%E4%BA%8B%E4%BB%B6-event
 */
import LivePlayer from '@liveqing/liveplayer'
import { nextTick } from 'process'
export default {
  props: {
    live: { type: Boolean, default: true }, //是否直播
    videoUrl: { type: String, default: '' }, //视频流路径
    videoTitle: { type: String, default: '' }, //视频流右上角显示的标题
    poster: { type: String, default: '' }, //视频封面图片
    aspect: { type: String, default: 'fullscreen' }, //视频显示区域的宽高比
  },
  components: { LivePlayer },
  data() {
    return {
      loading: false,
      fullscreen: false,
      loop: false,
    }
  },
  mounted() {
    const fullscreen = this.$refs.liveplayer.isFullscreen()
    this.fullscreen = fullscreen
    // 建议后期删除
    if (process.env.VUE_APP_MOCK) {
      this.loop = true
    }
  },
  methods: {
    doPlay() {
      this.$refs.liveplayer && this.$refs.liveplayer.play()
    },
    doPause() {
      this.$refs.liveplayer && this.$refs.liveplayer.pause()
    },
    setCurrentTime(step) {
      console.log('setCurrentTime', step)
      this.$refs.liveplayer && this.$refs.liveplayer.setCurrentTime(step)
    },
    //  播放, 参数: 当前时间进度
    playHandler() {
      console.log('play')
      this.loading = false
      this.$emit('play')
    },
    // 暂停
    playPause() {
      console.log('pause')
      this.$emit('pause')
    },
    // 播放器错误回调
    errorHandler(err) {
      // console.log('liveplayer:' + err)
      this.loading = false
    },
    // 播放结束
    endedHandler() {
      console.log('liveplayer:播放结束')
    },
    // m3u8加载失败时触发通知消息
    messageHandler(query) {
      console.log('liveplayer:', query)
    },
    getCurrentTime(seconds) {
      this.$emit('timeupdate', seconds)
    },
    //
    onMediaInfo(query) {
      console.log('liveplayer:', query)
    },
    // 内部快照回调
    snapInside(query) {
      console.log('liveplayer:内部快照回调', query)
    },
    // 全屏转换
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
    // 修改 video-inner hide-pic-in-pic show-time
    changeVideoInner() {
      this.$nextTick(() => {
        const videoInner = document.querySelector(
          '.video-inner.hide-pic-in-pic.show-time>'
        )
        if (videoInner) {
          videoInner.classList.add('hide-pic-in-pic')
          videoInner.classList.add('show-time')
        }
      })
    },
  },
  watch: {
    videoUrl: {
      handler(newVal) {
        if (newVal) {
          this.loading = true
        } else {
          this.loading = false
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<template>
  <div
    class="liveplayer liveplayer-template"
    :class="{ 'liveplayer-live': live }"
  >
    <LivePlayer
      style="height: 100%"
      v-loading="loading"
      element-loading-background="rgba(0,0,0,1)"
      element-loading-text="视频拼命加载中"
      ref="liveplayer"
      loading
      :hide-big-play-button="live"
      :hide-waiting="false"
      @fullscreen="fullscreenChange"
      @play="playHandler"
      @pause="playPause"
      :aspect="aspect"
      :videoUrl="videoUrl"
      :live="live"
      :loop="loop"
      muted
      autoplay
      @timeupdate="getCurrentTime"
      @error="errorHandler"
      @ended="endedHandler"
      @snapInside="snapInside"
      @message="messageHandler"
    >
    </LivePlayer>
    <!-- 关闭 -->
    <div
      @click="$emit('closeChannel')"
      v-if="live && videoUrl"
      class="liveplayer_btn"
    >
      关闭
    </div>
    <!-- 选择通道 -->
    <div
      @click="$emit('selectChannel')"
      v-if="live && !videoUrl"
      class="liveplayer_btn"
    >
      选择通道
    </div>
  </div>
</template>
<style lang="less" scoped>
.liveplayer {
  position: relative;
  width: 100%;
  height: 100%;

  .liveplayer_btn {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: hsla(0, 0%, 50%, 0.5);
    padding: 2px 5px;
    border-radius: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
