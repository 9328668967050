<script>
/***
 * @author lixidong
 * @description 左侧导航栏可以组合，左侧margin-left:380px
 */
import commonMixin from './utils/common.js'
export default {
  mixins: [commonMixin],
}
</script>

<template>
  <div v-resize="resizeScroll" class="wrapper">
    <ChangePwd ref="change-pwd" />
    <NaviBar
      @dialogFormShow="() => $refs['change-pwd'].dialogFormShow()"
      :logoText="logoText"
      :logoMiniText="logoMiniText"
      :versionText="versionText"
    ></NaviBar>

    <slot name="sider" />

    <div id="adlte-main" class="content-section active">
      <section style="height: 100%" class="content">
        <!-- 面包屑 -->
        <BreadCrumb v-if="mode" />
        <!-- 内容区 -->
        <div
          :style="{ height: mode ? 'calc(100% - 30px)' : '100%' }"
          class="content-section_page"
        >
          <slot />
        </div>
      </section>
    </div>
    <footer class="run-ani_footer main-footer active">
      <div class="pull-right hidden-xs hide">EasyDarwin</div>
      <strong
        >Copyright &copy; {{ Year() }}
        <a href="http://www.aihaohong.com">www.aihaohong.com</a>.</strong
      >
      All rights reserved.
    </footer>
  </div>
</template>

<style lang="less" scoped>
.content-section,
.right-side,
.main-footer {
  transition: none;
  margin-left: 380px;
}

/deep/ .n-sider-right {
  width: 280px !important;
}
</style>
<style scoped lang="less">
.content-section_page {
  > div {
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="less" scoped>
#adlte-main {
  box-sizing: border-box;
}
.vue-back-to-top {
  background-color: transparent;
  left: 30px;
  bottom: 10px;
}

.sidebar-collapse .vue-back-to-top {
  display: none;
}
</style>
