<script>
/**
 * @author lixidong
 * @description 黑白名单
 */
// 表格模块
import OnlyHeaderLTE from '@/components/AdminLTE/OnlyHeaderLTE.vue'
import TableTool from '@/components/TableTool/index.vue'
import * as configurationApi from '@/api/gosip_server/configuration_api.js'
import { transformJson } from '@/utils/tool.js'
export default {
  components: { TableTool, OnlyHeaderLTE },
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        pageSize: 20,
        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'equipment_national_standard_number',
            label: '设备国标编号',
          },
          {
            prop: 'ua',
            label: 'UA',
          },
          {
            prop: 'ip',
            width: 120,
            label: 'IP',
          },
          {
            prop: 'port',
            label: '端口',
          },
          {
            prop: 'notes',
            width: 300,
            label: '备注',
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },

          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        equipment_national_standard_number: '',
        ua: '',
        ip: '',
        port: '',
        notes: '',
      },
      dialogBtnLock: false,
      dialogRules: {
        equipment_national_standard_number: [
          { required: true, message: '设备国标编号不能为空！' },
        ],
        ua: [{ required: true, message: 'UA不能为空！' }],
        ip: [{ required: true, message: 'IP不能为空！' }],
        port: [{ required: true, message: '端口不能为空！' }],
      },
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 通道数据请求拦截
    async reqIntercept(query) {
      const filters = JSON.stringify([
        { list_type: this.$route.params.type === 'w' ? 'w' : 'b' },
      ])
      let res = await configurationApi.black_and_white_list_select({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: 10,
        filters,
      })
      if (res.code === 200) {
        let { Total, List } = res.data
        List = (List || []).map((item) =>
          transformJson(item, {
            ID: () => 'id',
            IP: () => 'ip',
            UA: () => 'ua',
          })
        )
        return { data: List, total: Total }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            if (this.dialogTitle == 'add') {
              let res = await configurationApi.black_and_white_list_insert(
                this.dialogForm
              )
              if (res.code != 200) return
            } else {
              let res = await configurationApi.black_and_white_list_update(
                this.dialogForm
              )
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()

            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    // add
    addList() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.$route.query.deviceid &&
        this.$set(this.dialogForm, 'deviceid', this.$route.query.deviceid)
      this.dialogFormVisible = true
    },
    //删除通道
    deleteList(row) {
      this.$confirm('此操作将永久删除这个名单，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await configurationApi.black_and_white_list_delete(row)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改通道
    updateList(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      ;(this.dialogTitle = 'update'), (this.dialogForm = Object.assign({}, row))
      this.dialogFormVisible = true
    },
  },
}
</script>

<template>
  <OnlyHeaderLTE>
    <div class="black-and-white-list">
      <table-tool ref="tableTool" :tableData="tableData">
        <template slot="header">
          <div class="black-and-white-list_header">
            <div>
              <auth-wrapper auth="add-name-list">
                <el-button
                  @click="addList"
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  >添加{{
                    $route.params.type === 'w' ? '白名单' : '黑名单'
                  }}</el-button
                >
              </auth-wrapper>
            </div>
          </div>
        </template>
        <!-- 字段 -->
        <template slot="slot-status" slot-scope="scope">
          <el-link
            :underline="false"
            :icon="
              $ifspecification(scope.row.status, 'on')
                ? 'el-icon-check'
                : 'el-icon-close'
            "
            :type="
              $ifspecification(scope.row.status, 'on') ? 'success' : 'danger'
            "
          ></el-link>
        </template>
        <!-- 操作按钮 -->
        <template slot="slot-edit" slot-scope="scope">
          <auth-wrapper slot auth="update-name-list">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                :underline="false"
                icon="el-icon-edit"
                type="success"
                size="mini"
                @click="updateList(scope.row)"
              ></el-link>
            </template>
          </auth-wrapper>
        </template>
        <template slot="slot-delete" slot-scope="scope">
          <auth-wrapper slot auth="delete-name-list">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                :underline="false"
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click="deleteList(scope.row)"
              ></el-link>
            </template>
          </auth-wrapper>
        </template>
      </table-tool>
      <!-- 添加修改名单 -->
      <el-dialog
        top="100px"
        :title="dialogTitle | dialogTitleKeyValue"
        :close-on-click-modal="false"
        :visible.sync="dialogFormVisible"
      >
        <el-form
          size="mini"
          ref="dialogForm"
          :model="dialogForm"
          :rules="dialogRules"
          label-width="110px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="设备国标编号"
                prop="equipment_national_standard_number"
              >
                <el-input
                  :disabled="dialogTitle == 'update'"
                  v-model="dialogForm.equipment_national_standard_number"
                  placeholder="请输入设备国标编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="UA" prop="ua">
                <el-input
                  v-model="dialogForm.ua"
                  placeholder="请输入UA"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="UA" prop="ip">
                <el-input
                  v-model="dialogForm.ip"
                  placeholder="请输入IP"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="端口" prop="port">
                <el-input
                  v-model="dialogForm.port"
                  placeholder="请输入端口"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="notes">
                <el-input
                  type="textarea"
                  autosize
                  v-model="dialogForm.notes"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
          <el-button
            size="mini"
            type="primary"
            :loading="dialogBtnLock"
            @click="dialogFormSubmit('dialogForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </OnlyHeaderLTE>
</template>

<style lang="less" scoped>
.black-and-white-list {
  .black-and-white-list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>
