<script>
/**
 * @author lixidong
 * @description 多屏播放
 */
import LatticeIcon from '@/components/Icon/Lattice.vue'
import Player from '@/components/Player/index.vue'
export default {
  name: 'LiveBroadcasts',
  components: { LatticeIcon, Player },
  data() {
    return {
      // 显示区域高度
      viewHeight: 0,
      playerData: [null, null, null, null],
    }
  },
  computed: {
    gridBoxStyle() {
      let num = Math.sqrt(this.playerData.length)
      return { gridTemplateColumns: `repeat(${num}, 1fr)` }
    },
  },
  methods: {
    // 选择通道
    selectChannel(data) {
      this.handleChannelClick(data)
    },
    // 通道点击派发
    async handleChannelClick(data) {
      const players = this.$refs.player
      const nullIndexPlayer = this.playerData.findIndex((item) => !item)
      if (nullIndexPlayer === -1) {
        return this.$message.warning('视频区域已满！')
      }
      const status = players[nullIndexPlayer].handleChannelClick(data)
      if (status) this.$set(this.playerData, nullIndexPlayer, null)
    },
    // 改变宫格
    updatePlayerNumber(val) {
      const playerData = this.playerData
      let value = []
      for (let i = 0; i < val; i++) {
        const playerItem = playerData[i] || null
        value.push(playerItem)
      }
      this.playerData = value
    },
    // 修改内容区高度
    changeViewHeight() {
      this.viewHeight = $('.content-section_page').height() - 18 - 15
    },
    // 播放器初始华数据回调
    initDataCallback(key, rawdata, streamlist) {
      this.$set(this.playerData, key, { rawdata, streamlist })
    },
    // 播放器数据销毁回调
    destoryDataCallback(key) {
      this.$set(this.playerData, key, null)
    },
    // 播放类型选择回调
    streamtypeSelectCallback(key, val) {
      let streamItem = this.playerData[key]
      this.$set(this.playerData, key, { ...streamItem, streamtype: val })
    },
  },
}
</script>

<template>
  <div v-resize="changeViewHeight" class="live-broadcast">
    <!-- 分屏 -->
    <div class="live-broadcast_grid-btns">
      <LatticeIcon @click="updatePlayerNumber(1)" :count="1" />
      <LatticeIcon @click="updatePlayerNumber(4)" :count="4" />
      <LatticeIcon @click="updatePlayerNumber(9)" :count="9" />
      <LatticeIcon @click="updatePlayerNumber(16)" :count="16" />
    </div>
    <!-- 宫格 -->
    <div :style="gridBoxStyle" class="live-broadcast_grid-box">
      <div
        v-for="(item, index) in playerData"
        :key="index"
        :style="{ height: viewHeight / Math.sqrt(playerData.length) + 'px' }"
        class="live-broadcast_grid-item"
      >
        <Player
          ref="player"
          mode
          :id="index"
          @initDataCallback="initDataCallback"
          @destoryDataCallback="destoryDataCallback"
          @streamtypeSelectCallback="streamtypeSelectCallback"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.live-broadcast {
  .live-broadcast_grid-btns {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    svg {
      margin-right: 10px;
    }

    & > svg:last-child {
      margin-right: 0;
    }
  }

  .live-broadcast_grid-box {
    display: grid;

    .live-broadcast_grid-item {
      overflow: hidden;
      border: 1px solid #fff;
      box-sizing: border-box;
      position: relative;
    }
  }
}
</style>
