<script>
/**
 * @author lixidong
 * @description 表格组件二次封装
 * @param {object} tableData
 * @example
 * // tableData
 * const tableData={
 *  // 可参考elementui中table attricutes 等
 *   @url https://element.eleme.cn/#/zh-CN/component/table#table-column-scoped-slot
 *  // 如果没有可以根据需求自行添加
 *   columns:[],
 * }
 */
import { uuid as _uuid, debounce } from '@/utils/tool.js'
export default {
  props: {
    tableData: Object,
    rowClassName: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      data: [], //表格数据
      total: 0, //总条数,
      currentPage: 1, //当前页
      pageSize: 10, //limit,
      loading: false,
      // 自适应的高度设置
      resizeH: null,
    }
  },
  computed: {
    tableHeight() {
      if (this.tableData.height) return this.tableData.height
      return this.resizeH
    },
  },
  created() {
    this.resizeDo = debounce(this.resizeHeight, 100)
  },
  methods: {
    // 自适应高度
    resizeHeight() {
      const container = this.$refs['table-fn']?.parentElement || null
      if (!container) return
      const children = container.children
      let tableHeight = container.getBoundingClientRect().height
      function getHeight(el) {
        const rect = el.getBoundingClientRect()
        const style = getComputedStyle(el)
        const marginHeight =
          parseFloat(style.marginTop) + parseFloat(style.marginBottom)
        tableHeight -= rect.height + marginHeight
      }
      // 排除其他兄弟的高度。
      Array.from(children).forEach((el) => {
        if (el == this.$refs['table-fn']) return
        getHeight(el)
      })

      this.$refs.header && getHeight(this.$refs.header)
      this.$refs.pagination && getHeight(this.$refs.pagination)

      this.resizeH = tableHeight
    },
    // 简单生成uuid
    uuid(key) {
      return _uuid(key)
    },
    // 请求表格数据
    async requestTableData() {
      try {
        if (this.loading) return false
        this.loading = true
        let { reqIntercept = () => new Promise((resolve) => resolve()) } =
          this.tableData
        // 父组件定义请求
        let response = await reqIntercept({
          total: this.total,
          pageSize: this.pageSize,
          currentPage: JSON.parse(JSON.stringify(this.currentPage)),
        })
        this.data = response.data || []
        this.total = response.total || 0
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message,
        })
      } finally {
        this.loading = false
      }
    },
    // 修改limit
    handleSizeChange(val) {
      // 初始化
      this.currentPage = this.$options.data().currentPage
      // 赋值
      this.pageSize = val
      this.requestTableData()
    },
    // 修改当前页
    handleCurrentChange(val) {
      // 初始化
      this.data = []
      // 赋值
      this.currentPage = val
      this.requestTableData()
    },
    // 重置
    handleReset() {
      this.data = []
      this.currentPage = 1
      this.pageSize = 100
      this.total = 0
      this.requestTableData()
    },
    // link type
    linkType(value, arr) {
      const item = arr.find((item) => item.value == value)
      return item ? item['type'] : 'default'
    },
    // link label
    linkLabel(value, arr) {
      const item = arr.find((item) => item.value == value)
      return item ? item['label'] : null
    },
    //具名插槽
    slotName(key) {
      return `slot-${key}`
    },
    // 单元格点击事件
    onCellClick(row, column, cell, event) {
      this.tableData.cellClick &&
        this.tableData.cellClick(row, column, cell, event)
    },
    // 扩展行
    onExpandChange(row, expanded) {
      this.tableData.expandChange && this.tableData.expandChange(row, expanded)
    },
  },
  watch: {
    tableData: {
      handler(newVal) {
        if (newVal && newVal.pageSize) {
          this.pageSize = newVal.pageSize
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<template>
  <div ref="table-fn" v-resize="resizeDo" class="table-fn">
    <div ref="header" class="table-fn-header">
      <!-- 自定义头部 -->
      <slot name="header" />
    </div>
    <div class="table-fn-body">
      <el-table
        v-if="tableData.height || (!tableData.height && resizeH)"
        :class="{ 'table-fn-no-border': tableData.border === false }"
        v-loading="loading"
        style="width: 100%"
        :row-class-name="rowClassName"
        :data="data"
        :height="tableHeight"
        :max-height="tableData.maxHeight"
        :stripe="tableData.stripe"
        :border="tableData.border"
        :size="tableData.size"
        :fit="tableData.fit"
        :show-header="tableData.showHeader"
        :lazy="tableData.lazy"
        :row-key="tableData.row_key"
        :load="tableData.load"
        @cell-click="onCellClick"
        @expand-change="onExpandChange"
      >
        <!-- 展开行 -->
        <el-table-column v-if="tableData.expand" type="expand">
          <template slot-scope="scope">
            <slot name="expand" :row="scope.row" />
          </template>
        </el-table-column>
        <!-- 列配置 -->
        <el-table-column
          v-for="column in tableData.columns"
          :key="column.prop"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :min-width="column.minWidth"
          :fixed="column.fixed"
          :align="column.align || tableData.align"
          :header-align="column.headerAlign"
          :sortable="column.sortable"
          :sort-method="column.sortMethod"
          :sort-by="column.sortBy"
          :formatter="column.formatter"
          :resizable="column.resizable"
          :show-overflow-tooltip="column.showOverflowTooltip"
          :show-overflow-tooltip-method="column.showOverflowTooltipMethod"
        >
          <template slot-scope="scope">
            <!-- 具名插槽 -->
            <slot
              v-if="column.slot"
              :name="slotName(column.prop)"
              :row="scope.row"
              :index="scope.$index"
            />
            <!-- 自定义html -->
            <div
              v-else-if="column.template"
              v-html="column.template(scope)"
            ></div>
            <!-- select -->
            <span
              v-else-if="column.type == 'select'"
              :class="[
                'outline-link_' +
                  linkType(scope['row'][column.prop], column.value),
              ]"
              >{{ linkLabel(scope['row'][column.prop], column.value) }}</span
            >
            <!-- 默认 -->
            <template v-else>{{
              scope['row'][column.prop] || tableData.emptyText || '-'
            }}</template>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <!-- 第一种不同操作栏，不同功能 -->
        <template v-if="tableData.btns && Array.isArray(tableData.btns)">
          <el-table-column
            v-for="(item, index) in tableData.btns"
            :key="index"
            :width="item.width"
            :min-width="item.minWidth"
            :fixed="item.fixed || 'right'"
            align="center"
            :label="item.label || '操作'"
          >
            <template slot-scope="scope">
              <slot :name="slotName(item.slot)" :row="scope.row" />
            </template>
          </el-table-column>
        </template>

        <!-- 第二种一个指定操作栏，不同功能 -->
        <template
          v-if="
            tableData.btns &&
            !Array.isArray(tableData.btns) &&
            typeof tableData.btns === 'object'
          "
        >
          <el-table-column
            :width="tableData.btns.width"
            :min-width="tableData.btns.minWidth"
            :fixed="tableData.btns.fixed || 'right'"
            align="center"
            :label="tableData.btns.label || '操作'"
          >
            <template slot-scope="scope">
              <template v-if="tableData.btns.value">
                <el-button
                  v-for="(btn, btnkey) in tableData.btns.value"
                  :key="uuid(btnkey)"
                  :size="btn.size || tableData.size"
                  @click.stop="btn.click && btn.click(scope.$index, scope.row)"
                  :type="btns.type"
                  :icon="btn.icon"
                  >{{ btn.label }}</el-button
                >
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div
      ref="pagination"
      v-if="!tableData.footer_disabled"
      class="table-fn-footer"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style lang="less" scoped>
.table-fn {
  height: 100%;
  .table-fn-header {
    overflow: hidden;
  }

  .table-fn-body {
    .table-fn-no-border {
      border-left: 0;
      border-right: 0;
    }
  }

  .table-fn-footer {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
