<script>
/**
 * @author lixidong
 * @description 直播
 */
// 视频模块
import Player from '@/components/Player/index.vue'
// 标签页模块
import Tab from '@/components/Tab/index.vue'
import TabItem from '@/components/Tab/Item.vue'

import PlayControl from './playControl.vue'
import InfoStream from './infoStream.vue'
import Share from './share.vue'

export default {
  name: 'LiveBroadcast',
  data() {
    return {
      //    标签页
      tabs: [
        {
          label: '播放控制',
          id: 1,
        },
        {
          label: '信息流',
          id: 2,
        },
        {
          label: '分享',
          id: 3,
        },
      ],
      tabsActive: 1,
      // 视频流
      player_value: [],
      // 视频流协议
      video_scheam: '',
    }
  },
  computed: {
    player_url() {
      const stream = this.player_value.find(
        (item) => item.type == this.video_scheam
      )
      return stream ? stream.url : ''
    },
  },
  methods: {
    // 选择通道
    selectChannel(data) {
      this.$refs.player.handleChannelClick(data)
    },
    // 播放器初始华数据回调
    initDataCallback(rawdata, streamlist, video_scheam) {
      this.player_value = streamlist
      this.video_scheam = video_scheam
    },
    // 播放器数据销毁回调
    destoryDataCallback() {
      Object.assign(this.$data, this.$options.data.call(this))
    },
    // 播放类型选择回调
    streamtypeSelectCallback(val) {
      this.video_scheam = val
    },
    // 播放器切换类型
    changeStreamType(type) {
      this.$refs.player.changeStreamTypeRef(type)
      this.video_scheam = type
    },
  },
  components: {
    Player,
    Tab,
    TabItem,
    PlayControl,
    InfoStream,
    Share,
  },
}
</script>

<template>
  <div class="live-broadcast">
    <Player
      ref="player"
      liveplayeraspect="16:9"
      @initDataCallback="initDataCallback"
      @destoryDataCallback="destoryDataCallback"
    />
    <!-- 控制台 -->
    <el-card style="margin: 15px 0">
      <tab :value="tabs" :active.sync="tabsActive">
        <!-- 播放控制 -->
        <tab-item label="1">
          <PlayControl
            :changeStreamType="changeStreamType"
            :playerurl="player_url"
            :videoscheam="video_scheam"
            :playervalue="player_value"
          />
        </tab-item>
        <!-- 流信息 -->
        <tab-item label="2">
          <InfoStream />
        </tab-item>
        <!-- 分享 -->
        <tab-item label="3">
          <Share :playerurl="player_url" />
        </tab-item>
      </tab>
    </el-card>
  </div>
</template>

<style lang="less" scoped>
.live-broadcast {
  /deep/ .channel-player {
    height: auto;
  }

  .live-broadcast_code-url {
    /deep/ .el-input__suffix {
      right: 0;
      width: 30px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .live-broadcast_row {
    padding: 10px 0;
  }
}
</style>
