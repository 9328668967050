export default class PageVisibilityObserver {
  constructor(callback) {
    this.callback = callback
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  handleVisibilityChange() {
    if (typeof this.callback === 'function') {
      this.callback(document.visibilityState)
    }
  }

  disconnect() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  }
}
