<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import { mapState } from 'vuex'

import ChangePwd from '@/components/ChangePwd.vue'
import Home from './home.vue'
// import House from './house.vue'
import Warm from '@/components/Warm/dialog.vue'
import WarmTimeMixin from '@/mixins/warm_time.js'
export default {
  mixins: [WarmTimeMixin],
  components: { ChangePwd, Home, Warm },
  data() {
    return {
      // activeRouter: 'Home',
      // routers: [
      //   {
      //     label: '首页',
      //     value: 'Home',
      //   },
      //   {
      //     label: '实时',
      //     value: 'House',
      //   },
      // ],
      timer: null,
    }
  },
  mounted() {
    this.$bus.$on(
      'warm-show',
      (value) =>
        this.$refs.warm &&
        this.$refs.warm.showDialog &&
        this.$refs.warm.showDialog(value)
    )
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 退出功能···
    logoutHandler() {
      this.$router.push({ path: '/logout' })
    },
  },
}
</script>

<template>
  <div class="home-page">
    <!-- 头部 -->
    <ChangePwd ref="change-pwd" />
    <div class="home-page_header">
      <!-- <nav class="home-page_nav">
        <ul>
          <li
            v-for="item in routers"
            :key="item.value"
            @click="activeRouter = item.value"
            :class="{ active: activeRouter === item.value }"
          >
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </nav> -->
      <div class="navbar-custom-menu">
        <ul style="float: right" class="nav navbar-nav">
          <li class="dropdown" v-if="userInfo">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <img
                style="width: 20px; height: 20px; margin-right: 8px"
                src="@/assets/images/users.svg"
                alt=""
              />
              {{ userInfo.NickName || userInfo.UserName }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <a
                  @click.prevent="$refs['change-pwd'].dialogFormShow()"
                  role="button"
                  ><i class="fa fa-key"></i> 修改密码</a
                >
              </li>
              <li>
                <a @click.prevent="logoutHandler" role="button"
                  ><i class="fa fa-sign-out"></i> 退出登陆</a
                >
              </li>
            </ul>
          </li>
          <li v-else>
            <a href="/login.html"><i class="fa fa-user"></i> 登录</a>
          </li>
        </ul>
        <div @click="$router.go(-1)" class="home-page-back">
          <i class="el-icon-position"></i>
        </div>
      </div>
    </div>
    <div class="home-page_main">
      <!-- <component :is="activeRouter" /> -->
      <Home />
    </div>
    <Warm ref="warm" />
  </div>
</template>

<style lang="less" scoped>
.home-page {
  width: 100vw;
  height: 100vh;
  background-color: #0e0f16;
  position: relative;

  .home-page-back {
    color: #b9b8b8;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    .el-icon-position {
      color: #b9b8b8;
    }
    &:hover {
      background-color: #eee;
      .el-icon-position {
        color: var(--color-black);
      }
    }
  }
  .home-page_header {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .home-page_nav {
      text-align: center;
      height: 100%;
      ul {
        list-style-type: none; /* 去掉默认的列表样式 */
        padding: 0; /* 去掉内边距 */
        margin: 0; /* 去掉外边距 */
        display: inline-block; /* 使ul变成内联块元素 */
        height: 100%;
        > li {
          line-height: 50px;
          color: #9b9ca6;
          display: inline-block;
          padding: 0 30px;
        }
        > li.active {
          color: #fff;
          > span {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              display: block;
              bottom: -8px;
              left: -10px;
              width: calc(100% + 20px);
              border-bottom: 2px solid #4d6ee7;
            }
          }
        }
      }
    }
    .navbar-custom-menu {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;

      .dropdown-toggle {
        color: #fff;
        display: flex;
        align-items: center;
        img {
          display: block;
        }
      }
      .dropdown-toggle:hover,
      .dropdown-toggle :focus {
        color: #0e0f16;
      }
      .nav .open > li:hover,
      .nav .open > a:focus {
        color: #0e0f16;
      }
      .dropdown.open {
        a {
          color: #0e0f16;
        }
      }
      .navbar-home {
        float: left;
        position: relative;
        display: block;
        padding: 0 15px;

        i {
          color: #333;
          line-height: 50px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .home-page_main {
    position: relative;
    z-index: 2;
    height: calc(100vh - 50px);
    /deep/ .home {
      @gap: 10px;
      box-sizing: border-box;
      padding: @gap;
      grid-gap: @gap;

      > div {
        // margin: 10px;
        background-color: rgba(28, 31, 50, 80%);
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
      }

      .home-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        > div:first-child {
          font-size: 14px;
          color: #fff;
          font-weight: bold;
        }
        > div:last-child {
          font-size: 14px;
          color: #636ab0;
        }
        div.red {
          color: var(--color-red);
          font-size: 20px;
        }
      }
      .home-detail-main {
        font-size: 12px;
        height: calc(100% - 30px);
        color: #9b9ca6;
        .home-statistic {
          .head {
            color: var(--color-grey);
            .title {
              font-size: 12px;
            }
          }
          .con {
            margin-top: 10px;
            color: var(--color-white);
            .number {
              font-size: 24px;
            }
            .minute {
              font-size: 12px;
              color: var(--color-grey);
            }
          }
        }
      }
      .home-detail-main:has(.home-statistic) {
        display: flex;
        align-items: center;
        > .el-row {
          width: 100%;
        }
      }
    }
  }
}
</style>
