<script>
/**
 * @author lixidong
 * @description 播放配置
 */
import * as configurationApi from '@/api/gosip_server/configuration_api'
import { transformJson, trimmedForm } from '@/utils/tool.js'
export default {
  data() {
    return {
      form: {
        default_play_protocol: 'http-flv',
        default_plays_protocol: 'http-flv',
        open_protocol: 'http-flv',
        survival_interval: 1,
        webrtc_server_url: '',
        webrtc_user: '',
        webrtc_pwd: '',
      },
      rules: {
        // webrtc_user:[{validator:this.webrtcLogin}],
        // webrtc_pwd:[{validator:this.webrtcLogin}]
      },
      submitLock: false,
      protocols: [
        { label: 'WS-FLV', value: 'ws-flv' },
        { label: 'HTTP-FLV', value: 'http-flv' },
        { label: 'HLS', value: 'hls' },
        { label: 'WebRTC', value: 'webrtc' },
      ],
    }
  },
  created() {
    // this.getConfiguration();
  },
  methods: {
    // 获取播放配置数据
    async getConfiguration() {
      const res = await configurationApi.play_configuration()
      if (res.code === 200) {
        var data = transformJson(res.data.Data, { ID: () => 'id' })
        if (Object.keys(data).length === 0) return false
        this.form = data
      }
    },
    // 提交表单数据
    submit() {
      this.$refs['form'].validate(async (vaild) => {
        if (vaild) {
          try {
            this.submitLock = true
            const res = await configurationApi.update_play_configuration(
              trimmedForm(this.form)
            )
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.getConfiguration()
            }
          } finally {
            this.submitLock = false
          }
        }
      })
    },
    // webrtc 路径存在 user pwd
  },
}
</script>
<template>
  <div class="play-configuration">
    <auth-wrapper slot auth="update-play-configuration">
      <template v-slot="{ root }">
        <el-form
          :disabled="!root"
          ref="form"
          :rules="rules"
          :model="form"
          size="mini"
          label-width="150px"
        >
          <div class="play-configuration_tags">播放协议</div>
          <el-form-item label="默认播放协议" prop="default_play_protocol">
            <el-radio-group v-model="form.default_play_protocol">
              <el-radio
                v-for="item in protocols"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="多分屏默认播放协议"
            prop="default_plays_protocol"
          >
            <el-radio-group v-model="form.default_plays_protocol">
              <el-radio
                v-for="item in protocols"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开放协议" prop="open_protocol">
            <el-radio-group v-model="form.open_protocol">
              <el-radio
                v-for="item in protocols"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <div class="play-configuration_tags">播放鉴权</div>
          <el-form-item label="播放保活间隔（秒）" prop="survival_interval">
            <el-input-number
              v-model="form.survival_interval"
              controls-position="right"
              :min="1"
              :max="10"
            ></el-input-number>
          </el-form-item>
          <!-- <div class="play-configuration_tags">WebRTC配置</div>
                    <el-form-item label="服务器地址" prop="webrtc_server_url">
                        <el-input v-model="form.webrtc_server_url" placeholder="请输入webrtc服务器地址"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="webrtc_user">
                        <el-input v-model="form.webrtc_user" placeholder="请输入webrtc服务器账号"></el-input>
                    </el-form-item>
                    <el-form-item label="服务密码" prop="webrtc_pwd">
                        <el-input v-model="form.webrtc" placeholder="请输入webrtc服务器密码"></el-input>
                    </el-form-item> -->
          <auth-wrapper auth="update-play-configuration">
            <el-form-item>
              <!-- <el-button :disabled="submitLock" :loading="submitLock" @click="submit"
                                type="primary">保存设置</el-button> -->
            </el-form-item>
          </auth-wrapper>
        </el-form>
      </template>
    </auth-wrapper>
  </div>
</template>

<style lang="less" scoped>
.play-configuration {
  .play-configuration_tags {
    width: 100%;
    height: 48px;
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    padding-left: 20px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 18px;
  }
}
</style>
