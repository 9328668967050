<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import * as cameraApi from '@/api/camera_api/camera_api'
export default {
  data() {
    return {
      value: [],
    }
  },
  created() {
    this.rd()
  },
  methods: {
    async rd() {
      let r = await cameraApi.selectBoxs({
        PageInfo: {
          PageIdx: 0,
          PageSize: 1000,
        },
      })
      if (r.code != 200) return

      let v = r.data.AIBoxes || []
      v = v.map((item) => {
        return {
          name: item.Name,
          status: 'success',
        }
      })
      this.value = [{ name: '主服务器', status: 'success' }].concat(v)
    },
  },
}
</script>

<template>
  <div class="home-serve home-item">
    <div class="home-detail-header">
      <div>服务器信息</div>
      <div>详情</div>
    </div>
    <div class="home-detail-main scrollbar-no-y">
      <el-row v-for="(item, index) in value" :key="index">
        <el-col class="ellipsis" :span="12">{{ item.name }}</el-col>
        <el-col class="home-detail-main_status ellipsis" :span="12">
          <span
            :style="{
              color:
                item.status == 'success'
                  ? 'var(--color-broccoli)'
                  : item.status == 'warming'
                  ? 'var(--color-yellow)'
                  : 'var(--color-red)',
            }"
            >{{
              item.status == 'success'
                ? '运行'
                : item.status == 'warming'
                ? '告警'
                : '停止'
            }}</span
          >
          <span
            :style="{
              backgroundColor:
                item.status == 'success'
                  ? 'var(--color-broccoli)'
                  : item.status == 'warming'
                  ? 'var(--color-yellow)'
                  : 'var(--color-red)',
            }"
            class="home-serve-circle"
          ></span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home-detail-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /deep/.el-col {
    line-height: 24px;
    height: 24px;
  }
  .home-detail-main_status {
    display: flex;
    align-items: center;

    .home-serve-circle {
      margin-left: 15px;
      @wh: 16px;
      flex-shrink: 0;
      flex-basis: auto;
      width: @wh;
      height: @wh;
      border-radius: 50%;
      display: inline-block;
      box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.5);
    }
  }
}
@media screen and (max-height: 650px) {
  .home-detail-main {
    display: block !important;
  }
}
@media screen and (min-height: 650px) {
  .home-detail-main {
    .inner {
      padding-right: 0 !important;
    }
  }
}
</style>
