<script>
/**
 * @author lixidong
 * @description 修改密码弹窗
 */
import { md5 } from '@/utils/tool.js'
import * as userApi from '@/api/easy_darwin_server/user_api.js'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        OldPwd: '',
        NewPwd: '',
        NewPwd2: '',
      },
      dialogBtnLock: false,
      dialogRules: {
        OldPwd: [
          { required: true, message: '旧密码不能为空！' },
          {
            pattern: /^[?=.*A-Za-z\d]{6,}$/,
            message: '至少 6 位，使用字母、数字或?=.*',
          },
        ],
        NewPwd: [
          { required: true, message: '新密码不能为空！' },
          {
            pattern: /^[?=.*A-Za-z\d]{6,}$/,
            message: '至少 6 位，使用字母、数字或?=.*',
          },
          {
            validator: this.validateOnlyPwd,
          },
        ],
        NewPwd2: [
          { required: true, message: '请再次输入密码！' },
          {
            pattern: /^[?=.*A-Za-z\d]{6,}$/,
            message: '至少 6 位，使用字母、数字或?=.*',
          },
          {
            validator: this.validateConfirmPwd,
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 验证新旧密码不一致
    validateOnlyPwd(rule, value, callback) {
      if (value == this.dialogForm.OldPwd) {
        callback(new Error('新密码与旧密码一致！'))
      } else {
        callback()
      }
    },
    // 验证新密码一致
    validateConfirmPwd(rule, value, callback) {
      if (value !== this.dialogForm.NewPwd) {
        callback(new Error('两次输入密码并不一致！'))
      } else {
        callback()
      }
    },
    // 修改密码逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            // pwd md5
            const dialogForm = Object.assign({}, this.dialogForm)
            Object.keys(dialogForm).forEach(
              (key) => (dialogForm[key] = md5(dialogForm[key]))
            )
            const { OldPwd, NewPwd, NewPwd2 } = dialogForm
            const userID = window.localStorage.getItem('USERID')
            if (!userID) {
              this.$message.error('用户信息异常，请重新登录！')
              return
            }
            await userApi.changepwd(userID, {
              OldPassword: OldPwd,
              NewPassword: NewPwd,
              ConfirmNewPassword: NewPwd2,
            })
            // 成功后重新登陆
            window.localStorage.clear()
            this.$router.push({ path: '/logout' })
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //显示表单按钮逻辑
    dialogFormShow() {
      this.dialogTitle = '修改密码'
      this.dialogFormVisible = true
    },
  },
}
</script>

<template>
  <!-- 修改密码 -->
  <el-dialog
    :close-on-click-modal="false"
    top="100px"
    :title="dialogTitle"
    :visible.sync="dialogFormVisible"
  >
    <el-form
      size="mini"
      ref="dialogForm"
      :model="dialogForm"
      :rules="dialogRules"
      label-width="100px"
    >
      <el-row :gutter="20">
        <el-form-item label="用户名">
          <el-input :value="userInfo.UserName" disabled> </el-input>
        </el-form-item>
        <el-form-item label="旧密码" prop="OldPwd">
          <el-input
            v-model="dialogForm.OldPwd"
            type="password"
            placeholder="请输入旧密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="NewPwd">
          <el-input
            v-model="dialogForm.NewPwd"
            type="password"
            placeholder="请输入新密码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="再次输入新密码" prop="NewPwd2">
          <el-input
            v-model="dialogForm.NewPwd2"
            type="password"
            placeholder="请输入新密码"
          >
          </el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
      <el-button
        size="mini"
        type="primary"
        :loading="dialogBtnLock"
        @click="dialogFormSubmit('dialogForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
