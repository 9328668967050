<script>
/**
 * @auhtor lixidong
 * @description 平均响应时间
 */
import ListenScroll from '@/mixins/listen_scroll'
import moment from 'moment'
export default {
  mixins: [ListenScroll],
  data() {
    return {
      value: [
        {
          name: '刘一凡',
          total: 8,
          ae: 3,
          data: [
            {
              warnType: '设备故障',
              locat: '2号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
            {
              warnType: '设备故障',
              locat: '2号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
            {
              warnType: '车辆违停',
              locat: '1号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
          ],
        },
        {
          name: '张永琪',
          total: 8,
          ae: 3,
          data: [
            {
              warnType: '设备故障',
              locat: '2号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
            {
              warnType: '车辆违停',
              locat: '1号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
            {
              warnType: '车辆违停',
              locat: '1号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
          ],
        },
        {
          name: '李飞侠',
          total: 8,
          ae: 2,
          data: [
            {
              warnType: '车辆违停',
              locat: '1号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
            {
              warnType: '车辆违停',
              locat: '1号厂房',
              time: moment(Date.now()).format('HH:mm:ss'),
              status: '已处理',
            },
          ],
        },
      ],
    }
  },
}
</script>

<template>
  <div v-resize="handleScroll" class="home-handlingSituation home-item">
    <div class="home-detail-header">
      <img src="@/assets/images/warm/header-common.png" alt="" />
      <div>
        <div class="home-detail-header-title">处理状况</div>
        <div class="home-detail-header-subtitle">Handing situation</div>
      </div>
      <div style="font-size: 14px; color: #0daa65">10/50</div>
    </div>
    <div ref="scrollbar" class="home-detail-main">
      <div ref="inner" class="inner">
        <div v-for="(item, index) in value" :key="index" class="home-hs-table">
          <div class="home-hs-table-header">
            <div style="font-size: 14px; color: var(--color-white)">
              {{ item.name }}
            </div>
            <!-- <div style="color: var(--color-red); font-size: 12px">
              {{ item.ae + '/' + item.total }}
            </div> -->
          </div>
          <el-row type="flex" align="middle" class="home-table-header">
            <el-col class="ellipsis" :span="6">告警类型</el-col>
            <el-col class="ellipsis" :span="6">地点</el-col>
            <el-col class="ellipsis" :span="6">时间</el-col>
            <el-col class="ellipsis" :span="6">处理状态</el-col>
          </el-row>
          <el-row
            v-for="(itemData, indexData) in item.data"
            :key="indexData"
            type="flex"
            align="middle"
            class="home-table-body"
          >
            <el-col class="home-td" :span="6">{{ itemData.warnType }}</el-col>
            <el-col class="home-td" :span="6">{{ itemData.locat }}</el-col>
            <el-col class="home-td" :span="6">{{ itemData.time }}</el-col>
            <el-col class="home-td r-border" :span="6"
              ><el-tag
                size="small"
                effect="dark"
                style="border-radius: 30px"
                :color="itemData.status == '已处理' ? '#0daa65' : '#ffb648'"
                >{{ itemData.status }}</el-tag
              ></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home-handlingSituation {
  .home-detail-main {
    .home-hs-table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
    .home-hs-table {
      margin-bottom: 16px;
      .home-td {
        white-space: normal;
        flex-shrink: 0;
        flex-grow: 0;
      }
      .home-table-header,
      .home-table-body {
        /deep/ .el-col {
          text-align: center;
        }
      }
      .home-table-header {
        /deep/ .el-col {
          background-color: rgba(13, 170, 101, 0.6);
          color: #fff;
          padding: 4px 8px;
          box-sizing: border-box;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background: rgba(28, 31, 50, 0.8);
            position: absolute;
            right: 0;
            top: 0;
          }
          > .el-col:last-child::after {
            display: none;
          }
        }
      }
      .home-table-body {
        margin-top: 5px;
      }
    }
    .home-hs-table:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
