<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import ListTe from '@/components/Home/list.vue'
import CarTe from '@/components/Home/car.vue'
import DayTe from '@/components/Home/day.vue'
import PopuleTe from '@/components/Home/popule.vue'
import WarmTe from '@/components/Home/warm.vue'
import ServeTe from '@/components/Home/serve.vue'
import Player from '@/components/Home/player.vue'
export default {
  components: { ListTe, CarTe, DayTe, PopuleTe, WarmTe, ServeTe, Player },
}
</script>

<template>
  <div class="home">
    <div class="home-main">
      <div v-for="(item, index) in 9" :key="index">
        <Player :uuid="index" />
      </div>
    </div>
    <ListTe />
    <ServeTe />
    <WarmTe />
    <DayTe />
    <PopuleTe />
    <CarTe />
  </div>
</template>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  display: grid; /* 确保这是网格容器 */
  grid-template-areas:
    'main main main main list'
    'main main main main list'
    'main main main main list'
    'serve warm day popule car';
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;
  grid-auto-rows: 1fr; /* 确保每行高度相同 */
  grid-auto-columns: 1fr; /* 确保每列宽度相同 */
  .home-main {
    grid-area: main;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 5px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    // &::after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 50vw;
    //   height: 50vw;
    //   z-index: 1;
    //   background: radial-gradient(
    //     circle,
    //     rgba(99, 106, 176, 1) 0%,
    //     rgba(14, 15, 22, 0) 70%
    //   );
    //   border-radius: 50%;
    //   pointer-events: none; /* 确保伪元素不干扰页面交互 */
    // }
  }
  .home-list {
    grid-area: list;
  }
  .home-serve {
    grid-area: serve;
  }
  .home-warm {
    grid-area: warm;
  }
  .home-day {
    grid-area: day;
  }
  .home-popule {
    grid-area: popule;
  }
  .home-car {
    grid-area: car;
  }
}
</style>
