<script>
/**
 * @auhtor lixidong
 * @description 重点时间
 */
import tnoa01 from '@/assets/images/home/tnoa01.png'
import tnoa02 from '@/assets/images/home/tnoa02.png'
import tnoa03 from '@/assets/images/home/tnoa03.png'
import tnoa04 from '@/assets/images/home/tnoa04.png'
export default {
  data() {
    return {
      value: [
        { type: '消防', value: '07:00', icon: tnoa01 },
        { type: '财产', value: '07:00', icon: tnoa02 },
        { type: '设备', value: '02:24', icon: tnoa03 },
        { type: '车辆', value: '09:30', icon: tnoa04 },
      ],
    }
  },
}
</script>

<template>
  <div class="home-keyTime home-item">
    <div class="home-detail-header">
      <img src="@/assets/images/warm/header-common.png" alt="" />
      <div>
        <div class="home-detail-header-title">重点时间</div>
        <div class="home-detail-header-subtitle">Key Time</div>
      </div>
      <div></div>
    </div>
    <div style="display: block" class="home-detail-main scrollbar-no-y">
      <el-row
        type="flex"
        style="flex-wrap: wrap; justify-content: space-between; height: 100%"
      >
        <el-col
          v-for="(item, index) in value"
          :key="index"
          style="
            min-width: 88px;
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
        >
          <img
            style="width: 35px; height: 35px; margin-bottom: 5px"
            :src="item.icon"
            :alt="item.value"
          />
          <el-statistic time-indices class="home-statistic" :title="item.type">
            <template slot="formatter" scope>
              <span style="color: #4480f8"> {{ item.value }}</span>
            </template>
          </el-statistic>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
