<script>
/***
 * @author lixidong
 * @description  没有侧边栏
 */
import commonMixin from './utils/common.js'
export default {
  mixins: [commonMixin],
}
</script>
<template>
  <div v-resize="resizeScroll" class="wrapper">
    <ChangePwd ref="change-pwd" />
    <NaviBar
      @dialogFormShow="() => $refs['change-pwd'].dialogFormShow()"
      :logoText="logoText"
      :logoMiniText="logoMiniText"
      :versionText="versionText"
    ></NaviBar>
    <div style="margin-left: 0" id="adlte-main" class="content-section">
      <section style="height: 100%" class="content">
        <!-- 面包屑 -->
        <BreadCrumb />
        <!-- 内容区 -->
        <div style="height: calc(100% - 30px)" class="content-section_page">
          <slot />
        </div>
      </section>
    </div>
    <footer
      style="margin-left: 0px !important"
      class="run-ani_footer main-footer"
    >
      <div class="pull-right hidden-xs hide">EasyDarwin</div>
      <strong
        >Copyright &copy; {{ Year() }}
        <a href="http://www.aihaohong.com">www.aihaohong.com</a>.</strong
      >
      All rights reserved.
    </footer>
  </div>
</template>

<style lang="less" scoped>
.content-section,
.right-side,
.main-footer {
  transition: margin 0.3s linear;
}

//
.content-section,
.main-footer {
  margin-left: 100px;
}

.content-section.no-sider,
.main-footer.no-sider {
  margin-left: 0px;
}
</style>
<style scoped lang="less">
#adlte-main {
  box-sizing: border-box;
}
.content-section_page {
  > div {
    width: 100%;
    height: 100%;

    /deep/.el-card {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .el-card__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        > .el-tabs {
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="less">
.vue-back-to-top {
  background-color: transparent;
  left: 30px;
  bottom: 10px;
}

.sidebar-collapse .vue-back-to-top {
  display: none;
}
</style>
