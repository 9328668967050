<script>
/***
 * @author lixidong
 * @description 监控中心
 */

import { mapState, mapActions } from 'vuex'

import ThreeSider from './treeSider.vue'

import AdminNoLTE from '@/components/AdminLTE/AdminNoLTE.vue'

import NSider from '@/components/NSider.vue'

import LiveBroadcasts from './liveBroadcasts.vue'
import LiveBroadcast from './LiveBroadcast/index.vue'
import PlayBack from './playBack.vue'

export default {
  name: 'monitorin',
  components: {
    ThreeSider,
    AdminNoLTE,
    NSider,
    // 页面
    LiveBroadcast,
    LiveBroadcasts,
    PlayBack,
  },
  data() {
    return {
      list: [
        {
          title: '直播观看',
          componentName: 'LiveBroadcast',
        },
        {
          title: '多屏播放',
          componentName: 'LiveBroadcasts',
        },
        {
          title: '回放',
          componentName: 'PlayBack',
        },
      ],
      listActive: 0,
      // componentName: null,/
    }
  },
  computed: {
    ...mapState(['logoText', 'logoMiniText', 'serverInfo']),
    versionText() {
      let text = ''
      if (this.serverInfo) {
        text = this.serverInfo.Version || ''
      }
      return text.substring(text.indexOf(' ') + 1)
    },
    componentName() {
      return this.list[this.listActive].componentName
    },
  },

  methods: {
    ...mapActions(['getServerInfo']),
    ...mapActions('menu', ['updateRoute']),
    // 选择通道
    handleChangeClick(data) {
      this.$refs.livebroadcasts.selectChannel &&
        this.$refs.livebroadcasts.selectChannel(data)
    },
    fixHover() {
      if (videojs.browser.IS_IOS || videojs.browser.IS_ANDROID) {
        for (
          var sheetI = document.styleSheets.length - 1;
          sheetI >= 0;
          sheetI--
        ) {
          var sheet = document.styleSheets[sheetI]
          if (sheet.cssRules) {
            for (var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
              var rule = sheet.cssRules[ruleI]
              if (rule.selectorText) {
                rule.selectorText = rule.selectorText.replace(
                  ':hover',
                  ':active'
                )
                rule.selectorText = rule.selectorText.replace(
                  ':focus',
                  ':active'
                )
              }
            }
          }
        }
      }
    },
    clickHanlde(index) {
      this.listActive = index
    },
  },
  watch: {
    // 更新路由信息
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.updateRoute(val)
      },
    },
  },
}
</script>

<template>
  <AdminNoLTE :mode="false" siderSlot>
    <NSider
      slot="sider"
      mode
      :list="list"
      :listActive="listActive"
      @clickHanlde="clickHanlde"
    >
      <three-sider @handleChangeClick="handleChangeClick" />
    </NSider>
    <keep-alive :include="list.map((item) => item.componentName)">
      <component ref="livebroadcasts" :is="componentName" :key="componentName">
      </component>
    </keep-alive>
  </AdminNoLTE>
</template>
