/**
 * @author lixidong
 * @description 盒子详情信息
 */
import * as cameraApi from '@/api/camera_api/camera_api'

export default {
  data() {
    return {
      nowPipelines: {
        total: 0,
        run: 0,
        stop: 0,
      },
      timerPipeline: null,
    }
  },
  methods: {
    async channelInfo(data) {
      try {
        // return false
        const piplines = await this.getPipelines(data)
        piplines.length > 0 && this.timerGetPipeline(data.Addr, piplines)
        if (piplines.length == 0)
          this.nowPipelines = this.$options.data().nowPipelines
      } catch (err) {
        this.nowPipelines = this.$options.data().nowPipelines
      }
    },
    // 定时器
    timerGetPipeline(ip, piplines) {
      if (this.timerPipeline) clearInterval(this.timerPipeline)
      const main = async () => {
        piplines = piplines.map((camera) => {
          return this.getPipelineStatus(ip, camera.PipelineInstanceId)
        })
        piplines = await Promise.all(piplines)
        //  过滤条件
        const run = piplines.filter((status) => status === 'RUNNING').length
        this.nowPipelines = {
          total: piplines.length,
          run: run,
          stop: piplines.length - run,
        }
      }

      this.timerPipeline = setInterval(() => main(), 10 * 60 * 1000)
      main()
    },
    // 获取通道
    async getPipelines(data) {
      try {
        // 盒子已经设置的通道
        let res = await cameraApi.selectBox(data)
        if (res.code == 200) {
          const Pipelines = res.data.Pipelines || []
          return Pipelines
        }
        return []
      } catch (err) {
        return []
      }
    },
    // 获取状态
    async getPipelineStatus(ip, PipelineInstanceId) {
      try {
        let res = await cameraApi.getPipelineStatus(ip, PipelineInstanceId)
        if (res.code == 200) {
          return res.data.state
        }
        return ''
      } catch (err) {
        return ''
      }
    },
  },
  beforeDestroy() {
    if (this.timerPipeline) clearInterval(this.timerPipeline)
  },
}
