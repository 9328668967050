<script>
/**
 * @author lixidong
 * @description 分享
 */
import { Copy } from '@icon-park/vue'
import QRCode from 'qrcode'
export default {
  props: {
    playerurl: String,
  },
  components: { Copy },
  computed: {
    streamurl() {
      const playerurl = this.playerurl
      if (!playerurl) return ''
      const { host, protocol } = window.location
      const publicPath = process.env.VUE_APP_PUBLICPATH
      const url = `${protocol}//${host}${publicPath}dirct.html?stream=${playerurl}`
      return url
    },
  },
  methods: {
    // 生成二维码
    generateQRCode(text) {
      this.$nextTick(() => {
        QRCode.toCanvas(
          this.$refs.qrCanvas,
          text,
          {
            width: 100,
            height: 100,
          },
          (error) => {
            if (error) console.error(error)
          }
        )
      })
    },
    copy(value) {
      this.$copyText(value)
    },
  },
  watch: {
    streamurl: {
      handler(val) {
        val && this.generateQRCode(val)
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <el-form label-width="80px" size="mini">
    <!-- <el-form-item label="分享模式">
            <el-checkbox>精简模式</el-checkbox>
        </el-form-item> -->
    <el-form-item label="分享链接">
      <el-input v-model="streamurl" class="live-broadcast_code-url" disabled>
        <template slot="suffix">
          <button
            v-if="streamurl"
            class="clipboard-button"
            @click="copy(streamurl)"
          >
            <copy
              class="live-broadcast_copy-icon"
              theme="outline"
              size="18"
              fill="#666"
              strokeLinecap="butt"
            />
          </button>
        </template>
      </el-input>
    </el-form-item>
    <!-- <el-form-item label="启动CDN">
            <el-input disabled></el-input>
        </el-form-item> -->
    <!-- <el-form-item label="iframe">
            <el-input class="live-broadcast_code-url" disabled>
                <template slot="suffix">
                    <button v-if="streamurl" v-clipboard="streamurl" @success="$message.success('复制成功')"
                        class="clipboard-button">
                        <copy class="live-broadcast_copy-icon" theme="outline" size="18" fill="#666"
                            strokeLinecap="butt" />
                    </button>
                </template>
            </el-input>
        </el-form-item> -->
    <el-form-item v-if="playerurl" label="扫码直播">
      <canvas ref="qrCanvas"></canvas>
    </el-form-item>
  </el-form>
</template>
