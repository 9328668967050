import * as wxApi from '@/api/easy_darwin_server/wx_api.js'
import { telRules } from '@/utils/rules.js'
export default {
  data() {
    return {
      wxform: {
        wxRoleName: '',
        wxRoleId: '',
        wxTel: '',
      },
      wxrules: {
        wxTel: telRules,
        wxRoleName: [{ required: true, message: '角色名称不能为空！' }],
      },
      wxdialogFormVisible: false,
      wxcode: null,
      wxloginCode: null,
    }
  },
  mounted() {
    this.getWxCode()
  },
  methods: {
    // 打开小程序二维码弹窗
    openWxCode() {
      this.wxform = this.$options.data().wxform
      this.$refs['wxRegister'] && this.$refs['wxRegister'].resetFields()
      this.wxcode = null
      this.wxdialogFormVisible = true
    },
    wxdialogFormCancel() {
      this.wxdialogFormVisible = false
    },
    // 获取小程序码
    async getWxCode() {
      const r = await wxApi.wx_login({
        page: 'pages/index/index',
      })
      if (r.code === 200) {
        this.wxloginCode = r.data
      }
    },
    // 获取注册小程序码
    getWxRegisterCode() {
      this.$refs['wxRegister'].validate(async (valid) => {
        if (valid) {
          const scene = encodeURIComponent(
            `RoleID=${this.wxform.wxRoleId}&Tel=${this.wxform.wxTel}`
          )
          const res = await wxApi.wx_register({
            page: 'userPages/register/index',
            scene,
          })
          if (res.code === 200) {
            this.wxcode = res.data
          }
        }
      })
    },
    // 下载wxcode
    downloadWxCode(url) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'wxcode.png'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(downloadUrl)
      document.body.removeChild(a)
    },
  },
  watch: {
    // wxform: {
    //   handler: function (val, oldVal) {
    //     if (val.wxRoleId && val.wxTel) {
    //       this.getWxRegisterCode()
    //     }
    //   },
    //   deep: true,
    // },
  },
}
