<script>
/**
 * @auhtor lixidong
 * @description 防盗栏 懒加载 大图预览等
 */
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'left',
    },
    popover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imageSrc: null,
      loadError: false,
    }
  },
  mounted() {
    if (this.lazy) {
      this.createObserver()
    } else {
      this.handleImage(this.src)
    }
  },
  methods: {
    // 图片加载防盗链
    async handleImage(url) {
      try {
        // const imageSrc = await this.fetchImage(url)
        // const imageSrc = await this.imagefn(url)
        const imageSrc = await this.imgfn(url)
        this.imageSrc = imageSrc
      } catch (err) {
        this.handleError()
      }
    },
    imgfn(url) {
      const limit = 2
      let count = 0
      return new Promise((resolve, reject) => {
        const loadImage = () => {
          const imgDom = document.createElement('img')
          imgDom.src = url
          imgDom.style.display = 'none'
          imgDom.onload = () => {
            // const canvas = document.createElement('canvas')
            // imgDom.crossOrigin = 'Anonymous';
            // canvas.width = imgDom.width
            // canvas.height = imgDom.height
            // const ctx = canvas.getContext('2d')
            // ctx.drawImage(imgDom, 0, 0)
            // canvas.toBlob((blob) => {
            //   document.body.removeChild(imgDom)
            //   resolve(URL.createObjectURL(blob))
            // }, 'image/png')
            document.body.removeChild(imgDom)
            resolve(url)
          }
          imgDom.onerror = () => {
            document.body.removeChild(imgDom)
            if (count < limit) {
              count++
              loadImage()
            } else {
              reject(new Error('图片加载失败'))
            }
          }
          document.body.appendChild(imgDom)
        }
        loadImage()
      })
    },
    //  fetch方法
    fetchImage(url) {
      const limit = 2
      let count = 0
      const main = async (url) => {
        try {
          const r = await f(url)
          return URL.createObjectURL(r)
        } catch (error) {
          if (count < limit) {
            count++
            return main(url)
          } else {
            throw error
          }
        }
      }
      // fetch 请求图片数据
      const f = (url) => {
        return fetch(url).then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.blob()
        })
      }
      return main(url)
    },
    // image方法
    imagefn(url) {
      const limit = 2
      let count = 0
      return new Promise((resolve, reject) => {
        const loadImage = () => {
          const image = new Image()
          image.crossOrigin = 'Anonymous' // 允许跨域加载图片
          image.src = url
          image.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(image, 0, 0)
            canvas.toBlob((blob) => {
              resolve(URL.createObjectURL(blob))
            }, 'image/png')
          }
          image.onerror = () => {
            if (count < limit) {
              count++
              loadImage()
            } else {
              reject(new Error('图片加载失败'))
            }
          }
        }
        loadImage()
      })
    },
    // 图片加载失败
    handleError() {
      this.loadError = true
    },

    createObserver() {
      const options = {
        root: null,
        threshold: 0.1,
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isIntersecting = true
            this.handleImage(this.src)
            observer.unobserve(this.$refs.image)
          }
        })
      }, options)

      observer.observe(this.$refs.image)
    },
  },
}
</script>

<template>
  <div ref="image" class="img-bolb">
    <el-popover
      v-if="imageSrc && popover"
      :placement="placement"
      trigger="hover"
    >
      <slot :src="imageSrc" />
      <img
        class="img-bolb_image"
        :src="imageSrc"
        slot="reference"
        @error="handleError"
        alt="图片"
      />
    </el-popover>
    <img
      v-if="imageSrc && !popover"
      class="img-bolb_image"
      :src="imageSrc"
      slot="reference"
      @error="handleError"
      alt="图片"
    />
    <div v-if="loadError" class="img-bolb_fail">加载失败</div>
  </div>
</template>

<style lang="scss" scoped>
.img-bolb {
  width: 100%;
  height: 100%;
  color: #c0c4cc;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-bolb_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
