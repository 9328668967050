<script>
/**
 * @author lixidong
 * @description 顶部导航栏 修改
 */
import { mapGetters, mapState } from 'vuex'
import logoPng from '@/assets/images/logo.png'
export default {
  props: {
    logoText: {
      default: 'AdminLte',
    },
    logoMiniText: {
      default: 'AD',
    },
    versionText: {
      default: '',
    },
  },
  data() {
    return {
      logoPng,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('menu', ['active']),
    ...mapGetters('menu', ['menuNavigate']),
    activeMenu() {
      if (this.active && this.active.length > 0) {
        return this.active[0]
      } else {
        return {}
      }
    },
  },
  methods: {
    // 退出功能···
    logoutHandler() {
      this.$router.push({ path: '/logout' })
    },

    // 顶部导航点击事件
    navHandler(item) {
      item.EName &&
        item.EName != this.activeMenu.name &&
        this.$router.push({ name: item.EName })
    },
    // 动态导入图片
    img_src(name) {
      const active = name == this.activeMenu.name
      return require(`@/assets/images/nav/${
        active ? name + '_active' : name
      }.png`)
    },
  },
}
</script>

<template>
  <header class="main-header">
    <router-link class="logo" style="position: relative" to="/">
      <div
        class="logo-img"
        :style="{ backgroundImage: 'url(' + logoPng + ')' }"
      ></div>
    </router-link>

    <nav class="navbar navbar-static-top">
      <div class="navbar-trans">
        <ul class="trans-ul">
          <li
            v-for="(item, index) in menuNavigate"
            :key="index"
            class="trans-li"
            @click="navHandler(item)"
            :class="{ 'trans-active': item.EName == activeMenu.name }"
          >
            <img
              class="trans-img"
              :src="img_src(item.EName)"
              :alt="item.title"
            />
            <a>{{ item.CName }}</a>
          </li>
        </ul>
      </div>
      <div class="navbar-custom-menu">
        <auth-wrapper auth="home">
          <a
            @click.prevent="() => $router.push({ name: 'home' })"
            class="navbar-home"
            role="button"
          >
            <i class="fa fa-home"></i>
          </a>
          <!-- <a
            @click.prevent="() => $router.push({ name: 'home_two' })"
            class="navbar-home"
            role="button"
          >
            <i class="fa fa-home"></i>
          </a> -->
        </auth-wrapper>
        <ul style="float: right" class="nav navbar-nav">
          <li class="dropdown" v-if="userInfo">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown"
              >欢迎, {{ userInfo.NickName || userInfo.UserName }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <a @click.prevent="$emit('dialogFormShow')" role="button"
                  ><i class="fa fa-key"></i> 修改密码</a
                >
              </li>
              <!-- <router-link to="/logout" tag="li">
                <a><i class="fa fa-sign-out"></i> 退出登陆</a>
              </router-link> -->
              <li>
                <a @click.prevent="logoutHandler" role="button"
                  ><i class="fa fa-sign-out"></i> 退出登陆</a
                >
              </li>
            </ul>
          </li>
          <li v-else>
            <a href="/login.html"><i class="fa fa-user"></i> 登录</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<style lang="less" scoped>
.main-header .navbar,
.main-header .logo {
  /* Fix for IE */
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.main-header .logo {
  // padding: 0;
  font-weight: 600;

  .logo-img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.main-header .navbar .dropdown-menu li a {
  color: #777;

  &:hover {
    background-color: #e1e3e9;
  }
}

// 定义
.main-header {
  .navbar {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar-trans {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;

      .trans-ul {
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        padding: 0;
        margin: 0;

        .trans-li {
          padding: 15px 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            color: #9b9ca6;
          }

          .trans-img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
        }
      }
    }

    .navbar-custom-menu {
      flex: 0 0 auto;
      float: none;

      .navbar-home {
        float: left;
        position: relative;
        display: block;
        padding: 0 15px;

        i {
          color: #333;
          line-height: 50px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}
</style>
