/**
 * @author lixidong
 * @description 照相机配置api
 */
import { put, get, del, post, upload, download } from '../index'

export const makePtzUrl = (cameraName, profileToken, actionPath) => {
  return post(
    `/camera_api/api/v3/cameras/${cameraName}/profiles/${profileToken}/ptz${actionPath}`
  )
}

export const gotoPreset = (cameraName, profileToken, presetToken) => {
  return post(
    `/camera_api/api/v3/cameras/${cameraName}/profiles/${profileToken}/presets/${presetToken}`
  )
}

export const makeCameraUrl = (cameraName, path) => {
  return get(`/camera_api/api/v3/cameras/${cameraName}${path}`)
}

export const makePipelineUrl = (cameraName, actionPath, doNotLog, data) => {
  if (doNotLog) {
    return get(
      `/camera_api/api/v3/cameras/${cameraName}/pipeline${actionPath}`,
      {},
      {
        headers: {
          'X-CameraApp-Ignore': true,
        },
      }
    )
  } else {
    return get(
      `/camera_api/api/v3/cameras/${cameraName}/pipeline${actionPath}`,
      data,
      {
        contentType: 'application/json; charset=utf-8',
      }
    )
  }
}

export const makePipelineUrlPost = (cameraName, actionPath, doNotLog, data) => {
  if (doNotLog) {
    return post(
      `/camera_api/api/v3/cameras/${cameraName}/pipeline${actionPath}`,
      {},
      {
        headers: {
          'X-CameraApp-Ignore': true,
        },
      }
    )
  } else {
    return post(
      `/camera_api/api/v3/cameras/${cameraName}/pipeline${actionPath}`,
      data,
      {
        contentType: 'application/json; charset=utf-8',
      }
    )
  }
}

export const makeProfileUrl = (cameraName, profileToken) => {
  return get(
    `/camera_api/api/v3/cameras/${cameraName}/profiles/${profileToken}/presets`
  )
}

export const refreshAllPipelineStatuses = () => {
  return get(
    `/camera_api/api/v3/pipelines/status/all`,
    {},
    {
      headers: {
        'X-CameraApp-Ignore': true,
      },
    }
  )
}

export const updateCameraList = () => {
  return get(`/camera_api/api/v3/get_cameras`)
}
export const updatePipelinesList = () => {
  return get(`/camera_api/api/v3/get_pipelines`)
}

// 删除
export const deletePipeline = (cameraName, id) => {
  return post(`/camera_api/api/v3/cameras/${cameraName}/pipeline/stop/${id}`)
}

// 新增全部启动的表单按钮
export const addBox = (data) => {
  return post(`/camera_api/api/v3/add_aibox`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 修改
export const editBox = (data) => {
  return post(`/camera_api/api/v3/update_aibox`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 删除
export const deleteBox = (data) => {
  return post(`/camera_api/api/v3/delete_aibox`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 获取盒子
export const selectBox = (data) => {
  return post(`/camera_api/api/v3/get_aibox`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 事件信息
export const selectEvent = (data) => {
  return post(`/camera_api/api/v3/envet_info`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 获取盒子列表
export const selectBoxs = (data) => {
  return post(`/camera_api/api/v3/get_aiboxs`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 下载视频
export const downloadVideo = (url) => {
  return download(url)
}

// 获取通道状态
export const getPipelineStatus = (aibox_ip, pipeline_id) => {
  return get(
    `/camera_api/api/v3/${aibox_ip}/get_pipeline_status/${pipeline_id}`
  )
}

// 通道停止
export const stopPipeline = (data) => {
  return post(`/camera_api/api/v3/pipeline/stop`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}
// 通道启动
export const startPipeline = (data) => {
  return post(`/camera_api/api/v3/pipeline/start`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}

// 获取通道
export const pipelinesRtsp = () => {
  return get(`/camera_api/api/v3/pipelines_rtsp`)
}

// 根据相机名称获取直播流
export const getCameraLiveStream = (data) => {
  return post(`/camera_api/api/v3/camera_stream`, JSON.stringify(data), {
    contentType: 'application/json; charset=utf-8',
  })
}
