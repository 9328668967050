<script>
/**
 * @author lixidong
 * @description 设备管理
 */
// 表格模块
import TableTool from '@/components/TableTool/index.vue'
import * as deviceApi from '@/api/gosip_server/device_api.js'
import moment from 'moment'
export default {
  components: { TableTool },
  data() {
    return {
      // 日期
      date: Date.now(),
      // 表格数据
      tableData: {
        stripe: true,
        border: false,

        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'deviceid',
            label: 'ID',
            width: 200,
          },
          {
            prop: 'name',
            label: '设备名称',
            width: 160,
          },
          {
            prop: 'jie',
            label: '接入协议',
            width: 100,
            template() {
              return 'GB/T28181'
            },
          },
          {
            prop: 'transport',
            label: '传输协议',
            width: 100,
          },
          {
            prop: 'devicetype',
            label: '设备类型',
            width: 160,
          },
          {
            prop: 'active',
            label: '心跳时间',
            width: 160,
            slot: true,
          },
          {
            prop: 'addtime',
            label: '添加时间',
            width: 160,
            slot: true,
          },
          {
            prop: 'channelsnum',
            label: '通道',
          },
          {
            prop: 'regist',
            label: '注册',
            slot: true,
          },
          {
            prop: 'memo',
            label: '备注',
            width: 300,
          },
        ],
        btns: [
          {
            label: '通道',
            slot: 'channel',
            width: 50,
          },
          // {
          //   label: "更新",
          //   slot: 'update',
          //   width:50
          // },
          // {
          //   label: "启用",
          //   slot: "use",
          //   width:50
          // },
          // {
          //   label: "协议",
          //   slot: 'text',
          //   width:50
          // },
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          // {
          //   label: "流量",
          //   slot: 'number',
          //   width:50
          // },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        pwd: '',
        name: '',
      },
      dialogBtnLock: false,
      dialogRules: {
        name: [{ required: true, message: '请输入设备名称' }],
        pwd: [{ required: true, message: '请输入设备秘密' }],
      },
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 时间转换
    dateChange(scope, key) {
      return moment(scope.row[key] * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    // 表格数据请求拦截
    async reqIntercept(query) {
      let res = await deviceApi.device_setting_list({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: query.pageSize,
      })
      if (res.code === 200) {
        let { Total, List } = res.data
        return { data: List || [], total: Total }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            if (this.dialogTitle == 'add') {
              let res = await deviceApi.adddevice(this.dialogForm)
              if (res.code != 200) return
            } else {
              let res = await deviceApi.editdevice(this.dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //add
    addDevice() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.dialogFormVisible = true
    },
    //删除设备
    async deleteDevice(row) {
      this.$confirm('此操作将永久删除该设备，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await deviceApi.deldevice(row)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    // 跳转通道
    hrefChannel(row) {
      const { name, deviceid } = row
      this.$router.push({
        path: '/device/channel_mangement',
        query: { DeviceName: name, deviceid },
      })
    },
    //修改设备
    updateDevice(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'update'
      this.dialogForm = Object.assign({}, row)
      this.dialogFormVisible = true
    },
  },
}
</script>

<template>
  <div class="device-mangement">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <div class="device-mangement_header">
          <div>
            <auth-wrapper auth="add-device">
              <el-button
                @click="addDevice"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加设备</el-button
              >
            </auth-wrapper>
          </div>
        </div>
      </template>
      <!-- 字段 -->
      <template slot="slot-active" slot-scope="scope">
        <span>{{ scope.row.active ? dateChange(scope, 'active') : '-' }}</span>
      </template>
      <!-- addtime -->
      <template slot="slot-addtime" slot-scope="scope">
        <span>{{
          scope.row.addtime ? dateChange(scope, 'addtime') : '-'
        }}</span>
      </template>
      <!-- regist -->
      <template slot="slot-regist" slot-scope="scope">
        <el-link
          :underline="false"
          :icon="scope.row.regist ? 'el-icon-check' : 'el-icon-close'"
          :type="scope.row.regist ? 'success' : 'danger'"
        ></el-link>
      </template>
      <!-- 按钮操作 -->
      <template slot="slot-channel" slot-scope="scope">
        <el-link
          :underline="false"
          icon="el-icon-folder-opened"
          type="primary"
          size="mini"
          @click="hrefChannel(scope.row)"
        ></el-link>
      </template>
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-device">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click="updateDevice(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-device">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click="deleteDevice(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改设备 -->
    <el-dialog
      top="100px"
      :title="dialogTitle | dialogTitleKeyValue"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="设备名称" prop="name">
              <el-input
                v-model="dialogForm.name"
                placeholder="请输入设备名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备密码" prop="pwd">
              <el-input
                type="password"
                v-model="dialogForm.pwd"
                placeholder="请输入设备密码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.device-mangement {
  .device-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>
