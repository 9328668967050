<script>
/**
 * @auhtor lixidong
 * @description 财产安全
 */
import * as echarts from 'echarts'
import optionfn from './utils/optionfn'
export default {
  data() {
    return {
      chart: null,
    }
  },
  methods: {
    getLast30Days() {
      let result = []
      let date = new Date()
      for (let i = 0; i < 30; i++) {
        let day = new Date(date)
        day.setDate(date.getDate() - i)
        let month = (day.getMonth() + 1).toString().padStart(2, '0')
        let dayOfMonth = day.getDate().toString().padStart(2, '0')
        result.unshift(`${month}/${dayOfMonth}`)
      }
      return {
        xAxisData: result,
        yAxisData: Array.from({ length: 30 }, () =>
          Math.floor(Math.random() * 40)
        ),
      }
    },

    main(width, height) {
      const { xAxisData, yAxisData: seriesData } = this.getLast30Days()
      var option = optionfn(xAxisData, seriesData, {
        color: '#ffb648',
        bgColor: 'rgba(255, 182, 72, 1)',
      })
      var chart = echarts.init(this.$refs.box, null, { width, height })
      chart.setOption(option)
      this.chart = chart
    },
    resize(domData) {
      const { width, height } = domData[0].contentRect
      if (this.chart) {
        this.chart.resize({ width, height })
      } else {
        this.main(width, height)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<template>
  <div class="home-propertySafety home-item">
    <div class="home-detail-header">
      <img src="@/assets/images/warm/header-common.png" alt="" />
      <div>
        <div class="home-detail-header-title">财产安全</div>
        <div class="home-detail-header-subtitle">Property safety</div>
      </div>
      <div style="font-size: 14px; color: #ffb648">详情</div>
    </div>
    <div v-resize="resize" ref="box" class="home-detail-main"></div>
    <!-- 四角 -->
    <div class="radius r1"></div>
    <div class="radius r2"></div>
    <div class="radius r3"></div>
    <div class="radius r4"></div>
  </div>
</template>
