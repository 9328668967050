<script>
/**
 * @author lixidong
 * @description  权限管理
 */

import InfoConfirm from '@/components/InfoConfirm/index.vue'
import tableMixin from './utils/table'
import authMixin from './utils/auth'
import { Browser, Tool, TagOne } from '@icon-park/vue'
export default {
  components: { InfoConfirm, Browser, Tool, TagOne },
  mixins: [tableMixin, authMixin],
}
</script>
<template>
  <div class="auth-mangement">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <auth-wrapper auth="add-auth">
          <div class="auth-mangement_header">
            <div>
              <el-button
                @click="addList"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加权限</el-button
              >
            </div>
            <div>
              <info-confirm>
                <el-row
                  >1. 点击“添加权限”按钮，可以实现<span class="info-highlight"
                    >权限的创建</span
                  >。</el-row
                >
                <el-row
                  >2. 如果您拥有“绑定”的权限，那么您就可以通过<span
                    class="info-highlight"
                    >绑定</span
                  >按钮，给存在的<span class="info-highlight"
                    >权限名称分配服务能力</span
                  >。</el-row
                >
                <el-row
                  >3.
                  如果您拥有“编辑”和“删除”的权限，那么您就可以修改权限的相应参数。</el-row
                >
              </info-confirm>
            </div>
          </div>
        </auth-wrapper>
      </template>
      <!-- 字段 -->
      <template slot="slot-permission_name" slot-scope="scope">
        <span>{{ scope.row.permission_name }}</span>
      </template>
      <!-- 操作按钮 -->
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-auth">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click="updateList(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-bind" slot-scope="scope">
        <auth-wrapper slot auth="bind-auth">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-s-operation"
              type="primary"
              size="mini"
              @click="bind(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-auth">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click="deleteList(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改名单 -->
    <el-dialog
      top="100px"
      :close-on-click-modal="false"
      :title="dialogTitle | dialogTitleKeyValue"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="110px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="权限名称" prop="PermissionName">
              <el-input
                v-model="dialogForm.PermissionName"
                placeholder="请输入权限名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 权限 -->
    <el-drawer title="绑定" size="400px" :visible.sync="drawer">
      <div style="padding: 0 20px">
        <el-alert title="功能介绍" type="info">
          <el-row
            >1.
            结构第一级是目录列表；控制着服务的一级导航，即访问目录的能力。</el-row
          >
          <el-row
            >2.
            结构第二级一般是功能页面；控制着功能页面的显隐，即访问功能页面的能力。</el-row
          >
          <el-row
            >3.
            结构第二级一般是页面含有的具体权限；控制着功能页面具体的权限，即功能页面的操作权限。</el-row
          >
          <el-row
            >注意：想要操作这项功能必须充分了解菜单管理，才可以进行操作。</el-row
          >
        </el-alert>
        <br />
        <el-button size="mini" @click="drawerSubmit" type="primary"
          >保存</el-button
        >
        <br />
        <br />
        <el-tree
          v-if="expandedKeys && freshen"
          accordion
          ref="tree"
          :data="authThree"
          node-key="value"
          :default-expanded-keys="checkedKeys"
        >
          <template slot-scope="{ node, data }">
            <div @click.stop>
              <el-checkbox
                @change="(val) => checkedChange(val, data)"
                :checked="checked(data)"
              >
              </el-checkbox>
              <browser
                style="margin: 0 5px"
                v-if="data.Type == 'menu'"
                theme="outline"
                size="15"
                fill="#4d6ee7"
              />
              <tool
                v-if="data.Type == 'operation'"
                style="margin: 0 5px"
                theme="outline"
                size="15"
                fill="#4d6ee7"
              />
              <tag-one
                style="margin: 0 5px"
                v-if="data.Type == 'dom'"
                theme="outline"
                size="15"
                fill="#4d6ee7"
              />
              <span>{{ data.Name }}</span>
            </div>
          </template>
        </el-tree>
      </div>
    </el-drawer>
  </div>
</template>
<style lang="less" scoped>
.auth-mangement {
  .auth-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>
