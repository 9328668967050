/**
 * @author lixidong
 * @description 权限
 */
import * as userApi from '@/api/easy_darwin_server/user_api.js'
import routes from '@/router/Index/routes'
export default {
  data() {
    return {
      authArr: [],
      expandedKeys: null,
      newexpandedKeys: null,
      // 动态刷新
      freshen: true,
      checkedKeys: [],
      // 菜单树
      authThree: [],
    }
  },
  created() {
    // 获取菜单树
    this.getMenuThrees()
  },

  computed: {
    checked() {
      const newexpandedKeys = this.newexpandedKeys || []
      return (data) => newexpandedKeys.includes(data.value)
    },
  },
  methods: {
    async getMenuThrees() {
      let res = await userApi.getMenuThree()
      if (res.code == 200) {
        var auth_three = res.data.Menus || []
        function updateChild(arr) {
          return arr.map((item) => {
            const { Children, ...params } = item
            return {
              ...params,
              value: `${params.Type}-${params.ID}`,
              children: Children ? updateChild(Children) : [],
            }
          })
        }
        auth_three = updateChild(auth_three)
        this.authThree = auth_three
      }
    },
    async getAuthUseID(id) {
      let res = await userApi.getPermissionMenusThree(id)
      if (res.code == 200) {
        var checked = res.data.Menus || []
        var checkedKeys = checked.map((item) => {
          return `${item.Type}-${item.ID}`
        })
        return checkedKeys
      }
      return []
    },
    // 获取已经关联的权限
    async getKnowAuth() {
      const id = this.dialogForm.ID
      const expandedKeys = await this.getAuthUseID(id)
      this.expandedKeys = expandedKeys
      this.newexpandedKeys = [...this.expandedKeys]
    },
    // 绑定
    async bind(row) {
      this.dialogForm = Object.assign({}, row)
      ;(this.expandedKeys = null), (this.newexpandedKeys = null)
      await this.getKnowAuth()
      this.drawer = true
    },
    // 保存
    async drawerSubmit() {
      try {
        const oldKeys = this.expandedKeys ? [...new Set(this.expandedKeys)] : []
        const newKeys = this.newexpandedKeys
          ? [...new Set(this.newexpandedKeys)]
          : []
        const asyncArray = []
        // false
        oldKeys.forEach((item) => {
          if (newKeys.indexOf(item) === -1) {
            let ida = item.split('-')
            asyncArray.push({ ID: ida[1] * 1, Type: ida[0], Status: false })
          }
        })
        newKeys.forEach((item) => {
          if (oldKeys.indexOf(item) === -1) {
            let ida = item.split('-')
            asyncArray.push({ ID: ida[1] * 1, Type: ida[0], Status: true })
          }
        })
        const data = {
          PermissionID: this.dialogForm.ID,
          Menus: asyncArray,
        }
        if (asyncArray.length > 0) {
          let res = await userApi.updatePermissionMenusThree(data)
          if (res.code != 200) {
            this.$message.error('更新失败')
            return
          }
        }
        this.$message.success('更新完毕')
      } finally {
        await this.getKnowAuth()
        this.freshen = false
        this.$nextTick(() => {
          this.freshen = true
        })
      }
    },
    // 重置
    draweReset() {
      this.newexpandedKeys = [...this.expandedKeys]
    },
    checkedChange(val, data) {
      let newexpandedKeys = this.newexpandedKeys || []
      if (val) {
        newexpandedKeys.push(data.value)
        // 如果是目录菜单就将重定向默认选中并
        if (data.value.startsWith('menu')) {
          const { Key, children } = data
          let findItem = routes.find((route) => route.name === Key)
          if (findItem && findItem.redirect && findItem.redirect.name) {
            const redirectName = findItem.redirect.name
            let redirectItem = children.find((item) => item.Key == redirectName)
            redirectItem.value && newexpandedKeys.push(redirectItem.value)
            // 动态刷新
            this.newexpandedKeys = newexpandedKeys
            this.freshen = false
            this.$nextTick(() => {
              this.checkedKeys = [data.value, redirectItem.value]
              this.freshen = true
            })
          }
        }
      } else {
        newexpandedKeys = newexpandedKeys.filter((item) => item != data.value)
        // 如果是被重定向的菜单就将重定向默认取消
        if (data.value.startsWith('menu')) {
          const { Key } = data
          let findItem = routes.find((route) => {
            if (route.redirect && route.redirect.name) {
              return route.redirect.name === Key
            }
            return false
          })
          if (findItem && findItem.name) {
            let parentNode = this.authThree.find(
              (item) => item.Key === findItem.name
            )
            if (parentNode.value) {
              newexpandedKeys = newexpandedKeys.filter(
                (item) => item != parentNode.value
              )
              // 动态刷新
              this.newexpandedKeys = newexpandedKeys
              this.freshen = false
              this.$nextTick(() => {
                this.checkedKeys = [parentNode.value, data.value]
                this.freshen = true
              })
            }
          }
        }
      }
      // 动态刷新
      this.newexpandedKeys = newexpandedKeys
    },
  },
}
