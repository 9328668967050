<script>
/**
 * 单通道视频播放
 * @author lixdiong
 */
import Liveplayer from '@/components/Player/liveplayer.vue'
import * as cameraApi from '@/api/camera_api/camera_api.js'
export default {
  props: {
    // 视频窗口 宽高比
    liveplayeraspect: {
      type: String,
      value: 'fullscreen',
    },
    live: { type: Boolean, default: true },
    uuid: Number,
  },
  components: { Liveplayer },
  data() {
    return {
      video: {
        url: null,
        name: null,
      },
      videoList: [],
      dialogVisible: false,
    }
  },
  mounted() {
    if (this.uuid == 0) {
      this.autoPlay()
    }
    this.$bus.$on('video-loading-key', (uuid) => {
      if (uuid + 1 == this.uuid) this.autoPlay()
    })
  },
  methods: {
    async autoPlay() {
      let res = await cameraApi.pipelinesRtsp()
      if (res.code === 200) {
        const data = res.data[this.uuid]
        this.video = {
          url: data['HLS-mpegts'],
          name: data['Name'],
        }
      }
    },
    playHandler() {
      this.$bus.$emit('video-loading-key', this.uuid)
    },
    async reqIntercept() {
      let res = await cameraApi.pipelinesRtsp()
      if (res.code === 200) {
        this.videoList = res.data || []
      }
    },
    // 打开弹窗
    dialogShow() {
      this.dialogVisible = true
      this.reqIntercept()
    },
    // 通道点击事件
    async handleChannelClick(data) {
      this.video = {
        url: data['HLS-mpegts'],
        name: data['Name'],
      }
      this.dialogVisible = false
    },
    // 关闭通道
    async closeChannel() {
      this.video = this.$options.data().video
    },
  },
}
</script>

<template>
  <div class="channel-player home-item">
    <!-- 视频 -->
    <Liveplayer
      :live="live"
      :videoTitle="video.name ? video.name : ''"
      :aspect="liveplayeraspect"
      :videoUrl="video.url"
      @closeChannel="closeChannel"
      @selectChannel="dialogShow"
      @play="playHandler"
    />
    <!-- 通道弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      top="100px"
      append-to-body
      title="视频通道"
      width="800px"
      :visible.sync="dialogVisible"
    >
      <el-table
        highlight-current-row
        @current-change="handleChannelClick"
        :data="videoList"
        height="50vh"
      >
        <el-table-column prop="Name" label="名称"></el-table-column>
        <el-table-column prop="HLS-fmp4" label="地址"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.channel-player {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
