<script>
/**
 * @author lixidong
 * @description 分组管理
 */
// 表格模块
import TableTool from "@/components/TableTool/index.vue";
export default {
  components: { TableTool },
  data () {
    return {
      // 日期
      date: Date.now(),
      // 表格数据
      tableData: {
        stripe: true,
        border: false,

        size: "mini",
        columns: [
          {
            prop: "a",
            label: "ID",
            slot: true,
            template: () => {
              return `<span>789</span>`;
            },
          },
          {
            prop: "b",
            label: "分组昵称",
          },
          {
            prop: "c",
            label: "节点编码",
          },
          {
            prop: "d",
            label: "级联名称",
          },
          {
            prop: "e",
            label: "更新时间"
          },
          {
            prop: "f",
            label: "设备源"
          },
          {
            prop: "g",
            label: "注册-心跳"
          },
          {
            prop: "h",
            label: "制造商"
          },
          {
            prop: "i",
            label: "摘要"
          },
        ],

        btns: [
          { label: "添加" },
          { label: "编辑" },
          { label: "绑定设备" },
          { label: "绑定通道" },
          { label: "共享节点" },
          { label: "删除" },
        ]
      },
    };
  },
};
</script>

<template>
  <div class="group-mangement">
    <table-tool :tableData="tableData">
      <template slot="header">
        <div class="group-mangement_header">
          <div>

          </div>
          <div>
            <el-date-picker size="mini" v-model="date" type="date" placeholder="选择日期">
            </el-date-picker>
            <el-button style="margin-left: 8px;" type="primary" size="mini">时间轴</el-button>
          </div>
        </div>
      </template>
    </table-tool>
  </div>
</template>

<style lang="less" scoped>
.group-mangement {
  .group-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>