/**
 * @author lixidong
 * @description 用户接口
 */
import { get, post, del, put } from '../index'

// 登录接口
export const login = (username, password) => {
  return post(
    '/easy_darwin_server/api/v1/user/login',
    JSON.stringify({ UserName: username, Password: password }),
    {
      contentType: 'application/json',
    }
  )
}

// 获取用户信息
export const userinfo = (userID) => {
  return get(`/easy_darwin_server/api/v1/user/${userID}`)
}

// 用户退出登录
export const logout = () => {
  return get('/easy_darwin_server/api/v1/user/logout')
}

// 用户注册
export const register = (UserName, Password, ConfirmPassword) => {
  return post(
    '/easy_darwin_server/api/v1/user/signup',
    JSON.stringify({ UserName, Password, ConfirmPassword }),
    {
      contentType: 'application/json',
    }
  )
}

// 新增用户
export const adduser = (data) => {
  return post(`/easy_darwin_server/api/v1/user`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 删除用户 "UserID":3
export const removeuser = (UserID) => {
  return del(`/easy_darwin_server/api/v1/user/${UserID}`)
}

// 修改密码
// export const changepwd = (OldPwd, NewPwd) => {
//   return post(
//     '/easy_darwin_server/api/v1/user/2/changepwd',
//     JSON.stringify({ OldPwd, NewPwd }),
//     {
//       contentType: 'application/json',
//     }
//   )
// }

export const changepwd = (id, data) => {
  return put(
    '/easy_darwin_server/api/v1/user/password/' + id,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 修改
export const edituser = (data) => {
  return put(
    `/easy_darwin_server/api/v1/user/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 用户列表 {start,limit}
export const loaduserlist = (data) => {
  return get('/easy_darwin_server/api/v1/users', data)
}

// 角色列表 {start,limit}
export const loadrolelist = (data) => {
  return get('/easy_darwin_server/api/v1/roles', data)
}

// 删除角色
export const delrole = (roleID) => {
  return del(`/easy_darwin_server/api/v1/role/${roleID}`)
}

// 修改角色 Rolename
export const editrole = (data) => {
  return put(
    `/easy_darwin_server/api/v1/role/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 添加角色
export const addrole = (data) => {
  return post(`/easy_darwin_server/api/v1/role`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 权限查询
export const getAuth = (data) => {
  return get(`/easy_darwin_server/api/v1/permissions`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 权限分页查询
export const loadauthlist = (data) => {
  return get('/easy_darwin_server/api/v1/permissions_page', data)
}

// 删除权限
export const delAuth = (data) => {
  return del(
    `/easy_darwin_server/api/v1/permission/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 添加权限
export const addAuth = (data) => {
  return post(`/easy_darwin_server/api/v1/permission`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}
// 修改权限
export const editAuth = (data) => {
  return put(
    `/easy_darwin_server/api/v1/permission/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}
// 获取用户权限key
export const getAuthUserKey = (data) => {
  return get(`/easy_darwin_server/api/v1/permission_user/${data}`)
}

// 获取菜单
export const getMenu = () => {
  return get(`/easy_darwin_server/api/v1/menus`)
}

// 添加菜单
export const addMenu = (data) => {
  return post(`/easy_darwin_server/api/v1/menu`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 修改菜单
export const updateMenu = (data) => {
  return put(
    `/easy_darwin_server/api/v1/menu/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 删除菜单
export const delMenu = (data) => {
  return del(`/easy_darwin_server/api/v1/menu/${data.ID}`)
}

// 获取功能
export const getOperation = (data) => {
  return get(`/easy_darwin_server/api/v1/operations/${data.ID}`)
}

// 修改功能
export const updateOperation = (data) => {
  return put(
    `/easy_darwin_server/api/v1/operation/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 增加功能
export const addOperation = (data) => {
  return post(`/easy_darwin_server/api/v1/operation`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 删除功能
export const delOperation = (data) => {
  return del(`/easy_darwin_server/api/v1/operation/${data.ID}`)
}

// 查看页面元素
export const getDom = (data) => {
  return get(`/easy_darwin_server/api/v1/doms/${data.ID}`)
}

// 添加页面元素
export const addDom = (data) => {
  return post(`/easy_darwin_server/api/v1/dom`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}
// 删除页面元素
export const delDom = (data) => {
  return del(`/easy_darwin_server/api/v1/dom/${data.ID}`)
}

// 修改页面元素
export const updateDom = (data) => {
  return put(
    `/easy_darwin_server/api/v1/dom/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 获取菜单关联
export const getMenuAuth = (data) => {
  return get(`/easy_darwin_server/api/v1/permission_menus/${data}`)
}

// 添加菜单关联
export const addMenuAuth = (data) => {
  return post(
    `/easy_darwin_server/api/v1/permission_menu`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 删除菜单关联
export const delMenuAuth = (data) => {
  return del(`/easy_darwin_server/api/v1/permission_menu/${data}`)
}

// 获取功能关联
export const getOperationAuth = (data) => {
  return get(`/easy_darwin_server/api/v1/permission_operations/${data}`)
}

// 添加功能关联
export const addOperationAuth = (data) => {
  return post(
    `/easy_darwin_server/api/v1/permission_operation`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 删除功能关联
export const delOperationAuth = (data) => {
  return del(`/easy_darwin_server/api/v1/permission_operation/${data}`)
}

// 获取页面元素关联
export const getDomAuth = (data) => {
  return get(`/easy_darwin_server/api/v1/permission_doms/${data}`)
}

// 添加页面元素关联
export const addDomAuth = (data) => {
  return post(
    `/easy_darwin_server/api/v1/permission_dom`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 删除页面元素关联
export const delDomAuth = (data) => {
  return del(`/easy_darwin_server/api/v1/permission_dom/${data}`)
}

// 角色关联添加
export const addRoleAuth = (data) => {
  return post(
    `/easy_darwin_server/api/v1/role_permission`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 查询当个角色权限关联
export const getRoleAuthByRoleID = (data) => {
  return get(`/easy_darwin_server/api/v1/role_permission/${data}`)
}

// 角色关联修改
export const updateRoleAuth = (data) => {
  return put(
    `/easy_darwin_server/api/v1/role_permission/${data.id}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 用户角色关联
export const addUserAndRole = (data) => {
  return post(`/easy_darwin_server/api/v1/user_role`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 修改用户角色关联
export const updateUserAndRole = (data) => {
  return put(
    `/easy_darwin_server/api/v1/user_role/${data.UserRoleID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 获取用户角色 根据用户id查询
export const selectUserAndRole = (data) => {
  return get(`/easy_darwin_server/api/v1/user_role/${data}`)
}

// 角色权限关联表
export const selectRoleAuth = (data) => {
  return get(`/easy_darwin_server/api/v1/role_permissions`)
}

// 微信

export const getWXToken = () => {
  return get(`/easy_darwin_server/api/v1/wx_acces_token`)
}

// 微信发送通知
export const wxSendMessage = (data) => {
  return get(`/easy_darwin_server/api/v1/wx_send_message`, data)
}

// 组分页
export const loadgrouplist = (data) => {
  return get(`/easy_darwin_server/api/v1/groups`, data)
}

// 新增组
export const addgroup = (data) => {
  return post(`/easy_darwin_server/api/v1/group`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 修改组
export const editgroup = (data) => {
  return put(
    `/easy_darwin_server/api/v1/group/${data.ID}`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 删除组
export const delgroup = (data) => {
  return del(`/easy_darwin_server/api/v1/group/${data.ID}`)
}

// 组用户添加
export const addGroupUsers = (data) => {
  return post(`/easy_darwin_server/api/v1/user_group`, JSON.stringify(data), {
    contentType: 'application/json',
  })
}

// 组用户删除
export const delGroupUser = (data) => {
  return del(`/easy_darwin_server/api/v1/user_group/${data.ID}`)
}
// 组用户查询分页
export const loadGroupUsers = (data) => {
  return get(`/easy_darwin_server/api/v1/user_groups/${data.ID}`, data)
}

// 用户列表所有
export const loadAllUser = () => {
  return get(`/easy_darwin_server/api/v1/users_info`)
}

// 获取组可添加的用户列表
export const loadGroupAddUsers = (data) => {
  return get(`/easy_darwin_server/api/v1/user_group_choose_all/${data.ID}`)
}

// 获取菜单树
export const getMenuThree = () => {
  return get(`/easy_darwin_server/api/v1/permission_menus_three`)
}

// 更新指定权限的菜单树
export const updatePermissionMenusThree = (data) => {
  return put(
    `/easy_darwin_server/api/v1/permission_menus_three`,
    JSON.stringify(data),
    {
      contentType: 'application/json',
    }
  )
}

// 获取菜单树选中的权限
export const getPermissionMenusThree = (ID) => {
  return get(`/easy_darwin_server/api/v1/permission_menus_checked/${ID}`)
}
