/**
 * @author lixidong
 * @description 告警表格
 */
import TableTool from '@/components/TableTool/index.vue'
import * as cameraApi from '@/api/camera_api/camera_api'
import Warm from '@/components/Warm/dialog.vue'
import DialogPlayer from '@/components/DialogPlayer/index.vue'
import { downloadImage } from '@/utils/tool'
export default {
  components: { TableTool, Warm, DialogPlayer },
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: true,
        size: 'mini',
        reqIntercept: this.reqIntercept,
        columns: [
          {
            prop: 'AIBoxName',
            label: '设备名称',
            width: 120,
            slot: true,
            resizable: true,
          },
          {
            prop: 'WarnType',
            label: '报警类型',
            width: 140,
            slot: true,
            resizable: true,
          },
          {
            prop: 'Location',
            label: '地点',
          },
          {
            prop: 'Snapshot',
            width: 150,
            slot: true,
            label: '关联快照',
          },
          // {
          //   prop: 'Video',
          //   label: '关联录像',
          //   width: 300,
          //   slot: true,
          // },
          // {
          //   prop: 'Warnlevel',
          //   label: '报警级别',
          // },
          // {
          //   prop: 'WarnMethod',
          //   label: '报警方式',
          // },
          {
            prop: 'Users',
            width: 200,
            label: '负责人',
            slot: true,
            resizable: true,
          },
          // {
          //   prop: 'WarnType',
          //   label: '报警类型',
          // },
          {
            prop: 'b',
            label: '处理状态',
            width: 80,
            slot: true,
          },
          {
            prop: 'Timestamp',
            label: '报警时间',
            template: this.dateChange,
          },
        ],
        btns: [
          {
            label: '操作',
            slot: 'info',
            width: 120,
          },
        ],
      },
    }
  },
  methods: {
    // 获取详情
    infoChannel(scope) {
      this.$refs.warm.showDialog(scope.row)
    },
    // 下载视频
    async download(url) {
      downloadImage(url)
    },
    // 时间转换
    dateChange(scope) {
      // return moment(scope.row.CreateTime).utc().format('YYYY-MM-DD HH:mm:ss')
      return scope.row.CreateTime
      // return scope.row.Timestamp
    },
    // 赋值功能
    copy(value) {
      this.$copyText(value)
    },
    // 播放
    async playVideo(scope, type) {
      // 关于页面失活视频信息失效bug 未处理，先搁置
      try {
        let r = await cameraApi.getCameraLiveStream({
          DeviceName: scope.row.CameraName,
        })
        if (r.code === 200) {
          this.$refs['dialog-player'].showDialog(
            r.data['HLS-mpegts'],
            type == 'source'
              ? `源视频流地址 : ${scope.row.CameraName}`
              : `分析后视频流地址 : ${scope.row.CameraName}`
          )
        }
      } catch (err) {}
    },
  },
}
