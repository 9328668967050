<script>
/**
 * 单通道视频播放
 * @author lixdiong
 */
import Liveplayer from './liveplayer.vue'
import channels from './mixins/channels'
import * as streamApi from '@/api/gosip_server/stream_api.js'
export default {
  props: {
    mode: Boolean, // 多视频
    // 唯一标识 mode为true有效
    id: {
      type: Number / String,
      default: 0,
    },
    // 视频窗口 宽高比
    liveplayeraspect: {
      type: String,
      value: 'fullscreen',
    },
    live: { type: Boolean, default: true },
  },
  activated() {
    // 解决keep alive页面失活后重新激活视频丢失问题
    this.oldData && this.getVideoData(this.oldData)
  },
  components: { Liveplayer },
  mixins: [channels],
  data() {
    return {
      updateStatus: true, //用于更新视频播放插件
      oldData: null, // 每次选择通道的数据，用于解决窗口失活后重新请求视频的问题
      rawdata: null, //其他参数
      streamlist: null, //协议地址列表
      streamtype: null, //播放协议类型
    }
  },
  mounted() {
    this.$bus.$on('PAGEVISIBILITY', this.pageVisibilityBug)
  },
  computed: {
    // 播放视频地址
    streamUrl() {
      if (!this.streamlist || !this.streamtype) return ''
      let stream = this.streamlist.find((item) => item.type == this.streamtype)
      if (stream) return stream.url
      return ''
    },
  },
  methods: {
    // 页面失活后重新请求页面视频
    pageVisibilityBug(state) {
      if (state && this.oldData) {
        this.getVideoData(this.oldData)
      }
    },
    doPlay() {
      this.$refs.liveplayer && this.$refs.liveplayer.doPlay()
    },
    doPause() {
      this.$refs.liveplayer && this.$refs.liveplayer.doPause()
    },
    setCurrentTime(step) {
      this.$refs.liveplayer && this.$refs.liveplayer.setCurrentTime(step)
    },
    // 视频进度
    timeupdate(time) {
      console.log('time', time)
      this.$emit('timeupdate', time)
    },
    // 通道点击事件
    async handleChannelClick(data, resetCallback) {
      // 判断是否更新
      if (resetCallback) {
        if (resetCallback()) return
      } else {
        // 多屏模式下
        if (this.mode && this.rawdata) return false
        if (this.rawdata && this.rawdata.channelid === data.channelid)
          return false
      }
      return await this.getVideoData(data)
    },
    // 获取视频数据
    async getVideoData(data) {
      console.log(data)
      // 直播
      this.oldData = data
      let res = await streamApi.watchplaystream(data)
      if (res.code === 200) {
        const { http, rtmp, wsflv, ...rawdata } = res.data
        this.rawdata = { ...rawdata, name: data.name }
        const streamlist = [
          { type: 'HLS', url: http },
          { type: 'WS-FLV', url: wsflv },
          { type: 'RTMP', url: rtmp },
        ]
        let active_item = streamlist.find((item) => item.url)
        this.streamlist = streamlist
        this.streamtype = active_item.type
        // 解决组件有视频信息，视频不播放的问题
        this.updateStatus = false
        this.$nextTick(() => {
          this.updateStatus = true
        })
        // 曝露一些数据源供调用者使用
        this.mode
          ? this.$emit('initDataCallback', this.id, rawdata, streamlist)
          : this.$emit(
              'initDataCallback',
              rawdata,
              streamlist,
              active_item.type
            )
        return true
      }
      return false
    },
    // 切换协议类型
    changeStreamTypeRef(streamtype) {
      this.streamtype = streamtype
    },
    // 关闭通道
    async closeChannel() {
      this.oldData = null
      this.rawdata = null
      this.streamlist = null
      this.streamtype = null
      // 关闭通道回调
      this.mode
        ? this.$emit('destoryDataCallback', this.id)
        : this.$emit('destoryDataCallback')
    },
  },
}
</script>

<template>
  <div class="channel-player">
    <!-- 视频 -->
    <Liveplayer
      ref="liveplayer"
      v-if="updateStatus"
      :live="live"
      :videoTitle="rawdata ? rawdata.name : ''"
      :aspect="liveplayeraspect"
      :videoUrl="streamUrl"
      @closeChannel="closeChannel"
      @selectChannel="dialogShow"
      @timeupdate="timeupdate"
      @play="$emit('play')"
      @pause="$emit('pause')"
    />
    <!-- 通道弹窗 -->
    <el-dialog
      top="100px"
      :close-on-click-modal="false"
      append-to-body
      title="视频通道"
      width="800px"
      :visible.sync="dialogVisible"
    >
      <table-tool ref="tableTool" :tableData="tableData"></table-tool>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.channel-player {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
