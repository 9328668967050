export default {
  Location: '5号楼-南-西',
  Time: '2024.08.26 16:45:03',
  Responsibles: [
    { name: '李四', phone: '13676541111' },
    { name: '鹿鸣湖', phone: '13876548741' },
  ],
  AlertStatus: '李四',
  WarnType: '通道拥塞',
}
