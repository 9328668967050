<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import ListTe from '@/components/Home/list.vue'
import CarTe from '@/components/Home/car.vue'
import DayTe from '@/components/Home/day.vue'
import PopuleTe from '@/components/Home/popule.vue'
import WarmTe from '@/components/Home/warm.vue'
import ServeTe from '@/components/Home/serve.vue'
export default {
  components: { ListTe, CarTe, DayTe, PopuleTe, WarmTe, ServeTe },
}
</script>

<template>
  <div
    v-img-lazy:backgroundImage="require('@/assets/images/warm/group2-1x.png')"
    class="home"
  >
    <ListTe />
    <ServeTe />
    <WarmTe />
    <DayTe />
    <PopuleTe />
    <CarTe />
  </div>
</template>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  display: grid; /* 确保这是网格容器 */
  grid-template-areas:
    'main main main main list'
    'main main main main list'
    'main main main main list'
    'serve warm day popule car';
  background: url('@/assets/images/warm/group2-1x-min.png');
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;
  grid-auto-rows: 1fr; /* 确保每行高度相同 */
  grid-auto-columns: 1fr; /* 确保每列宽度相同 */
  .home-list {
    grid-area: list;
  }
  .home-serve {
    grid-area: serve;
  }
  .home-warm {
    grid-area: warm;
  }
  .home-day {
    grid-area: day;
  }
  .home-popule {
    grid-area: popule;
  }
  .home-car {
    grid-area: car;
  }
}
</style>
