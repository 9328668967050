<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('menu', ['breadCrumb']),
  },
  methods: {
    navHandler(item) {
      item.name && this.$router.push({ name: item.name })
    },
  },
}
</script>
<template>
  <div class="bread-crumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in breadCrumb" :key="index"
        ><span @click="() => navHandler(item)" style="cursor: pointer">{{
          item.title
        }}</span></el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 16px;
}
</style>
