<script>
/**
 * @author lixidong
 * @description  菜单管理
 */
// 表格模块
import TableTool from '@/components/TableTool/index.vue'
import DomFnTe from './DomFn.vue'
import * as userApi from '@/api/easy_darwin_server/user_api'
import InfoConfirm from '@/components/InfoConfirm/index.vue'
import { mapActions, mapGetters } from 'vuex'
import { CNameRules, ENameRules } from '@/utils/rules'
import RoutesMixin from './utils/routes.js'
export default {
  components: { TableTool, DomFnTe, InfoConfirm },
  mixins: [RoutesMixin],
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        footer_disabled: true,
        size: 'mini',
        row_key: 'ID',
        reqIntercept: this.reqIntercept,
        columns: [
          {
            prop: 'CName',
            label: '菜单名称',
            width: 150,
          },
          {
            prop: 'EName',
            label: 'Key',
            width: 150,
          },
          {
            prop: 'Url',
            width: 260,
            label: 'Url',
          },
          {
            prop: 'Sort',
            label: '排序',
          },
          {
            label: '类型',
            template: (scope) => {
              return scope.row.ParentID == 0 ? '根目录' : '菜单'
            },
          },
          // {
          //   prop: 'Status',
          //   label: '状态',
          //   slot: true,
          // },
          {
            prop: 'Description',
            width: 300,
            label: '备注',
          },
        ],
        btns: [
          {
            label: '编辑',
            slot: 'edit',
            width: 50,
          },
          {
            label: '页面元素',
            slot: 'dom',
            width: 70,
          },
          {
            label: '页面功能',
            slot: 'fn',
            width: 70,
          },
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        CName: '',
        EName: '',
        Description: '',
        Status: true,
        Url: '',
        Sort: 1,
        ParentID: 0,
      },
      dialogBtnLock: false,
      dialogRules: {
        CName: CNameRules,
        EName: ENameRules,
        ParentID: [{ required: true, message: '类型不能为空！' }],
      },
      parents: [], //目录
      domFn: false,
    }
  },
  computed: {
    ...mapGetters('menu', ['menusTableValue']),
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    ...mapActions('menu', ['getMenuConfig']),
    // 通道数据请求拦截
    async reqIntercept(query) {
      try {
        // 获取菜单
        await this.getMenuConfig()
        this.parents = [
          { ID: 0, CName: '根目录' },
          ...this.menusTableValue,
        ].map((menu) => ({
          value: menu.ID,
          label: menu.CName,
        }))
        return {
          data: this.menusTableValue,
          total: this.menusTableValue.length,
        }
      } catch (err) {
        console.log(err.message)
        this.parents = []
        return { data: [], total: 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            if (this.dialogTitle == 'add') {
              let res = await userApi.addMenu(this.dialogForm)
              if (res.code != 200) return
            } else {
              let res = await userApi.updateMenu(this.dialogForm)
              if (res.code != 200) return
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    // add
    addList() {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = this.$options.data().dialogForm
      this.dialogTitle = 'add'
      this.dialogFormVisible = true
    },
    //删除通道
    deleteList(row) {
      this.$confirm('此操作将永久删除这个菜单，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await userApi.delMenu(row)
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
    //修改通道
    updateList(row) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      ;(this.dialogTitle = 'update'), (this.dialogForm = Object.assign({}, row))
      this.dialogFormVisible = true
    },
    // 局部权限
    smallAuth(row, type) {
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogForm = Object.assign({ type }, row)
      this.domFn = true
    },
  },
}
</script>
<template>
  <div class="menu-mangement">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <auth-wrapper auth="add-menu">
          <div class="menu-mangement_header">
            <div>
              <el-button
                @click="addList"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加目录菜单</el-button
              >
            </div>
            <div>
              <info-confirm>
                <el-row
                  >1. 点击“添加目录菜单”按钮，可以完善菜单页面相应信息。</el-row
                >
                <el-row
                  >2.
                  点击"页面元素"和“页面功能”按钮，可以完善菜单页面功能服务相应信息。</el-row
                >
                <el-row
                  >3.
                  如果您拥有“编辑”和“删除”的权限，那么您就可以修改菜单页面的相应参数。</el-row
                >
              </info-confirm>
            </div>
          </div>
        </auth-wrapper>
      </template>
      <!-- 字段 -->
      <template slot="slot-Status" slot-scope="scope">
        <el-link
          :underline="false"
          :icon="scope.row.Status ? 'el-icon-check' : 'el-icon-close'"
          :type="scope.row.Status ? 'success' : 'danger'"
        ></el-link>
      </template>
      <!-- 操作按钮 -->
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-menu">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click="updateList(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-dom" slot-scope="scope">
        <auth-wrapper slot auth="bind-menu">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-s-operation"
              type="primary"
              size="mini"
              @click="smallAuth(scope.row, 'dom')"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-fn" slot-scope="scope">
        <auth-wrapper slot auth="bind-menu">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-s-operation"
              type="primary"
              size="mini"
              @click="smallAuth(scope.row, 'fn')"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-menu">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click="deleteList(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改名单 -->
    <el-dialog
      top="100px"
      :close-on-click-modal="false"
      :title="dialogTitle | dialogTitleKeyValue"
      :visible.sync="dialogFormVisible"
    >
      <el-alert title="功能介绍" type="info">
        <el-row
          >1.
          目录菜单名称对应路由导航中的meta.title,仅做展示信息的能力。</el-row
        >
        <el-row>2. key是权限唯一标识，对应路由导航中的name，十分重要。</el-row>
        <el-row
          >3.
          类型分为目录类型和菜单类型，而菜单类型显示的是目录列表，一定要遵循路由导航的结构进行添加。</el-row
        >
        <el-row>4. url对应路由导航中的path,仅做展示使用。</el-row>
        <el-row
          >注意：想要操作这项功能必须充分了解项目的路由导航，才可以进行添加修改操作。非开发人员，劝君莫动。</el-row
        >
      </el-alert>
      <br />
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="110px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="目录菜单名称" prop="CName">
              <el-autocomplete
                v-model="dialogForm.CName"
                :fetch-suggestions="filterMenuName"
                placeholder="请输入目录\菜单名称"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="key(唯一)" prop="EName">
              <el-autocomplete
                v-model="dialogForm.EName"
                :fetch-suggestions="filterMenuKey"
                placeholder="请输入key(唯一)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Url" prop="Url">
              <el-autocomplete
                v-model="dialogForm.Url"
                :fetch-suggestions="filterMenuUrl"
                placeholder="请输入Url"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="ParentID">
              <el-select v-model="dialogForm.ParentID" placeholder="请选择类型">
                <el-option
                  :value="item.value"
                  :label="item.label"
                  v-for="item in parents"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="排序" prop="Sort">
              <el-input-number
                v-model="dialogForm.Sort"
                :min="1"
                label="请选择排序"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="描述" prop="Description">
              <el-input
                type="textarea"
                resize="none"
                :autosize="{ minRows: 2, maxRows: 4 }"
                v-model="dialogForm.Description"
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 页面元素和功能 -->
    <dom-fn-te :status.sync="domFn" :value="dialogForm" />
  </div>
</template>
<style lang="less" scoped>
.menu-mangement {
  .menu-mangement_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  /deep/ .el-autocomplete-suggestion {
    width: auto !important;
  }
}
</style>
