import * as userApi from '@/api/easy_darwin_server/user_api.js'
export default {
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,
        pageSize: 20,
        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'UserName',
            label: '用户名',
            width: 200,
          },
          {
            prop: 'NickName',
            label: '昵称',
          },
        ],
        btns: [
          {
            label: '删除',
            slot: 'delete',
            width: 50,
          },
        ],
      },
      // 弹出框
      dialogFormVisible: false,
      dialogTitle: '',
      dialogForm: {
        UserIDs: [],
        GroupID: null,
      },
      dialogBtnLock: false,
      dialogRules: {
        UserIDs: [{ required: true, message: '成员不能为空！' }],
        GroupID: [{ required: true, message: '组ID不能为空！' }],
      },
      // 所有用户信息
      allUser: [],
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
  },
  methods: {
    // 请求组可添加用户列表
    async loadAllUser() {
      if (!this.$route.query.id) return
      let res = await userApi.loadGroupAddUsers({ ID: this.$route.query.id })
      if (res.code === 200) {
        const allUser = res.data?.UserGroups || []
        this.allUser = allUser.map((item) => ({
          key: item.ID,
          label: item.UserName,
          nickName: item.NickName,
        }))
      }
    },
    // 表格数据请求拦截
    async reqIntercept(query) {
      if (!this.$route.query.id) return
      let res = await userApi.loadGroupUsers({
        ID: this.$route.query.id,
        CurrentPage: query.currentPage,
        PageSize: query.pageSize,
      })
      if (res.code === 200) {
        return { data: res.data.Users || [], total: res.data.Total || 0 }
      }
    },
    // 增加修改设备表单提交逻辑
    dialogFormSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.dialogBtnLock = true
          try {
            const dialogForm = Object.assign({}, this.dialogForm)
            if (this.dialogTitle == 'add') {
              let res = await userApi.addGroupUsers(dialogForm)
              if (res.code != 200) return
            } else {
              return false
            }
            // 成功后刷新表格
            this.dialogFormCancel()
            if (this.dialogTitle == 'add') {
              this.$refs.tableTool && this.$refs.tableTool.handleReset()
            } else {
              this.$refs.tableTool && this.$refs.tableTool.requestTableData()
            }
          } finally {
            this.dialogBtnLock = false
          }
        }
      })
    },
    // 表单取消按钮逻辑
    dialogFormCancel() {
      this.dialogFormVisible = false
    },
    //显示表单按钮逻辑
    dialogFormShow() {
      this.loadAllUser()
      this.$refs['dialogForm'] && this.$refs['dialogForm'].resetFields()
      this.dialogTitle = 'add'
      this.dialogForm = Object.assign({}, this.$options.data().dialogForm, {
        GroupID: this.$route.query.id * 1,
      })
      this.dialogFormVisible = true
    },
    //删除逻辑
    deleteFn(row) {
      this.$confirm('此操作将永久删除该成员，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { ID } = row
          let res = await userApi.delGroupUser({ ID })
          if (res.code != 200) return
          // 刷新表格数据
          this.$refs.tableTool && this.$refs.tableTool.handleReset()
        })
        .catch(() => {})
    },
  },
}
