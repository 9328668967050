/**
 * @author lixidong
 * @description store
 */
import Vue from 'vue'
import Vuex from 'vuex'

import menu from './modules/menu'
import root from './modules/root'

import * as userApi from '@/api/easy_darwin_server/user_api.js'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    logoText: '智慧园区',
    logoMiniText: 'ED',
    serverInfo: {},
    userInfo: null,
  },
  modules: { menu, root },
  mutations: {
    updateServerInfo(state, serverInfo) {
      state.serverInfo = serverInfo
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
  },
  getters: {
    logoutStatus(state) {
      return !!state.userInfo
    },
  },
  actions: {
    async getUserInfo({ commit, dispatch, state }) {
      try {
        const token = window.localStorage.getItem('IDENTITYSERVER_TOKEN')
        const userID = window.localStorage.getItem('USERID')
        // token && userID && !state.userInfo 请求用户信息
        if (token && userID && !state.userInfo) {
          let res = await userApi.userinfo(userID)
          if (res.code === 200) {
            const { userInfo } = state
            // 服务器返回用户信息为空，抛出错误，重定向到登录页面
            if (!res.data.User || Object.keys(res.data.User) == 0) {
              throw new Error('服务器错误，用户信息为空')
            }
            const { id } = res.data.User
            if (
              (!userInfo || userInfo.id !== id) &&
              state.root.rootKeys.length == 0
            ) {
              // 获取权限
              await dispatch('root/getRootKeys', id)
            }
            commit('updateUserInfo', res.data.User || null)
            return res.data.User || null
          }
        }
        // 未登录
        if (!token || !userID) {
          return null
        }
        // 已登录
        return state.userInfo
      } catch (err) {
        return null
      }
    },
    // 用户退出登录
    async logout({ commit, state }) {
      try {
        // 后台可能存在退出登录失败的情况，前端也要清除用户信息
        await userApi.logout()
      } finally {
        window.localStorage.removeItem('IDENTITYSERVER_TOKEN')
        window.localStorage.removeItem('USERID')
        commit('updateUserInfo', null)
      }
    },
  },
})

export default store
