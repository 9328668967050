<script>
/**
 * @author lixidong
 * @description 新增盒子
 */
import Pipeline from './Pipeline/index.vue'
import * as cameraApi from '@/api/camera_api/camera_api'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: { Pipeline },
  props: {
    status: Boolean,
    selectDialogBox: Object,
  },
  inject: ['state'],
  data() {
    return {
      value: [], //通道列表
      form: {
        boxname: '',
        boxip: '',
      },
      rules: {
        boxname: [{ required: true, message: '盒子名称不能为空！' }],
        boxip: [
          { required: true, message: '盒子IP不能为空！' },
          { validator: this.validateIP },
        ],
      },
      cameras: [], //相机列表
      pipelines: [], //算法列表
      pipelineStatus: false, //相机列表和算法列表的赋值状态
    }
  },
  computed: {
    type() {
      return this.state.type
    },
  },
  async created() {
    this.pipelineStatus = false
    // 相机
    await this.updateCameraList()
    // 算法
    await this.updatePipelinesList()
    this.pipelineStatus = true
  },
  methods: {
    // ip验证
    validateIP(rule, value, callback) {
      const ipPattern =
        /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
      if (!value) {
        return callback(new Error('请输入ip地址'))
      }
      if (!ipPattern.test(value) && !urlPattern.test(value)) {
        return callback(new Error('请输入正确的ip地址'))
      }
      callback()
    },
    // 获取相机
    async updateCameraList() {
      try {
        this.cameras = []
        let res = await cameraApi.updateCameraList()
        if (res.code === 200) this.cameras = res.data || []
      } catch (err) {
        this.cameras = []
      }
    },
    // 获取算法
    async updatePipelinesList() {
      try {
        this.pipelines = []
        let res = await cameraApi.updatePipelinesList()
        if (res.code === 200) {
          this.pipelines = (res.data || []).filter((p) =>
            this.shouldShowPipeline(p)
          )
        }
      } catch (err) {
        this.pipelines = []
      }
    },
    // 算法列表过滤
    shouldShowPipeline(p) {
      return (
        p.Name !== 'audio_detection' &&
        p.Name !== 'video_decode' &&
        p.Version !== 'app_src_dst' &&
        p.Version !== 'object_zone_count' &&
        p.Version !== 'object_line_crossing'
      )
    },
    // 加载更多
    async boxLoad() {
      if (this.status || this.type === '' || this.type == 'add') return
      try {
        // 盒子已经设置的通道
        let res = await cameraApi.selectBox(this.selectDialogBox)
        if (res.code == 200) {
          const Pipelines = res.data.Pipelines || []
          const value = []
          Pipelines.forEach((camera) =>
            value.push({ value: camera, uuid: uuidv4(), auth: true })
          )
          this.value = [...this.value, ...value]
        }
      } finally {
        this.$emit('update:status', true)
      }
    },
    //   启动
    async submit(callback) {
      try {
        this.$emit('update:status', false)
        await this.submitForm(callback)
      } finally {
        this.$emit('update:status', true)
      }
    },
    // 提交通道
    submitPipline() {
      var Pipelines = [...this.value]
      var pipelinesNo = Pipelines.find((item) => {
        const { selectedCamera, selectedProfile, selectedPipeline } = item.$that
        return !selectedCamera || !selectedProfile || !selectedPipeline
      })
      if (pipelinesNo) {
        let index = this.value.findIndex(
          (item) => item.uuid === pipelinesNo.uuid
        )
        this.$message({
          message: `请检查“通道${index + 1}”的选项是否存在空值。`,
          type: 'error',
        })
        return []
      }
      Pipelines = Pipelines.map((item) => {
        const { selectedCamera, selectedProfile, selectedPipeline, IsRtsp } =
          item.$that
        const CameraItem = this.cameras.find(
          (camera) => camera.Id === selectedCamera
        )
        const PipelineItem = this.pipelines.find(
          (pipeline) => pipeline.Id === selectedPipeline
        )
        return {
          CameraId: CameraItem.Id,
          CameraName: CameraItem.Name,
          CameraProfileName: selectedProfile,
          PipelineName: selectedPipeline,
          PipelineId: PipelineItem.Id,
          PipelineName: PipelineItem.Name,
          PipelineVersion: PipelineItem.Version,
          IsRtsp,
        }
      })
      return Pipelines
    },
    // 提交表单
    async submitForm(callback) {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate(async (valid) => {
          try {
            if (valid) {
              const { boxname: Name, boxip: Addr, id } = this.form
              const Pipelines = this.submitPipline()
              if (Pipelines.length != this.value.length) return
              if (this.type == 'add') {
                let res = await cameraApi.addBox({ Name, Addr, Pipelines })
                if (res.code === 200) {
                  this.$message({
                    message: '盒子新增成功',
                    type: 'success',
                  })

                  callback && callback()
                }
              }
              if (this.type == 'edit') {
                let res = await cameraApi.editBox({
                  Name,
                  Addr,
                  Id: id,
                  Pipelines,
                })
                if (res.code === 200) {
                  this.$message({
                    message: '盒子编辑成功',
                    type: 'success',
                  })
                  callback && callback()
                }
              }
            }
          } finally {
            resolve()
          }
        })
      })
    },
    // 新增通道
    addChannel() {
      this.value.push({ uuid: uuidv4() })
      this.$nextTick(() => {
        this.$refs.box.scrollTo({
          top: this.$refs.box.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
    // 删除增加的通道
    delChannel(uuid) {
      let index = this.value.findIndex((item) => item.uuid === uuid)
      index > -1 && this.value.splice(index, 1)
    },
    // 修改通道参数
    updateChannel(uuid, value, state = false) {
      let index = this.value.findIndex((item) => item.uuid === uuid)
      index > -1 &&
        this.$set(this.value, index, {
          ...this.value[index],
          value: Object.assign({}, this.value[index].value, value),
          auth: state,
        })
    },
    //获取this
    getThis(uuid, that) {
      let index = this.value.findIndex((item) => item.uuid === uuid)
      index > -1 &&
        this.$set(this.value, index, { ...this.value[index], $that: that })
    },
  },
  watch: {
    type: {
      handler(newVal) {
        if (newVal == 'add' && this.cameras.length > 0) {
          this.value = new Array(
            this.cameras.length > 4 ? 4 : this.cameras.length
          )
            .fill(0)
            .map(() => ({ uuid: uuidv4() }))
        }
      },
      immediate: true,
    },
    cameras(newVal) {
      if (this.type == 'add' && newVal.length > 0) {
        this.value = new Array(
          this.cameras.length > 4 ? 4 : this.cameras.length
        )
          .fill(0)
          .map(() => ({ uuid: uuidv4() }))
      }
    },
    selectDialogBox: {
      handler(newVal) {
        if (newVal) {
          this.form = {
            boxname: newVal.Name,
            boxip: newVal.Addr,
            id: newVal.Id,
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
<template>
  <div ref="dialogbox">
    <el-form
      ref="form"
      :model="form"
      size="mini"
      :rules="rules"
      label-width="80px"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="盒子名称" prop="boxname">
            <el-input
              :disabled="type == 'edit'"
              v-model="form.boxname"
              placeholder="请输入盒子名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="盒子IP" prop="boxip">
            <el-input
              :disabled="type == 'edit'"
              v-model="form.boxip"
              placeholder="请输入盒子IP"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div ref="box" class="carmera-dialog">
      <pipeline
        :type="type"
        @getThis="getThis"
        :ip="form.boxip"
        :cameras="cameras"
        :pipelines="pipelines"
        @delChannel="delChannel"
        :auth="item.auth"
        v-for="(item, index) in value"
        :value="item.value"
        :index="index"
        :uuid="item.uuid"
        :key="item.uuid"
      />
    </div>
    <div
      v-if="pipelineStatus"
      class="warning-box_load-more"
      v-load-more="{
        root: () => $refs.dialogbox,
        loadfn: boxLoad,
        thresholdpx: '38px',
      }"
    ></div>
  </div>
</template>

<style lang="less" scoped>
.carmera-dialog {
  height: 360px;
  overflow: hidden;
  overflow-y: auto;
}
</style>
