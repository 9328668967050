<script>
/**
 * @author lixidong
 * @description 左侧导航栏右侧树形控件功能
 */
import ListIcon from '@/components/Icon/List.vue'
import StarIcon from '@/components/Icon/Star.vue'
import * as deviceApi from '@/api/gosip_server/device_api.js'
import { Monitor, CameraFive } from '@icon-park/vue'
export default {
  components: { ListIcon, StarIcon, Monitor, CameraFive },
  data() {
    return {
      loadable: true,
      nodeKey: 'deviceid',
      searchValue: '',
      // 分页
      treeValue: {
        limit: 500,
        start: 0,
        total: 0,
        filters: null,
        treeData: [],
      },
    }
  },
  activated() {
    // 在组件被激活时执行的逻辑
    this.device_setting_list()
  },
  deactivated() {
    this.treeValue = this.$options.data().treeValue
  },

  methods: {
    // 懒加载 请求设备
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([])
      }
      if (node.data.children && data.children.length > 0) {
        return resolve(node.data.children)
      }
      if (node.level === 1) {
        // 使用的是通道分页接口，目前限制limit:1000 .
        deviceApi
          .channels_config({
            skip: 0,
            limit: 1000,
            filters: JSON.stringify([
              {
                field_name: 'deviceid',
                opertator: '=',
                value: node.data.deviceid,
              },
            ]),
          })
          .then((res) => {
            if (res.code === 200) {
              const data = res.data.List.map((item) => {
                item['leaf'] = true
                return item
              })
              resolve(data)
            }
          })
          .catch(() => resolve([]))
      }
      return resolve([])
    },
    // 请求设备列表信息
    async device_setting_list() {
      let { treeData, limit, start, total, filters } = this.treeValue
      if (total != 0 && treeData.length >= total) return false
      let res = await deviceApi.device_setting_list(
        filters
          ? { skip: start, limit: this.limit, filters }
          : { skip: start, limit: this.limit }
      )
      // 这里应该加上leaf，将没有通道的进行禁用。问题服务器没有相应字段返回。
      // const list = result.List.map(item)
      if (res.code === 200) {
        this.treeValue = {
          treeData: treeData.concat(res.data.List),
          total: res.data.Total,
          start: ++start,
          limit,
          filters,
        }
      }
    },
    // 点击节点事件
    handleNodeClick(data, node) {
      if (node.level === 2) {
        this.$emit('handleChangeClick', data)
      }
    },
    // 搜索
    handleChannelSearch() {
      if (this.searchValue) {
        this.treeValue = this.$options.data().Value
        const filters = [
          {
            or: [
              [{ field_name: 'host', opertator: '=', value: this.searchValue }],
              [{ field_name: 'name', opertator: '=', value: this.searchValue }],
            ],
          },
        ]
        this.treeValue = {
          ...this.$options.data().treeValue,
          filters: JSON.stringify(filters),
        }
      } else {
        this.treeValue = this.$options.data().treeValue
      }
      this.device_setting_list()
    },
  },
}
</script>
<template>
  <div class="tree-sider">
    <!-- 搜索功能 -->
    <div class="nsr-search">
      <el-input
        @change="handleChannelSearch"
        v-model="searchValue"
        size="mini"
        placeholder="请输入设备IP或设备名称"
        suffix-icon="el-icon-search"
      >
      </el-input>
    </div>
    <!-- 树形控件 -->
    <div ref="nsr_tree" class="nsr-tree">
      <el-tree
        class="nsr-tree-content"
        :data="treeValue.treeData"
        :props="{
          children: 'children',
          label: 'name',
          isLeaf: 'leaf',
        }"
        :load="loadNode"
        lazy
        @node-click="handleNodeClick"
      >
        <div class="nsr-tree-span" slot-scope="{ node, data }">
          <div class="nsr-tree_label">
            <monitor
              v-if="node.level === 1"
              theme="outline"
              size="14"
              fill="#fff"
              strokeLinecap="butt"
            ></monitor>
            <camera-five
              v-else
              theme="outline"
              size="14"
              :fill="$ifspecification(data.status, 'on') ? '#67c23a' : '#fff'"
              strokeLinecap="butt"
            ></camera-five>
            <span style="font-size: 14px; margin-left: 2px">{{
              node.label || '未命名'
            }}</span>
          </div>
          <!-- $ifspecification(scope.row.status,'on') -->
        </div>
      </el-tree>
      <div
        @click="device_setting_list"
        v-if="
          treeValue.total > 0 &&
          treeValue.treeData.length < treeValue.total &&
          loadable
        "
        class="nsr_load-more"
        v-load-more="{
          root: () => $refs.nsr_tree,
          loadfn: device_setting_list,
        }"
      >
        加载更多
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.tree-sider {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .nsr-search {
    padding: 20px 10px;
  }

  .nsr-tree {
    height: calc(100% - 68px);
    overflow-y: auto;
    padding: 0px 10px;
    box-sizing: border-box;

    /deep/ .is-leaf {
      &::before {
        content: '';
      }
    }

    .nsr-tree-span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nsr-tree_label {
        flex: 0 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .nsr-tree_nums {
        margin-left: 5px;
        flex: 1;
      }
    }
  }

  .nsr_load-more {
    text-align: center;
  }
}
</style>
